export default (store) => (next) => (action) => {
  console.groupCollapsed("Action: " + action.type);
  console.info("dispatching", action);

  let result = next(action);

  console.groupEnd();

  return result;
};
