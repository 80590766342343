import { isObject } from 'lodash';
import Types from 'Retail/state/actions/mapleNotes';

export const INITIAL_STATE = {
  data: { data: [], page: 1, size: 10, totalCount: 1 },
  fetching: false,
  error: false,
  created: false,
  updating: false,
  params: '',
};

const mapleNotes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_MAPLE_NOTE:
      return {
        ...state,
        fetching: true,
        error: false,
        data: isObject(action?.data) ? action?.data : INITIAL_STATE.data,
        params: action?.params,
      };
    case Types.FETCH_MAPLE_NOTE_SUCCESS:
      console.log('action', action);
      return {
        ...state,
        fetching: false,
        data: isObject(action?.data) ? action?.data : INITIAL_STATE.data,
      };
    case Types.FETCH_MAPLE_NOTE_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.CREATE_MAPLE_NOTE:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.CREATE_MAPLE_NOTE_SUCCESS:
      return {
        ...state,
        updating: false,
        created: true,
      };
    case Types.CREATE_MAPLE_NOTE_FAILED:
      return {
        ...state,
        updating: false,
        error: true,
      };
    case Types.UPDATE_MAPLE_NOTE:
      return {
        ...state,
        fetching: true,
        error: false,
        updating: true,
      };
    case Types.UPDATE_MAPLE_NOTE_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.UPDATE_MAPLE_NOTE_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.DELETE_MAPLE_NOTE:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.DELETE_MAPLE_NOTE_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.DELETE_MAPLE_NOTE_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default mapleNotes;
