import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import DictionaryHome from '@maple-tech/dictionary-web-app/HomePage';
import Manage from '@maple-tech/dictionary-web-app/Manage';

import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';

export const DICTIONARY_BASE_ROUTE = {
  index: '/dictionary',
  category: '/dictionary/category',
  detail: '/dictionary/detail',
  manage: '/dictionary/manage',
};

const ROUTES = {
  index: 'dictionary',
  category: 'category',
  detail: 'detail',
  manage: 'manage',
};

const subRoutesList = [
  {
    menuItem: false,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: '',
    element: (
      <Page title={'Dictionary'} contentStyle={{ padding: 0 }}>
        <DictionaryHome />
      </Page>
    ),
    index: true,
  },
  {
    menuItem: false,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: ROUTES.manage,
    element: (
      <Page title={'Manage'} contentStyle={{ padding: 0 }}>
        <Manage />
      </Page>
    ),
    index: false,
  },
];

const DictionaryPagesRoutes = {
  menuItem: true,
  requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
  path: ROUTES.index,
  section: true,
  title: 'Dictionary',
  icon: <LibraryBooksRoundedIcon />,
  element: <Outlet />,
  children: subRoutesList,
};

export default DictionaryPagesRoutes;
