/**
 * Set's up the different reducers that are used by the core system
 */
import authentication, { Filter as AuthFilter } from './authentication';
import user from './user';
import company, { Filter as CompFilter } from './company';
import application, { Filter as AppFilter } from './application';

export default {
    authentication,
    user,
    company,
    application,
};

//Export all the storage filters to prevent some keys from getting saved (persistance)
export const Filters = [
    AuthFilter,
    CompFilter,
    AppFilter,
];