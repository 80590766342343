import './bootstrap-overlay.less';

import React from 'react';

import Logo from '../logo';

export default ({ fade = false }) => (
  <div id="bootstrap-overlay" className={fade ? "fade" : ""}>
    <Logo full={false} color="white" />
  </div>
);
