import { call, put, all, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';
import { RetailAPI } from 'Core/API';

export function* fetchStorePosts({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.get('/store/posts?' + new URLSearchParams(params)),
    );
    yield put({ type: Types.FETCH_STORE_POSTS_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_POSTS_FAILED });
  }
}

export function* createStorePosts({ values }) {
  try {
    yield call(() => RetailAPI.post('/store/post', values));
    yield all([
      put({ type: Types.POST_STORE_POSTS_SUCCESS }),
      put({ type: Types.FETCH_STORE_POSTS }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_POSTS_FAILED });
  }
}

export function* deleteStorePosts({ params: { id, storeId } }) {
  try {
    yield call(() => RetailAPI.delete('/store/post/' + id));
    yield all([
      put({ type: Types.DELETE_STORE_POSTS_SUCCESS }),
      call(() => fetchStorePosts({ params: { storeId } })),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_POSTS_FAILED });
  }
}

export function* updateStorePosts({ values, id }) {
  try {
    yield call(() => RetailAPI.put('/store/post/' + id, values));
    yield all([
      put({ type: Types.PUT_STORE_POSTS_SUCCESS }),
      put({ type: Types.FETCH_STORE_POSTS }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_POSTS_FAILED });
  }
}
