import { Badge, Menu, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "./navigation.less";
import useRetailRoutes from "Retail/routes";

//Example data block of how the navigation is mapped to an object tree.
//This allows the redux state to drive the navigation tree.

export default ({ currentPath, collapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navigation } = useRetailRoutes();
  const handleItemClick = ({ key }) => navigate(key);
  const basePath = location.pathname.substr(
    0,
    location.pathname.indexOf("/", 1)
  );

  return (
    <div id="page-menu-navigation">
      <Menu
        defaultSelectedKeys={[location.pathname, currentPath]}
        defaultOpenKeys={[basePath]}
        onClick={handleItemClick}
        onMouseDown={({ key }) => key && window.open(key)}
        theme="light"
        mode="inline"
        items={navigation}
      />
    </div>
  );
};

const CustomTooltip = ({ children, title = "Coming Soon", isComingSoon }) => {
  if (isComingSoon)
    return (
      <Tooltip className="pl-3" title={title}>
        {children}
      </Tooltip>
    );

  return children;
};
