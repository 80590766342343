import { RetailAPI } from 'Core/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from 'Retail/state/actions/Coaching';

export function* fetchCoaching({ params = '' }) {
  try {
    let data = yield call(() => RetailAPI.get('/coaching' + params));
    yield put({ type: types.FETCH_COACHING_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_COACHING_FAILED });
  }
}

export function* fetchCoachingCompetenceList({ params = '' }) {
  try {
    let data = yield call(() =>
      RetailAPI.get('/coaching-competence-item?' + params),
    );
    yield put({ type: types.FETCH_COACHING_COMPETENCE_ITEMS_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_COACHING_COMPETENCE_ITEMS_FAILED });
  }
}

export default function* coachingSaga() {
  yield takeLatest(types.FETCH_COACHING, fetchCoaching);
  yield takeLatest(
    types.FETCH_COACHING_COMPETENCE_ITEMS,
    fetchCoachingCompetenceList,
  );
}
