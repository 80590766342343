import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: [],
  requesting: false,
  error: false,
  updated: 0,
  success: false,
};

const addNewTemplate = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.ADD_NEW_TEMPLATE:
      return {
        ...state,
        error: false,
        requesting: true,
        success: false,
      };
    case Types.ADD_NEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        //templates: action.data,
        data: {},
        updated: state.updated + 1,
        error: false,
        requesting: false,
        success: true,
      };

    case Types.ADD_NEW_TEMPLATE_FAILED:
      return {
        ...state,
        requesting: false,
        updated: state.updated + 1,
        error: true,
        success: false,
      };

    default:
      return state;
  }
};
addNewTemplate.Types = Types;

export default addNewTemplate;
