import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

export default function InfoModal({ open, onClose }) {
  const { t } = useTranslation('core');

  const onLinkClicked = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('support-info-dialog.title')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>
          {t('support-info-dialog.message-1')}
          <Link href="/support" underline="hover" onClick={onLinkClicked}>
            {t('support-info-dialog.link')}
          </Link>
          {t('support-info-dialog.message-2')}
        </DialogContentText>
        <DialogContentText>
          {t('support-info-dialog.message-3')}
        </DialogContentText>
        <DialogContentText>
          {t('support-info-dialog.message-4')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('support-info-dialog.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
