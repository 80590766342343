import Types from 'Retail/state/actions/checklist';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
  TotalCount: 0,
  Page: 1,
  Size: 10,
};

const fetchCehcklistTemplate = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_CHECKLIST_TEMPLATE:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case Types.FETCH_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.data.Data,
        updated: state.updated + 1,
        Size: action.data.Size,
        TotalCount: action.data.TotalCount,
        Page: action.data.Page,
        error: false,
        fetching: false,
      };

    case Types.FETCH_CHECKLIST_TEMPLATE_FAILED:
      return {
        ...state,
        fetching: false,
        updated: state.updated + 1,
        error: true,
      };
    case Types.REMOVE_CHECKLIST_TEMPLATE:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case Types.REMOVE_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        updated: state.updated + 1,
        TotalCount: action.data.TotalCount - 1,
        error: false,
        fetching: false,
      };

    case Types.REMOVE_CHECKLIST_TEMPLATE_FAILED:
      return {
        ...state,
        fetching: false,
        updated: state.updated + 1,
        error: true,
      };
    default:
      return state;
  }
};
export default fetchCehcklistTemplate;
