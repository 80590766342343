import { takeLatest, call, put, delay } from 'redux-saga/effects';

import { RetailAPI } from 'Core/API';

import Types from '../actions/permissions';

export function* fetchPermissions({ action }) {
  try {
    const payload = yield call(() => RetailAPI.get('/retail-api/permissions'));
    yield put({ type: Types.FETCH_DEFAULT_PERMISSIONS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.FETCH_DEFAULT_PERMISSIONS_FAILED, error });
  }
}

export default function* permissionsSaga() {
  yield takeLatest(Types.FETCH_DEFAULT_PERMISSIONS, fetchPermissions);
}
