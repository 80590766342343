import { combineReducers } from 'redux';
import floorManagement from './floorManagement';
import floorManagementTypes from './floorManagementTypes';
import posts from './posts';
import shift from './shift';
import shiftType from './shiftType';
import targetSchedule from './targetSchedule';

export default combineReducers({
  posts,
  targetSchedule,
  floorManagement,
  floorManagementTypes,
  shiftType,
  shift,
});
