import { call, put, takeLatest } from 'redux-saga/effects';
import Types from 'Retail/state/actions/checklist';
import { fetchChecklist, putChecklist } from './list';
import {
  createChecklistCategories,
  fetchChecklistCategories,
} from './categories';
import {
  fetchChecklistTemplate,
  createChecklistTemplate,
  putChecklistTemplate,
  removeChecklistTemplate,
} from './template';

export default function* checklistSaga() {
  //Template
  yield takeLatest(Types.FETCH_CHECKLIST_TEMPLATE, fetchChecklistTemplate);
  yield takeLatest(Types.POST_CHECKLIST_TEMPLATE, createChecklistTemplate);
  yield takeLatest(Types.PUT_CHECKLIST_TEMPLATE, putChecklistTemplate);
  yield takeLatest(Types.REMOVE_CHECKLIST_TEMPLATE, removeChecklistTemplate);
  //List
  yield takeLatest(Types.FETCH_CHECKLIST, fetchChecklist);
  // yield takeLatest(Types.POST_CHECKLIST, createChecklist);
  yield takeLatest(Types.PUT_CHECKLIST, putChecklist);
  //Category
  yield takeLatest(Types.CREATE_CHECKLIST_CATEGORY, createChecklistCategories);
  yield takeLatest(Types.GET_CHECKLIST_CATEGORY, fetchChecklistCategories);
}
