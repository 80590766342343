import { Player } from "@lottiefiles/react-lottie-player";
import { Col, message, Row } from "antd";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../components/logo";
import { Login as LoginAction } from "../../state/actions/authentication";
import LoginForm from "./form";
import "./login.less";
import LoginHeaderLottie from "./Lottie/loginHeader.json";

const MapleLoginPage = () => {
  const [isSamlLogin, setIsSamlLogin] = useState(false);

  const location = useLocation();

  //Check how we got here, and if nothing make a phony root path
  var { from } = location.state || { from: { pathname: "/" } };
  if (from && from.pathname === "/login") {
    console.debug("Preventing infinite loop");
    from.pathname = "/";
  }
  //See if we are actually logged in anyways, and if so, we redirect back out.
  //const isAuthenticated = useSelector(state => state.authentication.valid);

  //Get ahold of the i18n services
  const { t } = useTranslation(["core"]);

  //Grab the redux dispatch hook
  const dispatch = useDispatch();

  //Check the login status
  const { loginStatus, bootstrapStatus, fetchError } = useSelector((state) => ({
    loginStatus: state.authentication.loginStatus,
    bootstrapStatus: state.application.bootstrapLoginStatus,
    fetchError: state.authentication.loginError,
  }));

  let isLoading = false;
  if (loginStatus === "LOADING") {
    isLoading = true;
  } else if (loginStatus === "SUCCESS" && bootstrapStatus !== "COMPLETE") {
    isLoading = true;
  }
  useLayoutEffect(() => {
    const checkParams = new URLSearchParams(window.location.search);
    if (checkParams.has("samlAuthKey")) {
      dispatch(LoginAction(null, null, true, checkParams.get("samlAuthKey")));
    }
  }, []);
  //The login function/action. Get's fired off when the form is submitted with valid values
  const handleSubmit = (form) => {
    console.log("form :>> ", form);
    if (isSamlLogin) {
      fetch(
        `https://stg-core-api.workmaple.com/login-url?email=${form.username}`,
        {
          method: "get",
        }
      )
        .then((r) => (r.status === 200 ? r.text() : r.json()))
        .then((loginURL) => {
          if (typeof loginURL === "string") {
            window.location.href =
              `https://stg-core-api.workmaple.com${loginURL.trim()}?email=${
                form.username
              }`.replaceAll('"', "");
          } else {
            message.error(loginURL.additionalInfo);
          }
        })
        .catch((error) => {
          console.error(error);
          message.error("Error logging in");
        });
    } else {
      dispatch(LoginAction(form.username, form.password, isSamlLogin));
      // userLoggedIn(form.username, form.password).then((userResponse)=>{
      //   if(typeof userResponse != "object"){
      //     message.error(userResponse);
      //   }else{
      //   }
      // })
    }
  };
  return (
    <div className="route-auth">
      <div id="route-login-content" className="route-auth-content">
        <main>
          <Player
            src={LoginHeaderLottie}
            style={{ height: 120, marginBottom: 12 }}
          />
          <h1>{t("login.greeting")}</h1>
          <h2>{t("login.sub-greeting")}</h2>

          <LoginForm
            onSubmit={handleSubmit}
            loading={isLoading}
            setIsSamlLogin={setIsSamlLogin}
            isSamlLogin={isSamlLogin}
          />

          {fetchError && fetchError.code && (
            <span className="error">{renderError(t, fetchError)}</span>
          )}
          <Logo
            full
            color="primary"
            style={{ height: 24, marginBottom: -16, marginTop: 16 }}
          />
        </main>

        <Row className="route-auth-footer">
          <Col xs={12} xl={12}>
            <Link to="/register">{t("login.sign-up-message")}</Link>
          </Col>

          <Col xs={12} xl={12}>
            <Link to="/forgot-password">{t("login.forgot-pass-message")}</Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const renderError = (t, { code }) => {
  switch (code) {
    case "not-authorized":
      return t("login.bad-login");
    default:
      return t("generic-error");
  }
};

export default MapleLoginPage;
