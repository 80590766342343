import { createTheme } from '@mui/material';
import { blue, blueGrey, yellow, green, red } from '@mui/material/colors';

export const COLORS = [
  'primary',
  // "inherit",
  'tertiary',
  'secondary',
  'warning',
  'success',
  'error',
  'info',
];

const lightThemeBG = {
  paper: '#ffffff',
  default: '#f3f3f3',
};
const darkThemeBG = {
  paper: '#222222',
  default: '#111111',
};

const paletteColors = {
  maple: {
    light: '#ad2c23',
    main: '#992040',
    dark: '#7f131b',
  },
  primary: {
    light: '#ad2c23',
    main: '#992040',
    dark: '#7f131b',
  },
  positive: {
    light: '#3dd598', //  green["400"], // taken from figma
    main: '#3dd598', // green["500"],
    dark: green['700'],
  },
  negative: {
    light: '#fc5a5a', // red["400"],
    main: '#fc5a5a', //red["500"],
    dark: red['700'],
  },
  success: {
    light: '#81c784',
    main: '#66bb6a',
    dark: '#388e3c',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
  },
  info: {
    light: blue['400'],
    main: blue['500'],
    dark: blue['800'],
  },
  secondary: {
    light: blueGrey['400'],
    main: blueGrey['500'],
    dark: blueGrey['800'],
  },
  warning: {
    light: '#ffb74d',
    main: '#ffa726',
    dark: '#f57c00',
  },
  golden: {
    light: yellow['300'],
    main: '#F5D224',
    dark: yellow['800'],
    contrastText: '#333333',
  },
  tertiary: {
    light: '#D6C0AA',
    main: '#c4a78a',
    dark: '#CAA47E',
    contrastText: 'white',
  },
};

// mode = "light" | "dark"
const DefaultTheme = (mode) =>
  createTheme({
    palette: {
      ...paletteColors,
      background: mode === 'light' ? lightThemeBG : darkThemeBG,
      mode,
    },
    typography: {
      fontFamily: [
        // '-apple-system',
        // 'BlinkMacSystemFont',
        // '"Segoe UI"',
        'Roboto',
        // '"Helvetica Neue"',
        // 'Arial',
        // 'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            margin: 2,
          },
        },
        // @ts-ignore
        variants: [
          ...COLORS.map((a) => ({
            props: { variant: 'dashed', color: a },
            style: {
              border: `1px dashed ${paletteColors[a].main}`,
              color: paletteColors[a].main,
            },
          })),
        ],
      },

      MuiTypography: {
        // @ts-ignore
        variants: [
          ...COLORS.map((a) => ({
            props: { color: a },
            style: {
              color: paletteColors[a].main,
            },
          })),
        ],
      },

      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor:
              mode === 'light' ? lightThemeBG.default : darkThemeBG.default,
          },

          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: 8,
            height: 4,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: paletteColors.primary.main,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: paletteColors.primary.dark,
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: paletteColors.primary.dark,
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: paletteColors.primary.dark,
            },
        },
      },
    },
  });

export const themeGenerator = DefaultTheme;

export default DefaultTheme('light');
