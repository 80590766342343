import { Button, Checkbox, Divider, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const LoginForm = ({ onSubmit, loading, setIsSamlLogin, isSamlLogin }) => {
  const { t } = useTranslation("core");
  const handleSubmit = (values) => {
    onSubmit(values);
  };
  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        label={t("forms.email-phone")}
        colon={false}
        aria-labelledby="loginEmail"
        name="username"
        rules={[
          {
            required: true,
            min: 3,
            pattern: /^((.+@.+)|(\+?[\d\-\s]{12,16}))$/,
            message: t("forms.email-phone-req"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      {isSamlLogin === false && (
        <Form.Item
          label={t("forms.password")}
          colon={false}
          name="password"
          aria-labelledby="loginPassword"
          rules={[{ required: true, min: 6, message: t("forms.password-req") }]}
        >
          <Input.Password />
        </Form.Item>
      )}

      <Form.Item colon={false}>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          block
          aria-labelledby="loginSubmit"
        >
          {t("login.sign-in")}
        </Button>
      </Form.Item>
      <Divider />
      <Checkbox
        checked={isSamlLogin}
        onChange={() => setIsSamlLogin(!isSamlLogin)}
      >
        Saml İle Giriş Yap
      </Checkbox>
    </Form>
  );
};

export default LoginForm;
