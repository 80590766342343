import { Outlet } from 'react-router-dom';
import { COMPANIES, COUNTRIES } from 'Core/API';
import Page from 'Core/components/page';

import Checklist from '@maple-tech/checklists-forms-web-app/HomePage';
import List from '@maple-tech/checklists-forms-web-app/List';
import Details from '@maple-tech/checklists-forms-web-app/Details';

import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';

export const CHECKLIST_BASE_ROUTE = {
  index: '/checklist',
  list: '/checklist/list',
  details: '/checklist/details/:id',
  edit: '/checklist/edit/:id',
  // category: '/checklists-forms/category',
  // manage: '/checklists-forms/manage',
};

const ROUTES = {
  index: 'checklist',
  list: 'list',
  details: 'details/:id',
  edit: 'edit/:id',
};

const subRoutesList = [
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
    ],
    path: ROUTES.index,
    title: 'Checklists & Forms',
    element: (
      <Page title={'Checklists & Forms'} contentStyle={{ padding: 0 }}>
        <Checklist />
      </Page>
    ),
    index: true,
  },
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
    ],
    path: ROUTES.list,
    title: 'List',
    element: (
      <Page title={'Checklist List'} contentStyle={{ padding: 0 }}>
        <List />
      </Page>
    ),
    index: false,
  },
  {
    menuItem: false,
    path: ROUTES.details,
    element: (
      <Page title={'Checklist Details'} contentStyle={{ padding: 0 }}>
        <Details />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
    ],
  },
  {
    menuItem: false,
    path: ROUTES.edit,
    element: (
      <Page title={'Checklist Details'} contentStyle={{ padding: 0 }}>
        <Details />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
    ],
  },
];

const ChecklistsFormsRoutes = {
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.DEMO,
    COMPANIES.VINEYARD_VALLEY,
  ],
  path: ROUTES.index,
  section: true,
  title: 'Checklists & Forms',
  icon: <PlaylistAddCheckCircleIcon />,
  element: <Outlet />,
  children: subRoutesList,
};

export default ChecklistsFormsRoutes;

export const subRoutesForOldRoutes = (authorizationLevel, countryId) => [
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.SEPHORA_FRANCE,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      COMPANIES.MEDIAMARKT,
    ].filter(Boolean),
    path: ROUTES.list,
    title: 'retail:navigation.checklists.all',
    element: (
      <Page title="All Checklists" contentStyle={{ padding: 0 }}>
        <List />
      </Page>
    ),
    index: false,
  },
  {
    menuItem: false,
    path: ROUTES.details,
    element: (
      <Page title={'Checklist Details'} contentStyle={{ padding: 0 }}>
        <Details />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.SEPHORA_FRANCE,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      COMPANIES.MEDIAMARKT,
    ].filter(Boolean),
  },
  {
    menuItem: false,
    path: ROUTES.edit,
    element: (
      <Page title={'Checklist Details'} contentStyle={{ padding: 0 }}>
        <Details />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.SEPHORA_FRANCE,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      COMPANIES.MEDIAMARKT,
    ].filter(Boolean),
  },
];
