import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  error: false,
  uploaded: false,
};

const fetchTask = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.PUT_TASK_DOCUMENTS:
      console.log('state :', state);
      return { ...state, fetching: true, uploaded: false };
    case Types.PUT_TASK_DOCUMENTS_SUCCESS:
      return {
        error: false,
        uploaded: true,
      };
    case Types.PUT_TASK_DOCUMENTS_FAILED:
      return {
        uploaded: false,
        error: true,
      };

    default:
      return state;
  }
};
fetchTask.Types = Types;

export default fetchTask;
