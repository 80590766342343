import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: {},
  fetching: false,
  error: false,
  updated: 0,
};

const prepareNewIssueTask = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.PREPARE_NEW_ISSUE_TASK:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.PREPARE_NEW_ISSUE_TASK_SUCCESS:
      return {
        ...state,
        data: action.data,
        updated: state.updated + 1,
        //myTasks: []
      };
    case Types.PREPARE_NEW_ISSUE_TASK_FAILED:
      return {
        ...state,
        error: false,
        updated: state.updated + 1,
        //myTasks: []
      };
    default:
      return state;
  }
};
prepareNewIssueTask.Types = Types;

export default prepareNewIssueTask;
