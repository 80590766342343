/**
 * Provides an empty state for the core application.
 * This includes the default keys and objects.
 */

import { DefaultState as authentication } from './reducers/authentication';
import { DefaultState as user } from './reducers/user';
import { DefaultState as company } from './reducers/company';
import { DefaultState as application } from './reducers/application';

export default {
    authentication,
    user,
    company,
    application,
};