/**
 * Returns a new immutable object based on the previous state object,
 * and the new values that are being changed.
 * The newState object does not have to be the same mapping.
 * This wraps Object.assign, so nesting may not be reliable.
 *
 * @param {object} oldState The previous state at the start of the reducer
 * @param {object} newState The next state values that are being changed
 */
export const nextState = (oldState, newState) =>
  Object.assign({}, oldState, newState);

/**
 * Takes an optional date string (or null) and returns a date object.
 * If null, then null is returned.
 * @param {string|null} date Either a RFC-83339 date string, or null
 */
export const optDate = (date) => (date ? new Date(date) : null);
