import Types from 'Retail/state/actions/store';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  created: false,
};

const floorManagementReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //!get
    case Types.FETCH_STORE_FLOOR_MANAGEMENT:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.FETCH_STORE_FLOOR_MANAGEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case Types.FETCH_STORE_FLOOR_MANAGEMENT_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    //!create
    case Types.POST_STORE_FLOOR_MANAGEMENT:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.POST_STORE_FLOOR_MANAGEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.POST_STORE_FLOOR_MANAGEMENT_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    //!update
    case Types.PUT_STORE_FLOOR_MANAGEMENT:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.PUT_STORE_FLOOR_MANAGEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.PUT_STORE_FLOOR_MANAGEMENT_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    //!delete
    case Types.DELETE_STORE_FLOOR_MANAGEMENT:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.DELETE_STORE_FLOOR_MANAGEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.DELETE_STORE_FLOOR_MANAGEMENT_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default floorManagementReducers;
