import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
};

const fetchCategories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_CATEGORIES:
      return { ...state, fetching: true };
    case Types.FETCH_CATEGORIES_SUCCESS:
      console.log('action.data,state.data', action.data, state.data);
      return {
        fetching: false,
        error: false,
        data: action.data || [],
        updated: state.updated + 1,
      };
    case Types.FETCH_CATEGORIES_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };

    default:
      return state;
  }
};
fetchCategories.Types = Types;

export default fetchCategories;
