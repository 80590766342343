import { message } from 'antd';
import moment from 'dayjs';
import durationTimePlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import { isObject, uniqBy } from 'lodash';
import { useEffect, useRef } from 'react';
moment.extend(relativeTimePlugin);
moment.extend(durationTimePlugin);
moment.locale('en');

export const useDidUpdate = (callback, deps) => {
  const hasMount = useRef(false);
  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, deps);
};

export const checkApprovers = async (obj) => {
  return new Promise((resolve, reject) => {
    if (Object.keys(obj).length > 0 && obj.childTemplates) {
      let loc;
      for (let i = 0; i < obj.childTemplates.length; i++) {
        loc = obj.childTemplates[i];
        if (obj.requireStartingApproval) {
          if (loc.beforeApprovers.individuals.length === 0) {
            message.warning(
              'You must select Before Start Approvers for all childTemplates',
            );
            resolve(false);
            return;
          }
        }
        if (obj.requireEndingApproval) {
          if (loc.afterApprovers.individuals.length === 0) {
            message.warning(
              'You must select Upon Finish Approvers for all childTemplates',
            );
            resolve(false);
            return;
          }
        }
        if (obj.requireRating) {
          if (loc.raters.individuals.length === 0) {
            message.warning('You must select Raters for all childTemplates');
            resolve(false);
            return;
          }
        }
        if (i === obj.childTemplates.length - 1) {
          resolve(true);
        }
      }
    }
  });
};

export const checkExplanationAndDocuments = (obj) => {
  return new Promise((resolve, reject) => {
    if (Object.keys(obj).length > 0 && obj.locations) {
      let loc;
      for (let i = 0; i < obj.locations.length; i++) {
        loc = obj.locations[i];
        if (obj.requireStartExplanation) {
          if (
            !loc.startingExplanation ||
            loc.startingExplanation.length === 0
          ) {
            message.warning(
              'You must select Before Start Explanation for all locations',
            );
            resolve(false);
            return;
          }
        }
        if (obj.requireStartAttachments) {
          if (!loc.startingDocuments || loc.startingDocuments.length === 0) {
            message.warning(
              'You must select Before Start Documents for all locations',
            );
            resolve(false);
            return;
          }
        }
        if (obj.requireExplanation) {
          if (!loc.explanation || loc.explanation.length === 0) {
            message.warning(
              'You must select Upon Finish Explanation for all locations',
            );
            resolve(false);
            return;
          }
        }
        if (obj.requireAttachments) {
          if (!loc.documents || loc.documents.length === 0) {
            message.warning(
              'You must select Upon Finish Documents for all locations',
            );
            resolve(false);
            return;
          }
        }
        if (i === obj.locations.length - 1) {
          resolve(true);
        }
      }
    }
  });
};

export const convertBackupForTemplate = (
  { childTemplates, ...newTemplate },
  locations = [],
) => {
  const template = {
    ...newTemplate,
    key: '*',
    location: {
      id: '',
      name: 'All Locations',
    },
    locations: newTemplate.locations,
    people: {
      type: newTemplate.peopleType,
      automation: {
        locations: childTemplates
          .map((i) =>
            (i.performers || []).filter((i) => i.type === 'LOCATION').flat(),
          )
          .flat(),
        departments: childTemplates
          .map((i) =>
            (i.performers || []).filter((i) => i.type === 'DEPARTMENT').flat(),
          )
          .flat(),
        permissions: childTemplates
          .map((i) =>
            (i.performers || []).filter((i) => i.type === 'PERMISSION').flat(),
          )
          .flat(),
      },
      individuals: childTemplates
        .map((i) =>
          (i.performers || []).filter((i) => i.type === 'USER').flat(),
        )
        .flat(),
    },
    type: {
      name: convertPerformerModeToTaskType(newTemplate.performerMode, false),
      delegatable: newTemplate.delegatable,
    },
    dueDate: {
      type: 'none',
      duration: {
        amount: 1,
        unit: 'hours',
      },
      date: null,
    },
    beforeApprovers: {
      type: 'individuals',
      individuals: [],
    },
    afterApprovers: {
      type: 'individuals',
      individuals: [],
    },
    raters: {
      type: 'individuals',
      individuals: [],
    },
    childTemplates: (childTemplates || []).map((loc) => ({
      ...loc,
      key: loc.id ? loc.id : loc.location.id,
      location: loc.performedAtLocationId
        ? locations.find((i) => i.id === loc.performedAtLocationId)
        : loc.id
          ? loc
          : loc.location,
      people: {
        type: loc.performedAtLocationId ? 'backup' : 'none',
        automation: {
          locations: loc.performedAtLocationId
            ? (loc.performers || []).filter((i) => i.type === 'LOCATION')
            : [],
          departments: loc.performedAtLocationId
            ? (loc.performers || []).filter((i) => i.type === 'DEPARTMENT')
            : [],
          permissions: loc.performedAtLocationId
            ? (loc.performers || []).filter((i) => i.type === 'PERMISSION')
            : [],
        },
        individuals: loc.performedAtLocationId
          ? (loc.performers || []).filter((i) => i.type === 'USER')
          : [],
      },
      type: {
        name: loc.performedAtLocationId
          ? convertPerformerModeToTaskType(loc.performerMode, false)
          : '',
        delegatable: loc.performedAtLocationId ? loc.delegatable : false,
      },
      dueDate: {
        type: loc.performedAtLocationId
          ? (loc.dueDateType || '').toLocaleLowerCase()
          : 'none',
        duration: {
          amount: 1,
          unit: 'hours',
        },
        date: loc.performedAtLocationId ? loc.dueDate : null,
      },
      beforeApprovers: {
        type: 'individuals',
        individuals: loc.performedAtLocationId
          ? loc.startingApprovalUsers || []
          : [],
      },
      afterApprovers: {
        type: 'individuals',
        individuals: loc.performedAtLocationId
          ? loc.endingApprovalUsers || []
          : [],
      },
      raters: {
        type: 'individuals',
        individuals: loc.performedAtLocationId ? loc.raters || [] : [],
      },
      requireStartAttachments: loc.requireStartAttachments,
      requireStartExplanation: loc.requireStartExplanation,
      autoAssignLocation: loc.autoAssignLocation,
      workflowOnly: loc.workflowOnly,
      requireStartingApproval: loc.requireStartingApproval,
      requireEndingApproval: loc.requireEndingApproval,
      requireAttachments: loc.requireAttachments,
      requireExplanation: loc.requireExplanation,
      requireRating: loc.requireRating,
      delegatable: loc.delegatable,
    })),
  };
  return template;
};
export const convertTemplateforPost = async (sourceTemplate) => {
  const convertedObj = await loopChildTemplates(sourceTemplate);
  sourceTemplate.locations = sourceTemplate.locations.filter(
    (i) => typeof i !== 'string',
  );
  const template = {
    name: sourceTemplate.name,
    description: sourceTemplate.description,
    icon: isObject(sourceTemplate.icon)
      ? JSON.stringify(sourceTemplate.icon)
      : sourceTemplate.icon,
    taskCategoryId: sourceTemplate.taskCategory?.id,
    taskCategory: sourceTemplate.taskCategory,
    // tags: sourceTemplate.tags,
    requireStartAttachments: sourceTemplate.requireStartAttachments,
    requireStartExplanation: sourceTemplate.requireStartExplanation,
    requireStartingApproval: sourceTemplate.requireStartingApproval,
    requireAttachments: sourceTemplate.requireAttachments,
    requireExplanation: sourceTemplate.requireExplanation,
    requireEndingApproval: sourceTemplate.requireEndingApproval,
    requireRating: sourceTemplate.requireRating,
    dueDateType: 'NONE',
    peopleType: 'NONE',
    performerMode: 'EACH',
    delegatable: false,
    draft: sourceTemplate.draft || false,
    autoAssignLocation: true,
    workflowOnly: false,
    departments: await reduceArrayToPropArray(
      sourceTemplate.departments || [],
      'id',
    ),
    owners: await reduceArrayToPropArray(sourceTemplate.owners || [], 'id'),
    permissions: await reduceArrayToPropArray(
      sourceTemplate.permissions || [],
      'id',
    ),
    locations: await reduceArrayToPropArray(
      sourceTemplate.locations || [],
      'id',
    ),
    performers: [], // (sourceTemplate.performers || []).map(i => ({ performerId: i.id, performerType: i.type })),
    childTemplates: convertedObj,
  };
  return template;
};
/*
             "startingApprovalPermissions": [
              {
               "value": "<Error: Too many levels of nesting to fake this schema>"
              },
              {
               "value": "<Error: Too many levels of nesting to fake this schema>"
              }
             ],
             "startingApprovalUsers": [
              {
               "value": "<Error: Too many levels of nesting to fake this schema>"
              },
              {
               "value": "<Error: Too many levels of nesting to fake this schema>"
              }
             ],
*/
export const loopChildTemplates = (sourceTemplate) => {
  let { childTemplates } = sourceTemplate;
  return new Promise(async (resolve, reject) => {
    try {
      let result = [],
        child,
        temp,
        performerMode,
        dueDate,
        peopleType,
        locations = [],
        departments = [],
        permissions = [],
        employees = [],
        startingApprovalUsers = [],
        startingApprovalPermissions = [],
        endingApprovalUsers = [],
        endingApprovalPermissions = [],
        raters = [];
      for (let i = 0; i < childTemplates.length; i++) {
        let beforeApprovers = 'INDIVIDUAL';
        let afterApprovers = 'INDIVIDUAL';

        temp = childTemplates[i];
        peopleType = temp.people['type'].toUpperCase();
        if (temp.people.type === 'backup') {
          peopleType =
            temp.people.individuals.length > 0 &&
            temp.people.automation.locations.length > 0 &&
            temp.people.automation.locations.length > 0 &&
            temp.people.automation.locations.length > 0
              ? 'AUTOMATED'
              : 'INDIVIDUAL';
          employees = await reduceArrayToPropArray(
            temp.people.individuals,
            'id',
          );
          locations = await reduceArrayToPropArray(
            uniqBy(temp.people.automation.locations, 'locationId'),
            'id',
          );
          departments = await reduceArrayToPropArray(
            temp.people.automation.departments,
            'id',
          );
          permissions = await reduceArrayToPropArray(
            temp.people.automation.permissions,
            'id',
          );
        }
        if (temp.people.type === 'individuals') {
          peopleType = 'INDIVIDUAL';
          employees = await reduceArrayToPropArray(
            temp.people.individuals,
            'id',
          );
        }
        if (temp.people.type === 'auto') {
          peopleType = 'AUTOMATED';
          locations = await reduceArrayToPropArray(
            temp.people.automation.locations,
            'id',
          );
          departments = await reduceArrayToPropArray(
            temp.people.automation.departments,
            'id',
          );
          permissions = await reduceArrayToPropArray(
            temp.people.automation.permissions,
            'id',
          );
        }
        if (sourceTemplate.requireStartingApproval) {
          startingApprovalUsers = await reduceArrayToPropArray(
            temp.beforeApprovers.individuals,
            'id',
          );
          if (temp.beforeApprovers.type === 'backup') {
            beforeApprovers =
              temp.beforeApprovers.individuals.length > 0 &&
              temp.beforeApprovers.automation.locations.length > 0 &&
              temp.beforeApprovers.automation.locations.length > 0 &&
              temp.beforeApprovers.automation.locations.length > 0
                ? 'AUTOMATED'
                : 'INDIVIDUAL';
            startingApprovalPermissions = await reduceArrayToPropArray(
              temp.beforeApprovers.automation.permissions,
              'id',
            );
          }

          if (temp.beforeApprovers.type === 'auto') {
            beforeApprovers = 'AUTOMATED';
            startingApprovalPermissions = await reduceArrayToPropArray(
              temp.beforeApprovers.automation.permissions,
              'id',
            );
          }
          endingApprovalPermissions;
        }
        if (sourceTemplate.requireExplanation) {
          endingApprovalUsers = await reduceArrayToPropArray(
            temp.afterApprovers.individuals,
            'id',
          );
          if (temp.afterApprovers.type === 'backup') {
            afterApprovers =
              temp.afterApprovers.individuals.length > 0 &&
              temp.afterApprovers.automation.locations.length > 0 &&
              temp.afterApprovers.automation.locations.length > 0 &&
              temp.afterApprovers.automation.locations.length > 0
                ? 'AUTOMATED'
                : 'INDIVIDUAL';
            endingApprovalPermissions = await reduceArrayToPropArray(
              temp.afterApprovers.automation.permissions,
              'id',
            );
          }

          if (temp.afterApprovers.type === 'auto') {
            afterApprovers = 'AUTOMATED';
            endingApprovalPermissions = await reduceArrayToPropArray(
              temp.afterApprovers.automation.permissions,
              'id',
            );
          }
        }
        if (sourceTemplate.requireRating) {
          raters = await reduceArrayToPropArray(temp.raters.individuals, 'id');
        }
        child = {
          locationId: temp.location['id'],
          // performedAtLocationId: temp.location["id"],
          peopleType,
          performerMode: 'GROUP',
          delegatable: false,
          locations,
          departments,
          permissions,
          employees,
          startingApprovalUsers,
          endingApprovalUsers,
          startingApprovalPermissions,
          endingApprovalPermissions,
          raters,
        };

        dueDate = formatDueDate(temp.dueDate);
        if (temp.dueDate['type'] === 'none') {
          child.dueDateType = 'NONE';
        } else if (temp.dueDate['type'] === 'date') {
          child.dueDateType = 'DATE';
          child.dueDate = dueDate;
        } else if (temp.dueDate['type'] === 'end_of_day') {
          child.dueDateType = 'DATE';
          child.dueDate = dueDate;
          child.dueDateDuration = moment()
            .endOf('day')
            .format('HH:mm:ss')
            .toString();
        } else {
          child.dueDateType = 'DURATION';
          child.dueDateDuration = moment(dueDate, 'HH:mm')
            .format('HH:mm:ss')
            .toString();
        }

        if (temp.type['name'] !== '') {
          performerMode = convertTaskTypeToPerformerMode(temp.type['name']);
          child.delegatable = temp.type['delegatable'];
          child.performerMode = performerMode;
        }

        result.push(child);
        if (i === childTemplates.length - 1) resolve(result);
      }
    } catch (error) {
      reject('error', error);
    }
  });
};

export const reduceArrayToPropArray = (arr = [], propName) => {
  const reducedArray = arr.reduce(function (result, item, index, original) {
    result.push(item[propName]);
    return result;
  }, []);

  return reducedArray.filter((i) => i);
};

export const formatDueDate = (obj) => {
  switch (obj.type) {
    case 'end_of_day':
      const now = moment();
      return now.endOf('day').format('YYYY-MM-DD');
    case 'duration':
      const duration = moment()
        .add(obj.duration.amount, obj.duration.unit.toLowerCase())
        .format('dd:hh:mm');
      return duration;
    case 'date':
      const date = moment(obj.date).format('YYYY-MM-DD');
      return date;
    default:
      return 'Not Set';
  }
};

export const convertDueDate = (type = '', dueDate, duration) => {
  let date;
  switch (type.toLowerCase()) {
    case 'end_of_day':
      date = moment(dueDate).format('YYYY-MM-DD');
    case 'duration':
      const arr = duration.split(':');
      if (parseInt(arr[0]) !== 0) {
        date = moment.duration(parseInt(arr[0]), 'weeks').as('weeks') + ' Days';
      }
      if (parseInt(arr[1]) !== 0) {
        date = moment.duration(parseInt(arr[1]), 'days').as('days') + ' Hours';
      }
      if (parseInt(arr[2]) !== 0) {
        date =
          moment.duration(parseInt(arr[2]), 'hours').as('hours') + ' Minutes';
      }
      return date;
    case 'date':
      date = moment(dueDate).format('YYYY-MM-DD');
      return date;
    default:
      return 'Not Set';
  }
};

export const convertTypeToString = (taskType) => {
  if (!taskType) {
    return 'Not set';
  }

  switch (taskType) {
    case 'GROUP':
      return 'Group';
    case 'GROUP_DELEGATE':
      return 'Group (can delegate)';
    case 'CLAIM':
      return 'Claim';
    case 'CLAIM_DELEGATE':
      return 'Claim (can delegate)';
    case 'INDIVIDUAL':
      return 'Individual';
    case 'INDIVIDUAL_DELEGATE':
      return 'Individual (can delegate)';
    default:
      return 'Not Set';
  }
};

export const convertTaskTypeToPerformerMode = (taskType) => {
  switch (taskType) {
    case 'INDIVIDUAL':
      return 'EACH';
    case 'CLAIM':
      return 'CLAIM_EACH';
    case 'GROUP':
      return 'GROUP_EACH';
  }
};

export const convertPerformerModeToTaskType = (taskType, delegatable) => {
  let type;
  switch (taskType) {
    case 'EACH':
      type = 'INDIVIDUAL';
      break;
    case 'CLAIM_EACH':
      type = 'CLAIM';
      break;
    case 'GROUP_EACH':
      type = 'GROUP';
      break;
    default:
      type = null;
      break;
  }
  if (delegatable) type = type + ' (can delegatable)';
  return type;
};
let uploadFunctions = async (file) => {
  // if (!file.originFileObj.lastModifiedDate) return null;
  if (file.originFileObj) file = file.originFileObj;
  let formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      sharingMode: 'public',
    }),
  );
  formData.append('document', file);
  return fetch(
    import.meta.env.DEV
      ? `http://localhost:8085/v1/documents`
      : 'https://stg-documents-api.workmaple.com/v1/documents',
    {
      method: 'POST',
      // acceptType: "multipart/form-data",
      // contentType: "multipart/form-data"
      headers: {
        authorization: window.Maple.store.getState().authentication.token,
      },
      body: formData,
    },
  ).then((d) => (d.status === 200 ? d.json() : null));
};
export const uploadFile = (files) => {
  return Promise.all(files.map(uploadFunctions));
};

export const findKeyWithNewObject = (keys = [], obj) => {
  let newObject = {};
  for (const key of keys) {
    newObject[key] = obj[key];
  }

  return newObject;
};

export const diffDateFormatter = (
  startTime = moment(),
  timeTypeList = {
    y: 'year',
    M: 'month',
    d: 'day',
    h: 'h',
    m: 'm',
  },
) => {
  let responseStr = '',
    minutes = startTime.get('m'),
    hours = startTime.get('h'),
    day = startTime.get('d'),
    month = startTime.get('M'),
    year = startTime.get('y');
  if (year) responseStr = year + timeTypeList.y;
  if (month) responseStr = month + timeTypeList.M;
  if (day) responseStr = day + timeTypeList.d;
  if (hours) responseStr = hours + timeTypeList.h;
  if (minutes) responseStr = minutes + timeTypeList.m;

  return responseStr;
};
