import { call, put, all, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';
import { RetailAPI } from 'Core/API';

export function* fetchFloorManagementTypesSaga({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.get(
        `/store/floor-management-types?${new URLSearchParams(params)}`,
      ),
    );
    yield put({
      type: Types.FETCH_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT_FAILED });
  }
}

export function* createFloorManagementTypesSaga({ values }) {
  try {
    yield call(() => RetailAPI.post(`/store/floor-management-types`, values));
    yield all([
      put({ type: Types.POST_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS }),
      put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT_TYPES }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_FLOOR_MANAGEMENT_TYPES_FAILED });
  }
}

export function* deleteFloorManagementTypesSaga({ id }) {
  try {
    yield call(() => RetailAPI.delete(`/store/floor-management-types/${id}`));
    yield all([
      put({ type: Types.DELETE_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS }),
      call(() => fetchFloorManagementTypesSaga()),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_FLOOR_MANAGEMENT_TYPES_FAILED });
  }
}

export function* updateFloorManagementTypesSaga({ values, id }) {
  try {
    yield call(() =>
      RetailAPI.put(`/store/floor-management-types/${id}`, values),
    );
    yield all([
      put({ type: Types.PUT_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS }),
      put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT_TYPES }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_FLOOR_MANAGEMENT_TYPES_FAILED });
  }
}
