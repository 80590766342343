const Types = {
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILED: 'FETCH_USER_FAILED',

  FETCH_BUILDINGS: 'FETCH_BUILDINGS',
  FETCH_BUILDINGS_SUCCESS: 'FETCH_BUILDINGS_SUCCESS',
  FETCH_BUILDINGS_FAILED: 'FETCH_BUILDINGS_FAILED',

  FETCH_LOCATIONS: 'FETCH_LOCATIONS',
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAILED: 'FETCH_LOCATIONS_FAILED',

  FETCH_DISTRICTS: 'FETCH_DISTRICTS',
  FETCH_DISTRICTS_SUCCESS: 'FETCH_DISTRICTS_SUCCESS',
  FETCH_DISTRICTS_FAILED: 'FETCH_DISTRICTS_FAILED',

  FETCH_DEPARTMENTS: 'FETCH_DEPARTMENTS',
  FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
  FETCH_DEPARTMENTS_FAILED: 'FETCH_DEPARTMENTS_FAILED',

  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  FETCH_EMPLOYEES_SUCCESS: 'FETCH_EMPLOYEES_SUCCESS',
  FETCH_EMPLOYEES_FAILED: 'FETCH_EMPLOYEES_FAILED',

  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAILED: 'FETCH_PERMISSIONS_FAILED',
};

export const fetchUser = () => ({
  type: Types.FETCH_USER,
});

export const fetchLocations = (search) => ({
  type: Types.FETCH_LOCATIONS,
  search,
});

export const fetchBuilding = (search) => ({
  type: Types.FETCH_BUILDINGS,
  search,
});

export const fetchDistricts = (search) => ({
  type: Types.FETCH_DISTRICTS,
  search,
});

export const fetchDepartments = () => ({
  type: Types.FETCH_DEPARTMENTS,
});

export const fetchEmployees = (search) => ({
  type: Types.FETCH_EMPLOYEES,
  search,
});

export const fetchPermissions = () => ({
  type: Types.FETCH_PERMISSIONS,
});

export default Types;
