import Types from 'Retail/state/actions/checklist';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
  params: new URLSearchParams(),
  page: 1,
  size: 5,
  totalCount: 0,
};

const addNewChecklist = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_CHECKLIST:
      return {
        ...state,
        error: false,
        fetching: true,
        params: action.params || new URLSearchParams(),
      };
    case Types.FETCH_CHECKLIST_SUCCESS:
      console.log('action.data', typeof action.data);
      return {
        ...state,
        ...action,
        data: action.data,
        updated: state.updated + 1,
        error: false,
        fetching: false,
      };

    case Types.FETCH_CHECKLIST_FAILED:
      return {
        ...state,
        fetching: false,
        updated: state.updated + 1,
        error: true,
      };

    default:
      return state;
  }
};
export default addNewChecklist;
