import {
  call,
  put,
  takeEvery,
  all,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { RetailAPI, CoreAPI, DocumentsAPI } from 'Core/API';
import Types from 'Retail/state/actions/task';
import { convertTemplateforPost, uploadFile } from 'Retail/helpers';
import moment from 'dayjs';
import { message } from 'antd';

export function* fetchTemplates({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.get('/task-template?' + params, {}, {}),
    );
    yield put({ type: Types.FETCH_TEMPLATES_SUCCESS, data: data.Data });
  } catch (error) {
    yield put({ type: Types.FETCH_TEMPLATES_FAILED });
  }
}

export function* fetchCategories() {
  try {
    const data = yield call(() => RetailAPI.get('/task-category', {}, {}));
    yield put({ type: Types.FETCH_CATEGORIES_SUCCESS, data });
  } catch (error) {
    console.log('error', error);
    yield put({ type: Types.FETCH_CATEGORIES_FAILED });
  }
}

export function* fetchTags() {
  try {
    const data = yield call(() => RetailAPI.get('/tag', {}, {}));
    yield put({ type: Types.FETCH_TAGS_SUCCESS, data });
  } catch (error) {
    yield put({ type: Types.FETCH_TAGS_FAILED });
  }
}

export function* prepareNewTemplate(params) {
  const object = params.data;
  if (Object.keys(object).length === 0)
    yield put({ type: Types.PREPARE_NEW_TEMPLATE_SUCCESS, data: {} }); // reset
  const getStateValue = (state) => state.task.prepareNewTemplate.data;
  let data = yield select(getStateValue);
  // if (data.locations) {
  //   data.locations = data.locations.filter((i) => typeof i !== "string");
  // }
  for (let property in object) {
    data[property] = object[property];
  }

  yield put({ type: Types.PREPARE_NEW_TEMPLATE_SUCCESS, data });
}

export function* addNewTemplate() {
  try {
    const getRequestBody = (state) => state.task.prepareNewTemplate.data;
    let requestBody = yield select(getRequestBody);
    const convertedTemplate = yield call(() =>
      convertTemplateforPost(requestBody),
    );
    if (convertedTemplate && convertedTemplate !== 'error') {
      console.log('CONVERTED ', convertedTemplate);
      yield call(() => RetailAPI.post('/task-template', {}, convertedTemplate));
      yield put({ type: Types.ADD_NEW_TEMPLATE_SUCCESS });
      delay(100);
      yield call(() => {
        localStorage.removeItem('newTemplate');
        localStorage.removeItem('task-template:step1');
        localStorage.removeItem('task-template:step2');
        window.location.href = '/task/template';
        if (window.reactHistory) {
          window.reactHistory.push(`/task/template`);
        }
      });
      yield put({ type: Types.PREPARE_NEW_TEMPLATE, data: {} });
    } else {
      yield put({ type: Types.ADD_NEW_TEMPLATE_FAILED });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: Types.ADD_NEW_TEMPLATE_FAILED });
  }
}

export function* createCategory(params) {
  try {
    let requestBody = params.object;
    const id = yield call(() =>
      RetailAPI.post('/task-category', {}, requestBody),
    );
    yield all([
      put({ type: Types.CREATE_CATEGORY_SUCCESS }),
      put({ type: Types.FETCH_CATEGORIES }),
    ]);
  } catch (error) {
    yield put({ type: Types.CREATE_CATEGORY_FAILED });
  }
}

export function* prepareNewIssueTask(params) {
  const object = params.object;
  if (Object.keys(object).length === 0)
    yield put({ type: Types.PREPARE_NEW_ISSUE_TASK_SUCCESS, data: {} }); // reset

  const getStateValue = (state) => state.task.prepareNewIssueTask.data;
  let data = yield select(getStateValue);
  for (let property in object) {
    data[property] = object[property];
  }
  yield put({ type: Types.PREPARE_NEW_ISSUE_TASK_SUCCESS, data });
}

export function* prepareNewCustomTask(params) {
  const object = params.object;
  if (Object.keys(object).length === 0)
    yield put({ type: Types.PREPARE_NEW_CUSTOM_TASK_SUCCESS, data: {} }); // reset

  const getStateValue = (state) => state.task.prepareNewCustomTask;
  let data = yield select(getStateValue);
  for (let property in object) {
    data[property] = object[property];
  }
  yield put({ type: Types.PREPARE_NEW_CUSTOM_TASK_SUCCESS, data });
}

export function* fetchTasks({ params }) {
  params = params === null ? '' : new URLSearchParams(params);
  try {
    const payload = yield call(() => RetailAPI.get(`task?${params}`));
    yield put({ type: Types.FETCH_TASK_SUCCESS, data: payload });
  } catch (err) {
    yield put({ type: Types.FETCH_TASK_FAILED });
  }
}
// Task için sonradan dosya ekleme
export function* putTaskDocuments({
  params: { taskId, initialFiles, files, type },
}) {
  try {
    const uploadedFileList = yield call(() => uploadFile(files));
    let uploadedList = uploadedFileList.map((i) =>
      type !== 'documents'
        ? { type: i.OriginalFilename.split('.').pop(), uri: i.ID }
        : i.ID,
    );

    initialFiles[type] = [initialFiles[type], uploadedList]
      .flat()
      .filter((i) => i !== null);
    const payload = yield call(() =>
      RetailAPI.put(`task/${taskId}`, initialFiles),
    );
    yield all([
      put({ type: Types.COMPLETE_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
    if (payload) {
      message.success('Dosyalar Yüklendi!');
    } else {
      message.error('Dosyalar Yüklenemedi!');
    }
  } catch (err) {
    message.error('Dosyalar Yüklenemedi!');
  }
}

export function* deleteTasks({ id, callback }) {
  try {
    const payload = yield call(() => RetailAPI.delete(`task/${id}`));
    yield all([
      put({ type: Types.DELETE_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
    if (payload) {
      message.success('Görev Silindi!');
    } else {
      message.error('Görev Silinemedi!');
    }
  } catch {
    message.error('Görev Silinemedi, Bu hata sunucu taraflı olabilir!');
  }
}
export function* completeTasks({
  params: { id, completedBy, attachments, explanation },
}) {
  try {
    const payload = yield call(() =>
      RetailAPI.put(`task/${id}/complete`, {
        attachments,
        explanation,
      }),
    );
    yield call(() =>
      RetailAPI.put(`task/${id}`, {
        completedBy,
        timeCOmpleted: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );

    if (payload) {
      message.success('Görev Tamamlandı!');
    } else {
      message.error('Görev Tamamlanamadı!');
    }
    yield all([
      put({ type: Types.COMPLETE_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* denyTasks({ params: { id, approvedBy } }) {
  try {
    const payload = yield call(() =>
      RetailAPI.put(`task/${id}/deny`, { approvedBy }),
    );

    if (payload) {
      message.success('Görev Rededildi!');
    } else {
      message.error(
        'Görev Rededilemedi. Lütfen bu durumu yetkili birime bildirin!',
      );
    }
    yield all([
      put({ type: Types.DENY_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* beginTasks({ params: { id } }) {
  try {
    const payload = yield call(() => RetailAPI.put(`task/${id}/begin`));
    yield call(() =>
      RetailAPI.put(`task/${id}`, {
        timeStarted: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );
    if (payload) {
      message.success('Görev Başladı!');
    } else {
      message.error('Görev Başlamadı!');
    }
    yield all([
      put({ type: Types.BEGIN_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* rejectTasks({ params: { id } }) {
  try {
    const payload = yield call(() => RetailAPI.put(`task/${id}/reject`));

    if (payload) {
      message.success('Görev Başladı!');
    } else {
      message.error('Görev Başlamadı!');
    }
    yield all([
      put({ type: Types.REJECT_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* approveTasks({ params: { id } }) {
  try {
    const payload = yield call(() => RetailAPI.put(`task/${id}/approve`));
    yield call(() =>
      RetailAPI.put(`task/${id}`, {
        timeApproval: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );
    if (payload) {
      message.success('Görev Onaylandı!');
    } else {
      message.error('Görev Onaylanamadı!');
    }
    yield all([
      put({ type: Types.APPROVE_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* claimTasks({ params: { id, data } }) {
  try {
    const payload = yield call(() => RetailAPI.put(`task/${id}/claim`, data));
    yield call(() =>
      RetailAPI.put(`task/${id}`, {
        timeClaimedl: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );

    if (payload) {
      message.success('İstek Gönderildi!');
    } else {
      message.error('İsteğiniz Kabul Edilmedi!');
    }
    yield all([
      put({ type: Types.CLAIM_TASK_SUCCESS }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {
    message.error('Sunucu taraflı hata alındı!');
  }
}

export function* addNewTask(payload) {
  try {
    yield call(() =>
      uploadFile(payload.object.startingDocuments || []).then((files) => {
        payload.object.startingDocuments = files.map((i) => ({
          type: i.OriginalFilename.split('.').pop(),
          uri: i.ID,
        }));
        RetailAPI.post('/task', {}, payload.object);
      }),
    );
    yield all([
      put({ type: Types.ADD_NEW_TASK_SUCCESS, data: [] }),
      put({ type: Types.FETCH_TASK }),
    ]);
  } catch {}
}

export default function* taskSaga() {
  yield takeLatest(Types.FETCH_TEMPLATES, fetchTemplates);
  yield takeLatest(Types.FETCH_CATEGORIES, fetchCategories);
  yield takeLatest(Types.FETCH_TAGS, fetchTags);
  yield takeLatest(Types.PREPARE_NEW_TEMPLATE, prepareNewTemplate);
  yield takeLatest(Types.ADD_NEW_TEMPLATE, addNewTemplate);
  yield takeLatest(Types.CREATE_CATEGORY, createCategory);
  yield takeLatest(Types.PREPARE_NEW_ISSUE_TASK, prepareNewIssueTask);
  yield takeLatest(Types.PREPARE_NEW_CUSTOM_TASK, prepareNewCustomTask);
  yield takeLatest(Types.ADD_NEW_TASK, addNewTask);
  yield takeLatest(Types.FETCH_TASK, fetchTasks);
  yield takeLatest(Types.PUT_TASK_DOCUMENTS, putTaskDocuments);
  yield takeLatest(Types.CLAIM_TASK, claimTasks);
  yield takeLatest(Types.COMPLETE_TASK, completeTasks);
  yield takeLatest(Types.BEGIN_TASK, beginTasks);
  yield takeLatest(Types.DENY_TASK, denyTasks);
  yield takeLatest(Types.APPROVE_TASK, approveTasks);
  yield takeLatest(Types.REJECT_TASK, rejectTasks);
  yield takeLatest(Types.DELETE_TASK, deleteTasks);
}
