import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Input, Button, Result } from "antd";

import Page from "../../components/page";

import "./not-found.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
export default ({ status = "not-found", statusCode = "404" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Page
      className="route-not-found"
      title={t(`core:${status}.title`)}
      headerContent={<HeaderContent status={status} />}
    >
      <Result
        status={statusCode}
        title={t(`core:${status}.filler-header`)}
        subTitle={t(`core:${status}.filler-message`)}
        extra={
          <Button
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        }
      />
      {/* <div className="filler">
          <h3>{t(`core:${status}.filler-header`)}</h3>
          <p>{t(`core:${status}.filler-message`)}</p>
        </div> */}
    </Page>
  );
};

const HeaderContent = ({ status }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rootPath } = useSelector((state) => ({
    rootPath: state.application.rootPath,
  }));

  const redirectHome = () => navigate(rootPath);

  return (
    <Row type="flex" gutter={8}>
      <Col>
        <Input.Search placeholder="Search" enterButton allowClear />
      </Col>
      <Col>
        <Button onClick={redirectHome}>{t(`core:${status}.return`)}</Button>
      </Col>
    </Row>
  );
};
