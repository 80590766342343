const Types = {
  FETCH_CHECKLIST: 'FETCH_CHECKLIST',
  FETCH_CHECKLIST_SUCCESS: 'FETCH_CHECKLIST_SUCCESS',
  FETCH_CHECKLIST_FAILED: 'FETCH_CHECKLIST_FAILED',

  POST_CHECKLIST: 'POST_CHECKLIST',
  POST_CHECKLIST_SUCCESS: 'POST_CHECKLIST_SUCCESS',
  POST_CHECKLIST_FAILED: 'POST_CHECKLIST_FAILED',

  PUT_CHECKLIST: 'PUT_CHECKLIST',
  PUT_CHECKLIST_SUCCESS: 'PUT_CHECKLIST_SUCCESS',
  PUT_CHECKLIST_FAILED: 'PUT_CHECKLIST_FAILED',

  GET_CHECKLIST_CATEGORY: 'GET_CHECKLIST_CATEGORY',
  GET_CHECKLIST_CATEGORY_SUCCESS: 'GET_CHECKLIST_CATEGORY_SUCCESS',
  GET_CHECKLIST_CATEGORY_FAILED: 'GET_CHECKLIST_CATEGORY_FAILED',

  CREATE_CHECKLIST_CATEGORY: 'CREATE_CHECKLIST_CATEGORY',
  CREATE_CHECKLIST_CATEGORY_SUCCESS: 'CREATE_CHECKLIST_CATEGORY_SUCCESS',
  CREATE_CHECKLIST_CATEGORY_FAILED: 'CREATE_CHECKLIST_CATEGORY_FAILED',

  //Template

  FETCH_CHECKLIST_TEMPLATE: 'FETCH_CHECKLIST_TEMPLATE',
  FETCH_CHECKLIST_TEMPLATE_SUCCESS: 'FETCH_CHECKLIST_TEMPLATE_SUCCESS',
  FETCH_CHECKLIST_TEMPLATE_FAILED: 'FETCH_CHECKLIST_TEMPLATE_FAILED',

  POST_CHECKLIST_TEMPLATE: 'POST_CHECKLIST_TEMPLATE',
  POST_CHECKLIST_TEMPLATE_SUCCESS: 'POST_CHECKLIST_TEMPLATE_SUCCESS',
  POST_CHECKLIST_TEMPLATE_FAILED: 'POST_CHECKLIST_TEMPLATE_FAILED',

  PUT_CHECKLIST_TEMPLATE: 'PUT_CHECKLIST_TEMPLATE',
  PUT_CHECKLIST_TEMPLATE_SUCCESS: 'PUT_CHECKLIST_TEMPLATE_SUCCESS',
  PUT_CHECKLIST_TEMPLATE_FAILED: 'PUT_CHECKLIST_TEMPLATE_FAILED',

  REMOVE_CHECKLIST_TEMPLATE: 'REMOVE_CHECKLIST_TEMPLATE',
  REMOVE_CHECKLIST_TEMPLATE_SUCCESS: 'REMOVE_CHECKLIST_TEMPLATE_SUCCESS',
  REMOVE_CHECKLIST_TEMPLATE_FAILED: 'REMOVE_CHECKLIST_TEMPLATE_FAILED',

  GET_CHECKLIST_TEMPLATE_CATEGORY: 'GET_CHECKLIST_TEMPLATE_CATEGORY',
  GET_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS:
    'GET_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS',
  GET_CHECKLIST_TEMPLATE_CATEGORY_FAILED:
    'GET_CHECKLIST_TEMPLATE_CATEGORY_FAILED',

  CREATE_CHECKLIST_TEMPLATE_CATEGORY: 'CREATE_CHECKLIST_TEMPLATE_CATEGORY',
  CREATE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS:
    'CREATE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS',
  CREATE_CHECKLIST_TEMPLATE_CATEGORY_FAILED:
    'CREATE_CHECKLIST_TEMPLATE_CATEGORY_FAILED',
};

export const fetchChecklist = (params, suffix = '') => ({
  type: Types.FETCH_CHECKLIST,
  params,
  suffix,
});

export const createChecklist = (params) => ({
  type: Types.POST_CHECKLIST,
  params,
});

export const updateChecklist = (params) => ({
  type: Types.PUT_CHECKLIST,
  params,
});

// Template

export const fetchChecklistTemplate = (params) => {
  console.log('params', params);
  return {
    type: Types.FETCH_CHECKLIST_TEMPLATE,
    params,
  };
};

export const createChecklistTemplate = (params) => ({
  type: Types.POST_CHECKLIST_TEMPLATE,
  params,
});

export const updateChecklistTemplate = (params) => ({
  type: Types.PUT_CHECKLIST_TEMPLATE,
  params,
});

export const removeChecklistTemplate = (params) => ({
  type: Types.REMOVE_CHECKLIST_TEMPLATE,
  params,
});

//Category

export const createChecklistCategory = (values) => ({
  type: Types.CREATE_CHECKLIST_CATEGORY,
  values,
});

export const getChecklistCategory = (params) => ({
  type: Types.GET_CHECKLIST_CATEGORY,
  params,
});

export default Types;
