import ManageActions from '@maple-tech/knowledge-base-web-app/Admin/Manage';
import DocumentModifier from '@maple-tech/knowledge-base-web-app/Admin/Tabs/Articles/Modifier';
import ArticleDetail from '@maple-tech/knowledge-base-web-app/ArticleDetails';
import KnowledgeBase from '@maple-tech/knowledge-base-web-app/HomePage';
import Topic from '@maple-tech/knowledge-base-web-app/Topic';
import { Outlet } from 'react-router-dom';

import StorageIcon from '@mui/icons-material/StorageRounded';
// Dependencies
import 'swiper/css';
import 'swiper/css/navigation';
import Page from '../../../core/components/page';
import { COMPANIES, COUNTRIES } from 'Core/API';
const KNOWLEDGE_BASE_ROUTE = {
  index: 'knowledge-base',
  category: 'knowledge-base/category',
  detail: 'knowledge-base/detail',
  manage: 'knowledge-base/manage',
  createDocument: 'knowledge-base/manage/document/create',
  editDocument: 'knowledge-base/manage/document/:id/edit',
  topics: 'knowledge-base/topics',
};
const KnowledgeBaseRoutes = (authorizationLevel, countryId) => ({
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.SEPHORA,
    COMPANIES.DEMO,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
    // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
  ].filter(Boolean),
  path: KNOWLEDGE_BASE_ROUTE.index,
  title: 'Knowledge Base',
  icon: <StorageIcon />,
  element: (
    <Page title="Knowledge Base" contentClassName="!p-0">
      <Outlet />
    </Page>
  ),
  children: [
    {
      element: <KnowledgeBase />,
      title: 'retail:navigation.knowledge.articles',
      menuItem: true,
      index: true,
      requiredRoles: [
        'admin',
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.SEPHORA,
        // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      ].filter(Boolean),
    },
    {
      path: `manage`,
      requiredRoles: [
        'admin',
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.LOREM,
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.WORKMAPLE,
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.DEMO,
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.VINEYARD_VALLEY,
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.CENTRAL_RETAIL,
        authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
        // authorizationLevel.COUNTRY_MANAGER &&
        //   COUNTRIES.MM_HOLLAND === countryId &&
        //   COMPANIES.MEDIAMARKT,
      ].filter(Boolean),
      menuItem: true,
      title: 'retail:navigation.knowledge.manage-articles',
      element: <Outlet />,
      children: [
        {
          element: <ManageActions />,
          index: true,
          requiredRoles: [
            'admin',
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.LOREM,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.WORKMAPLE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.DEMO,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.VINEYARD_VALLEY,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            // authorizationLevel.COUNTRY_MANAGER &&
            //   COUNTRIES.MM_HOLLAND === countryId &&
            //   COMPANIES.MEDIAMARKT,
          ].filter(Boolean),
        },
        {
          element: <DocumentModifier />,
          path: 'document/create',
          requiredRoles: [
            'admin',
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.LOREM,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.WORKMAPLE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.DEMO,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.VINEYARD_VALLEY,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            // authorizationLevel.COUNTRY_MANAGER &&
            //   COUNTRIES.MM_HOLLAND === countryId &&
            //   COMPANIES.MEDIAMARKT,
          ].filter(Boolean),
        },
        {
          element: <DocumentModifier />,
          path: 'document/:id/edit',
          requiredRoles: [
            'admin',
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.LOREM,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.WORKMAPLE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.DEMO,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.VINEYARD_VALLEY,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            // authorizationLevel.COUNTRY_MANAGER &&
            //   COUNTRIES.MM_HOLLAND === countryId &&
            //   COMPANIES.MEDIAMARKT,
          ].filter(Boolean),
        },
      ],
    },
    {
      element: <Topic />,
      path: `topics/:id`,
      requiredRoles: [
        'admin',
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.SEPHORA,
        // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      ].filter(Boolean),
    },
    {
      element: <ArticleDetail />,
      path: `detail/:id`,
      requiredRoles: [
        'admin',
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.SEPHORA,
        // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      ].filter(Boolean),
    },
  ],
});
export default KnowledgeBaseRoutes;
// !Todo Aslında aşağıdaki gibi olmalı
// import KnowledgeBaseRouteGenerator from "@maple-tech/knowledge-base-web-app";
// import Page from "../../../core/components/page/index.jsx";
// // Dependencies
// import "swiper/css";
// import "swiper/css/navigation";

// export default KnowledgeBaseRouteGenerator(Page);
