import { useUserController } from '@maple-tech/auth';
import { CoreAPI, RetailAPI } from 'Core/API';
import { isObject } from 'lodash';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import Types from 'Retail/state/actions/retail';

export function* fetchRetailUser({ params }) {
  try {
    const user = useUserController.getState().user;
    if (!user) throw new Error('User not found');
    // let data = yield call(() =>
    //   RetailAPI.get("/employee/" + user.id || user.user?.id)
    // );
    console.log(user);
    // window.Maple.injectNavigation(data.isManager);
    yield put({ type: Types.FETCH_USER_SUCCESS, data: user });
  } catch (error) {
    console.error('error', error);
    yield put({ type: Types.FETCH_USER_FAILED });
  }
}
function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}
const searchObj2Params = (search) => {
  let params = '';
  if (search) {
    params = '?';
    for (let key in search) {
      if (search[key]) params += key + '=' + search[key] + '&';
    }
    params = params.substring(0, params.length - 1);
  }
  return params;
};

export function* fetchLocations({ search }) {
  yield call(waitFor, (state) => state.retail.user.fetching !== true);
  const user = yield select((state) => state.retail.user);

  if (!search && !isObject(search)) search = {};
  if (!search?.countryId) {
    search = { countryId: user.countryId };
  }
  if (user?.isRegionManager || !!user?.regionId) {
    search = { ...search, regionId: user.regionId };
  }
  if (user?.isDistrictManager || !!user?.districtId) {
    search.districtId = user.districtId;
  }
  if (Object.keys(search).length === 0) return;
  try {
    const data = yield call(() =>
      RetailAPI.get('/location' + searchObj2Params(search)),
    );
    yield put({
      type: Types.FETCH_LOCATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_LOCATIONS_FAILED });
  }
}

export function* fetchBuildingSaga({ search, ...rest }) {
  yield call(waitFor, (state) => state.retail.user.fetching !== true);
  const user = yield select((state) => state.retail.user);

  if (!search && !isObject(search)) search = {};
  if (!search?.countryId) {
    search = { countryId: user.countryId };
  }
  // !Geçici
  // if (user?.isRegionManager || !!user?.regionId) {
  //   search = { ...search, regionId: user.regionId };
  // }
  // if (user?.isDistrictManager || !!user?.districtId) {
  //   search.districtId = user.districtId;
  // }
  console.log('Object.keys(search).length', user);
  if (Object.keys(search).length === 0) return;
  try {
    const data = yield call(() =>
      RetailAPI.get('/building' + searchObj2Params(search)),
    );
    yield put({
      type: Types.FETCH_BUILDINGS_SUCCESS,
      data: (data || []).filter((i) => i.locationId !== '-1'),
    });
  } catch (error) {
    yield put({ type: Types.FETCH_BUILDINGS_FAILED });
  }
}

export function* fetchDistrictSaga({ search }) {
  try {
    yield call(waitFor, (state) => state.retail.user.fetching !== true);
    const user = yield select((state) => state.retail.user);
    if (!search && !isObject(search)) search = {};
    if (!search?.countryId) {
      search = { countryId: user.countryId };
    }
    // !Geçici
    // if (user?.isRegionManager || !!user?.regionId) {
    //   search = { ...search, regionId: user.regionId };
    // }
    // if (user?.isDistrictManager || !!user?.districtId) {
    //   search.districtId = user.districtId;
    // }
    if (Object.keys(search).length === 0) return;
    const { data = [] } = yield call(() =>
      RetailAPI.get('/district' + searchObj2Params(search)),
    );
    yield put({
      type: Types.FETCH_DISTRICTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_DISTRICTS_FAILED });
  }
}

export function* fetchDepartments() {
  try {
    const { data } = yield call(() => CoreAPI.get('/company/department'));
    yield put({ type: Types.FETCH_DEPARTMENTS_SUCCESS, data });
  } catch (error) {
    console.error(error);
    yield put({ type: Types.FETCH_DEPARTMENTS_FAILED });
  }
}

export function* fetchEmployees({ search }) {
  try {
    yield call(waitFor, (state) => state.retail.user.fetching !== true);
    const user = yield select((state) => state.retail.user);
    if (!search && !isObject(search)) search = {};
    console.log(
      'localStorage.has("grayGandalfAdmin")',
      localStorage.getItem('grayGandalfAdmin') === 'true',
    );
    if (!localStorage.getItem('grayGandalfAdmin') && !search?.countryId) {
      search = { countryId: user.countryId };
    }
    // if (user?.isRegionManager || !!user?.regionId) {
    //   search = { ...search, regionId: user.regionId };
    // }
    // if (user?.isDistrictManager || !!user?.districtId) {
    //   search.districtId = user.districtId;
    // }
    if (
      !localStorage.getItem('grayGandalfAdmin') &&
      Object.keys(search).length === 0
    )
      return;
    const data = yield call(() =>
      RetailAPI.get('/employee' + searchObj2Params(search)),
    );
    yield put({ type: Types.FETCH_EMPLOYEES_SUCCESS, data });
  } catch (error) {
    yield put({ type: Types.FETCH_EMPLOYEES_FAILED });
  }
}

export function* fetchPermissions() {
  try {
    const getStateValue = (state) => state.company;
    let company = yield select(getStateValue);
    const { data } = yield call(() => CoreAPI.get('/company/permission'));
    yield put({
      type: Types.FETCH_PERMISSIONS_SUCCESS,
      data: data || [],
    });
  } catch (error) {
    yield put({ type: Types.FETCH_PERMISSIONS_FAILED });
  }
}
export default function* retailSaga() {
  yield takeLatest(Types.FETCH_USER, fetchRetailUser);
  yield takeLatest(Types.FETCH_LOCATIONS, fetchLocations);
  yield takeLatest(Types.FETCH_BUILDINGS, fetchBuildingSaga);
  yield takeLatest(Types.FETCH_DISTRICTS, fetchDistrictSaga);
  yield takeLatest(Types.FETCH_DEPARTMENTS, fetchDepartments);
  yield takeLatest(Types.FETCH_EMPLOYEES, fetchEmployees);
  yield takeLatest(Types.FETCH_PERMISSIONS, fetchPermissions);
}
