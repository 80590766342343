const Types = {
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAILED: 'FETCH_ROLES_FAILED',

  ADD_ROLES: 'ADD_ROLES',
  ADD_ROLES_SUCCESS: 'ADD_ROLES_SUCCESS',
  ADD_ROLES_FAILED: 'ADD_ROLES_FAILED',

  DELETE_ROLES: 'DELETE_ROLES',
  DELETE_ROLES_SUCCESS: 'DELETE_ROLES_SUCCESS',
  DELETE_ROLES_FAILED: 'DELETE_ROLES_FAILED',
};

/**
 * @param {schoolId} query
 */

export const fetchRoles = (action) => ({ type: Types.FETCH_ROLES, action });

export const addRoles = (action) => ({ type: Types.ADD_ROLES, action });

export const deleteRoles = (payload) => ({ type: Types.DELETE_ROLES, payload });

export default Types;
