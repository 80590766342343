import { nextState } from 'Core/state/reducers/utils';
import Types from '../actions/permissions';

export const INITIAL_STATE = {
  data: [],

  loading: false,
  creating: false,
  created: false,
  error: null,
};

function Permissions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FETCH_DEFAULT_PERMISSIONS:
      return nextState(state, { loading: true });
    case Types.FETCH_DEFAULT_PERMISSIONS_SUCCESS:
      return nextState(state, {
        data: action.payload,
        error: null,
        loading: false,
      });
    case Types.FETCH_DEFAULT_PERMISSIONS_FAILED:
      return nextState(state, { data: [], error: error, loading: false });

    default:
      return state;
  }
}

export default Permissions;
