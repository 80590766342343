import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
};

const createCategory = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.CREATE_CATEGORY:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case Types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        //templates: action.data,
        data: [],
        updated: state.updated + 1,
        error: false,
        fetching: false,
      };

    case Types.CREATE_CATEGORY_FAILED:
      return {
        ...state,
        fetching: false,
        updated: state.updated + 1,
        error: true,
      };

    default:
      return state;
  }
};
createCategory.Types = Types;

export default createCategory;
