import { message } from 'antd';
import { RetailAPI } from 'Core/API';
import moment from 'dayjs';
import { all, call, delay, put, select, take } from 'redux-saga/effects';
import { findKeyWithNewObject, uploadFile } from 'Retail/helpers';
import Types from 'Retail/state/actions/checklist';

function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}
const keyList = {
  template: [
    'icon',
    'name',
    'maxScore',
    'description',
    'scoreThreshold',
    'locations',
    'tags',
    'departments',
    'checklistType',
    'checklistCategoryId',
    'performers',
    'visibleToTaker',
    'totalScore',
    'maxDuration',
    'scheduleType',
    'taggedTemplate',
  ],
  item: [],
  section: [],
};
let maxDurationRoles = ['minutes', 'hours', 'day', 'week', 'month', 'year'];
let createMaxDuration = (value, role) => {
  // return moment().add(value, role).format("YYYY-MM-DDTHH:mm:ssZ");
  return moment().add(value, role).toJSON();
};

const asyncMap = (data, callback) =>
  Promise.all(data.map((...d) => callback(...d)));

const messageSwitcher = (
  sectionCount,
  itemCount,
  sectionIndex,
  itemIndex,
  isCompleted = false,
) => {
  let messageKey = 'checklist-completed-id';
  if (isCompleted) {
    message.success({ content: 'Checklist Created', key: messageKey });
  } else {
    let msg = `${sectionIndex / sectionCount} bölümünde ${
      itemIndex / itemCount
    } eleman yüklendi`;
    message.loading({ content: 'Checklist Created', key: messageKey });
  }
};

export function* createChecklistTemplate({ params }) {
  console.log('params', params);
  try {
    const [user, locations, departments, buildings] = yield all([
      select((state) => state.user),
      select((state) => state.retail.locations.data),
      select((state) => state.retail.departments.data),
      select((state) => state.retail.buildings.data),
    ]);
    message.loading({
      type: 'info',
      content: 'İçerik Düzenlendikten Sonra Yükleme İşlemi Başlayacak',
      key: 'checklist-completed-id',
    });
    let templateData = findKeyWithNewObject(keyList.template, params);
    templateData.createdBy = user.id;
    templateData.departments = [];
    templateData.locations = [];
    // if (
    //   templateData.departments.length === 1 &&
    //   templateData.departments[0] === "*"
    // )
    //   templateData.departments = departments.map((i) => i.id);
    // if (
    //   templateData.locations.length === 1 &&
    //   templateData.locations[0] === "*"
    // ) {
    //   templateData.locations = buildings.map((i) => i.id);
    // }
    // templateData.locationAndBuildingId = templateData.locations
    //   .map((i) => {
    //     let locID = buildings.find((a) => a.id === i).locationId;
    //     if (i !== "-1" && locID && locID !== "-1")
    //       return {
    //         locationId: locID,
    //         buildingId: i,
    //       };
    //   })
    //   .filter((i) => i);
    if (templateData.maxDuration && templateData.maxDuration.enable) {
      templateData.maxDuration = createMaxDuration(
        templateData.maxDuration.value,
        templateData.maxDuration.role,
      );
    } else {
      templateData.maxDuration = null;
    }
    templateData.tags = [];
    yield RetailAPI.post('/checklist-template', templateData).then(
      (templateId) => {
        return asyncMap(params.section, (tmp, sectionIndex) => {
          messageSwitcher(params.section.length, tmp.length, sectionIndex, 0);
          let { items, ...templateSection } = tmp;
          templateSection.scoringWeight = parseFloat(
            templateSection.scoringWeight,
          );
          templateSection.templateId = templateId;
          templateSection.optional = false;
          return RetailAPI.post(
            '/checklist-template-section',
            templateSection,
          ).then((sectionId) => {
            return asyncMap(items, (sectionItem, k) => {
              messageSwitcher(
                params.section.length,
                tmp.length,
                sectionIndex,
                k + 1,
              );
              console.log('sectionItem :>> ', sectionItem);
              sectionItem.templateId = templateId;
              sectionItem.itemType = sectionItem.itemType.toUpperCase();
              sectionItem.sectionId = sectionId.id;
              sectionItem.optional = Boolean(sectionItem.optional);

              sectionItem.sectionIndex = k;
              // sectionItem.templateID = templateId;
              if (
                sectionItem.itemType === 'DATE' &&
                sectionItem.datePassType === 'DURATION'
              ) {
                sectionItem.dateDuration =
                  moment().get('hours') +
                  'h' +
                  moment().get('minutes') +
                  'm' +
                  moment().get('seconds') +
                  's';
              }
              if (sectionItem.itemType === 'RATING') {
                sectionItem.ratePass =
                  sectionItem.rateMax -
                  (sectionItem.rateMax - sectionItem.rateMin) / 2;
              }
              return RetailAPI.post(
                '/checklist-template-item',
                sectionItem,
              ).then(({ id: itemId }) => {
                let { attachments = [], documents = [] } =
                  sectionItem.attachments || {
                    attachments: [],
                    documents: [],
                  };
                return uploadFile(Array.isArray(attachments) ? attachments : [])
                  .then((attachmentsList) => {
                    return attachmentsList.map((i) => ({
                      type: i.OriginalFilename.split('.').pop(),
                      uri: i.ID,
                    }));
                  })
                  .then((attachments) => {
                    if (Array.isArray(documents)) {
                      return uploadFile(documents).then((documentsList) => {
                        return [
                          attachments,
                          documentsList.map((i) => ({
                            type: i.OriginalFilename.split('.').pop(),
                            uri: i.ID,
                          })),
                        ];
                      });
                    } else {
                      return [attachments, []];
                    }
                  })
                  .then(([attachments = [], documents = []]) => {
                    if (attachments.length > 0 || documents.length > 0)
                      return RetailAPI.post(
                        `/checklist-template-item-attachments`,
                        {
                          checklistItemId: itemId,
                          attachments: JSON.stringify({
                            attachments,
                            documents,
                          }),
                        },
                      );
                    return [];
                  })
                  .then(() => {
                    if (
                      sectionItem.itemType === 'MULTIPLE_CHOICE' &&
                      Array.isArray(sectionItem?.rules?.list)
                    ) {
                      return asyncMap(
                        sectionItem?.rules?.list || [],
                        (item, choiceIndex) => {
                          item.index = choiceIndex;
                          RetailAPI.post(
                            `checklist-template-item/${itemId}/choice`,
                            item,
                          );
                        },
                      );
                    }
                  });
              });
            });
          });
        });
      },
    );

    yield messageSwitcher(0, 0, 0, 0, true);
    yield delay(500);
    if (window?.reactHistory?.push) {
      yield window.reactHistory.push('/checklist/templates');
    } else {
      yield (window.location.href = '/checklist/templates');
    }

    yield put({ type: Types.POST_CHECKLIST_SUCCESS });
  } catch (error) {
    console.error(error);
    yield put({ type: Types.POST_CHECKLIST_FAILED });
  }
}

// TODO:  Template İsteklerini saga dan yeni apiye göre düzenle
export function* fetchChecklistTemplate({ params = {} }) {
  try {
    // yield call(waitFor, (state) => state.retail.user.fetching !== true);
    const user = yield select((state) => state.retail.user);

    let reqParams = new URLSearchParams();
    let entriesParams = Object.entries(params || {});
    entriesParams.map((i) => i[1] && reqParams.set(i[0], i[1]));
    if (user?.userLevel > 5 && user?.hubId) {
      reqParams.set('performerHubId', user.hubId);
      reqParams.delete('performerCountryId');
    } else if (user?.countryId) {
      reqParams.set('performerCountryId', user.countryId);
      reqParams.delete('performerHubId');
    }
    // console.log('reqParams', reqParams);
    // remove null value from params
    for (let key of reqParams.keys()) {
      if (!reqParams.get(key)) reqParams.delete(key);
    }
    let data = yield call(() =>
      RetailAPI.get('checklist-template?' + reqParams.toString()),
    );
    if (Array.isArray(data)) {
      data = data.map((item) => {
        if (item.departments === null) item.departments = [];
        if (item.locations === null) item.locations = [];
      });
    }
    yield put({
      type: Types.FETCH_CHECKLIST_TEMPLATE_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_CHECKLIST_TEMPLATE_FAILED });
  }
}

export function* removeChecklistTemplate({ params }) {
  try {
    let resp = yield call(() =>
      RetailAPI.delete('checklist-template/' + params.id),
    );
    let data = yield select((state) => state.checklist.templates.data);
    if (typeof resp === 'string') {
      yield put({
        type: Types.REMOVE_CHECKLIST_TEMPLATE_SUCCESS,
      });
      yield fetchChecklistTemplate({ param: {} });
    } else {
      yield put({ type: Types.REMOVE_CHECKLIST_TEMPLATE_FAILED });
    }
  } catch (error) {
    yield put({ type: Types.REMOVE_CHECKLIST_TEMPLATE_FAILED });
  }
}

export function* putChecklistTemplate({ params }) {
  try {
    yield put({ type: Types.FETCH_CHECKLIST });
  } catch (error) {
    yield put({ type: Types.PUT_CHECKLIST_TEMPLATE_FAILED });
  }
}
