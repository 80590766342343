const Types = {
  // Shift
  FETCH_STORE_CLUSTERS: 'FETCH_STORE_CLUSTERS',
  FETCH_STORE_CLUSTERS_SUCCESS: 'FETCH_STORE_CLUSTERS_SUCCESS',
  FETCH_STORE_CLUSTERS_FAILED: 'FETCH_STORE_CLUSTERS_FAILED',
  // Shift
  FETCH_STORE_SHIFTS: 'FETCH_STORE_SHIFTS',
  FETCH_STORE_SHIFTS_SUCCESS: 'FETCH_STORE_SHIFTS_SUCCESS',
  FETCH_STORE_SHIFTS_FAILED: 'FETCH_STORE_SHIFTS_FAILED',

  FETCH_SINGLE_STORE_SHIFTS: 'FETCH_SINGLE_STORE_SHIFTS',
  FETCH_SINGLE_STORE_SHIFTS_SUCCESS: 'FETCH_SINGLE_STORE_SHIFTS_SUCCESS',
  FETCH_SINGLE_STORE_SHIFTS_FAILED: 'FETCH_SINGLE_STORE_SHIFTS_FAILED',

  POST_STORE_SHIFT: 'POST_STORE_SHIFT',
  POST_STORE_SHIFT_SUCCESS: 'POST_STORE_SHIFT_SUCCESS',
  POST_STORE_SHIFT_FAILED: 'POST_STORE_SHIFT_FAILED',

  UPDATE_STORE_SHIFT: 'UPDATE_STORE_SHIFT',
  UPDATE_STORE_SHIFT_SUCCESS: 'UPDATE_STORE_SHIFT_SUCCESS',
  UPDATE_STORE_SHIFT_FAILED: 'UPDATE_STORE_SHIFT_FAILED',

  DELETE_STORE_SHIFT: 'DELETE_STORE_SHIFT',
  DELETE_STORE_SHIFT_SUCCESS: 'DELETE_STORE_SHIFT_SUCCESS',
  DELETE_STORE_SHIFT_FAILED: 'DELETE_STORE_SHIFT_FAILED',

  //Shift Types
  FETCH_STORE_SHIFT_TYPES: 'FETCH_STORE_SHIFT_TYPES',
  FETCH_STORE_SHIFT_TYPES_SUCCESS: 'FETCH_STORE_SHIFT_TYPES_SUCCESS',
  FETCH_STORE_SHIFT_TYPES_FAILED: 'FETCH_STORE_SHIFT_TYPES_FAILED',

  POST_STORE_SHIFT_TYPE: 'POST_STORE_SHIFT_TYPE',
  POST_STORE_SHIFT_TYPE_SUCCESS: 'POST_STORE_SHIFT_TYPE_SUCCESS',
  POST_STORE_SHIFT_TYPE_FAILED: 'POST_STORE_SHIFT_TYPE_FAILED',

  UPDATE_STORE_SHIFT_TYPE: 'UPDATE_STORE_SHIFT_TYPE',
  UPDATE_STORE_SHIFT_TYPE_SUCCESS: 'UPDATE_STORE_SHIFT_TYPE_SUCCESS',
  UPDATE_STORE_SHIFT_TYPE_FAILED: 'UPDATE_STORE_SHIFT_TYPE_FAILED',

  DELETE_STORE_SHIFT_TYPE: 'DELETE_STORE_SHIFT_TYPE',
  DELETE_STORE_SHIFT_TYPE_SUCCESS: 'DELETE_STORE_SHIFT_TYPE_SUCCESS',
  DELETE_STORE_SHIFT_TYPE_FAILED: 'DELETE_STORE_SHIFT_TYPE_FAILED',

  FETCH_STORE_FLOOR_MANAGEMENT_TYPES: 'FETCH_STORE_FLOOR_MANAGEMENT_TYPES',
  FETCH_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS:
    'FETCH_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS',
  FETCH_STORE_FLOOR_MANAGEMENT_TYPES_FAILED:
    'FETCH_STORE_FLOOR_MANAGEMENT_TYPES_FAILED',

  POST_STORE_FLOOR_MANAGEMENT_TYPES: 'POST_STORE_FLOOR_MANAGEMENT_TYPES',
  POST_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS:
    'POST_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS',
  POST_STORE_FLOOR_MANAGEMENT_TYPES_FAILED:
    'POST_STORE_FLOOR_MANAGEMENT_TYPES_FAILED',

  PUT_STORE_FLOOR_MANAGEMENT_TYPES: 'PUT_STORE_FLOOR_MANAGEMENT_TYPES',
  PUT_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS:
    'PUT_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS',
  PUT_STORE_FLOOR_MANAGEMENT_TYPES_FAILED:
    'PUT_STORE_FLOOR_MANAGEMENT_TYPES_FAILED',

  DELETE_STORE_FLOOR_MANAGEMENT_TYPES: 'DELETE_STORE_FLOOR_MANAGEMENT_TYPES',
  DELETE_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS:
    'DELETE_STORE_FLOOR_MANAGEMENT_TYPES_SUCCESS',
  DELETE_STORE_FLOOR_MANAGEMENT_TYPES_FAILED:
    'DELETE_STORE_FLOOR_MANAGEMENT_TYPES_FAILED',

  FETCH_STORE_FLOOR_MANAGEMENT: 'FETCH_STORE_FLOOR_MANAGEMENT',
  FETCH_STORE_FLOOR_MANAGEMENT_SUCCESS: 'FETCH_STORE_FLOOR_MANAGEMENT_SUCCESS',
  FETCH_STORE_FLOOR_MANAGEMENT_FAILED: 'FETCH_STORE_FLOOR_MANAGEMENT_FAILED',

  POST_STORE_FLOOR_MANAGEMENT: 'POST_STORE_FLOOR_MANAGEMENT',
  POST_STORE_FLOOR_MANAGEMENT_SUCCESS: 'POST_STORE_FLOOR_MANAGEMENT_SUCCESS',
  POST_STORE_FLOOR_MANAGEMENT_FAILED: 'POST_STORE_FLOOR_MANAGEMENT_FAILED',

  PUT_STORE_FLOOR_MANAGEMENT: 'PUT_STORE_FLOOR_MANAGEMENT',
  PUT_STORE_FLOOR_MANAGEMENT_SUCCESS: 'PUT_STORE_FLOOR_MANAGEMENT_SUCCESS',
  PUT_STORE_FLOOR_MANAGEMENT_FAILED: 'PUT_STORE_FLOOR_MANAGEMENT_FAILED',

  DELETE_STORE_FLOOR_MANAGEMENT: 'DELETE_STORE_FLOOR_MANAGEMENT',
  DELETE_STORE_FLOOR_MANAGEMENT_SUCCESS:
    'DELETE_STORE_FLOOR_MANAGEMENT_SUCCESS',
  DELETE_STORE_FLOOR_MANAGEMENT_FAILED: 'DELETE_STORE_FLOOR_MANAGEMENT_FAILED',

  FETCH_STORE_POSTS: 'FETCH_STORE_POSTS',
  FETCH_STORE_POSTS_SUCCESS: 'FETCH_STORE_POSTS_SUCCESS',
  FETCH_STORE_POSTS_FAILED: 'FETCH_STORE_POSTS_FAILED',

  POST_STORE_POSTS: 'POST_STORE_POSTS',
  POST_STORE_POSTS_SUCCESS: 'POST_STORE_POSTS_SUCCESS',
  POST_STORE_POSTS_FAILED: 'POST_STORE_POSTS_FAILED',

  PUT_STORE_POSTS: 'PUT_STORE_POSTS',
  PUT_STORE_POSTS_SUCCESS: 'PUT_STORE_POSTS_SUCCESS',
  PUT_STORE_POSTS_FAILED: 'PUT_STORE_POSTS_FAILED',

  DELETE_STORE_POSTS: 'DELETE_STORE_POSTS',
  DELETE_STORE_POSTS_SUCCESS: 'DELETE_STORE_POSTS_SUCCESS',
  DELETE_STORE_POSTS_FAILED: 'DELETE_STORE_POSTS_FAILED',

  FETCH_STORE_TRACKING_TARGET_SCHEDULE: 'FETCH_STORE_TRACKING_TARGET_SCHEDULE',
  FETCH_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS:
    'FETCH_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS',
  FETCH_STORE_TRACKING_TARGET_SCHEDULE_FAILED:
    'FETCH_STORE_TRACKING_TARGET_SCHEDULE_FAILED',

  POST_STORE_TRACKING_TARGET_SCHEDULE: 'POST_STORE_TRACKING_TARGET_SCHEDULE',
  POST_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS:
    'POST_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS',
  POST_STORE_TRACKING_TARGET_SCHEDULE_FAILED:
    'POST_STORE_TRACKING_TARGET_SCHEDULE_FAILED',

  PUT_STORE_TRACKING_TARGET_SCHEDULE: 'PUT_STORE_TRACKING_TARGET_SCHEDULE',
  PUT_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS:
    'PUT_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS',
  PUT_STORE_TRACKING_TARGET_SCHEDULE_FAILED:
    'PUT_STORE_TRACKING_TARGET_SCHEDULE_FAILED',

  DELETE_STORE_TRACKING_TARGET_SCHEDULE:
    'DELETE_STORE_TRACKING_TARGET_SCHEDULE',
  DELETE_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS:
    'DELETE_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS',
  DELETE_STORE_TRACKING_TARGET_SCHEDULE_FAILED:
    'DELETE_STORE_TRACKING_TARGET_SCHEDULE_FAILED',
};

// Shift
export const fetchStoreClusters = (params = '') => ({
  type: Types.FETCH_STORE_CLUSTERS,
  params,
});

// Shift
export const fetchSingleStoreShifts = (params = '', storeId) => ({
  type: Types.FETCH_STORE_SHIFTS,
  params,
  storeId,
});
export const fetchShifts = (params) => ({
  type: Types.FETCH_STORE_SHIFTS,
  params,
});

export const createShift = (values) => ({
  type: Types.POST_STORE_SHIFT,
  values,
});

export const updateShift = (values, id) => ({
  type: Types.DELETE_STORE_SHIFT,
  values,
  id,
});

export const deleteStoreShift = (id) => ({
  type: Types.UPDATE_STORE_SHIFT,
  id: id,
});

// Shift Type
export const fetchShiftTypes = (params) => ({
  type: Types.FETCH_STORE_SHIFT_TYPES,
  params,
});

export const createShiftType = (values) => ({
  type: Types.POST_STORE_SHIFT_TYPE,
  values,
});

export const updateShiftType = (values, id) => ({
  type: Types.DELETE_STORE_SHIFT_TYPE,
  values,
  id,
});

export const deleteStoreShiftType = (id) => ({
  type: Types.UPDATE_STORE_SHIFT_TYPE,
  id: id,
});

// Posts
export const fetchStorePosts = (params) => ({
  type: Types.FETCH_STORE_POSTS,
  params,
});

export const createStorePosts = (params) => ({
  type: Types.POST_STORE_POSTS,
  params,
});

export const updateStorePosts = (params) => ({
  type: Types.PUT_STORE_POSTS,
  params,
});

export const deleteStorePosts = (params) => ({
  type: Types.DELETE_STORE_POSTS,
  params,
});

// Tracking Target Schedule
export const fetchStoreTrackingSchedule = (params, storeId) => ({
  type: Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE,
  params,
  storeId,
});

export const createStoreTrackingSchedule = (values, storeId) => ({
  type: Types.POST_STORE_TRACKING_TARGET_SCHEDULE,
  values,
  storeId,
});

export const updateStoreTrackingSchedule = (values, id, storeId) => ({
  type: Types.PUT_STORE_TRACKING_TARGET_SCHEDULE,
  values,
  id,
  storeId,
});

export const deleteStoreTrackingSchedule = (id, storeId) => ({
  type: Types.DELETE_STORE_TRACKING_TARGET_SCHEDULE,
  id,
  storeId,
});

// FloorManagement
export const fetchStoreFloorManagement = (params, storeId) => ({
  type: Types.FETCH_STORE_FLOOR_MANAGEMENT,
  params,
  storeId,
});

export const createStoreFloorManagement = (values, storeId) => ({
  type: Types.POST_STORE_FLOOR_MANAGEMENT,
  values,
  storeId,
});

export const updateStoreFloorManagement = (values, id, storeId) => ({
  type: Types.PUT_STORE_FLOOR_MANAGEMENT,
  values,
  id,
  storeId,
});

export const deleteStoreFloorManagement = (id, storeId) => ({
  type: Types.DELETE_STORE_FLOOR_MANAGEMENT,
  id,
  storeId,
});

// FloorManagement Types
export const fetchStoreFloorManagementTypes = (params) => ({
  type: Types.FETCH_STORE_FLOOR_MANAGEMENT_TYPES,
  params,
});

export const createStoreFloorManagementTypes = (values) => ({
  type: Types.POST_STORE_FLOOR_MANAGEMENT_TYPES,
  values,
});

export const updateStoreFloorManagementTypes = (params) => ({
  type: Types.PUT_STORE_FLOOR_MANAGEMENT_TYPES,
  params,
});

export const deleteStoreFloorManagementTypes = (params) => ({
  type: Types.DELETE_STORE_FLOOR_MANAGEMENT_TYPES,
  params,
});

export default Types;
