import Types from 'Retail/state/actions/trainings';

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  created: false,
};

const trainings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_TRAININGS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.FETCH_TRAININGS_SUCCESS:
      let data = [];
      if (
        typeof action.data === 'object' &&
        Array.isArray(action.data) === false
      ) {
        data = Object.values(action.data);
      } else {
        data = action.data;
      }
      return {
        ...state,
        fetching: false,
        data: data,
      };
    case Types.FETCH_TRAININGS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.POST_TRAININGS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.POST_TRAININGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.POST_TRAININGS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.PUT_TRAININGS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.PUT_TRAININGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.PUT_TRAININGS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.PERFORMERS_TRAININGS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.PERFORMERS_TRAININGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
        // data: { ...state.data, performers: action.data },
      };
    case Types.PERFORMERS_TRAININGS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default trainings;
