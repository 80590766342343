const Types = {
  CREATE_MAPLE_NOTE: 'CREATE_MAPLE_NOTE',
  CREATE_MAPLE_NOTE_SUCCESS: 'CREATE_MAPLE_NOTE_SUCCESS',
  CREATE_MAPLE_NOTE_FAILED: 'CREATE_MAPLE_NOTE_FAILED',

  FETCH_MAPLE_NOTE: 'FETCH_MAPLE_NOTE',
  FETCH_MAPLE_NOTE_SUCCESS: 'FETCH_MAPLE_NOTE_SUCCESS',
  FETCH_MAPLE_NOTE_FAILED: 'FETCH_MAPLE_NOTE_FAILED',

  UPDATE_MAPLE_NOTE: 'UPDATE_MAPLE_NOTE',
  UPDATE_MAPLE_NOTE_SUCCESS: 'UPDATE_MAPLE_NOTE_SUCCESS',
  UPDATE_MAPLE_NOTE_FAILED: 'UPDATE_MAPLE_NOTE_FAILED',

  DELETE_MAPLE_NOTE: 'DELETE_MAPLE_NOTE',
  DELETE_MAPLE_NOTE_SUCCESS: 'DELETE_MAPLE_NOTE_SUCCESS',
  DELETE_MAPLE_NOTE_FAILED: 'DELETE_MAPLE_NOTE_FAILED',
};

export const createMapleNote = (params) => ({
  type: Types.CREATE_MAPLE_NOTE,
  params,
});

export const fetchMapleNote = (params) => ({
  type: Types.FETCH_MAPLE_NOTE,
  params,
});

export const updateMapleNote = (params) => ({
  type: Types.FETCH_MAPLE_NOTE,
  params,
});

export const deleteMapleNote = (params) => ({
  type: Types.DELETE_MAPLE_NOTE,
  params,
});

export default Types;
