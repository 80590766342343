import { LoadForUser } from "Core/bootstrapper";
import AuthKeys from "Core/state/actions/authentication";
import ActionKeys from "Core/state/actions/bootstrap";
import { put, select, take, takeLatest } from "redux-saga/effects";

function* BootstrapInit() {
  yield put({ type: ActionKeys.BOOTSTRAP_START });

  try {
    yield put({ type: AuthKeys.CHECK });
    yield take(AuthKeys.CHECK_COMPLETE);

    const state = yield select();
    console.log("[BootstrapInit] State: ", state);
    if (state.authentication.checkStatus === "SUCCESS") {
      const { user, company } = state;
      yield LoadForUser(user, company);
    }
  } catch (err) {
    console.error("[Bootstrap] Error: ", err);
  }

  yield put({ type: ActionKeys.BOOTSTRAP_COMPLETE });
}

function* BootstrapLogin() {
  console.log("BootstrapLogin");
  yield put({ type: ActionKeys.BOOTSTRAP_LOGIN_START });
  yield take(AuthKeys.LOGIN_COMPLETE);

  const state = yield select();
  if (state.authentication.loginStatus === "SUCCESS") {
    const { user, company } = state;
    yield LoadForUser(user, company);
    yield put({ type: ActionKeys.BOOTSTRAP_LOGIN_COMPLETE });
  }
}

export default function* BootstrapSaga() {
  yield takeLatest(ActionKeys.BOOTSTRAP, BootstrapInit);
  yield takeLatest(AuthKeys.LOGIN_SUCCESS, BootstrapLogin);
}
