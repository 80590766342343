import { types } from '../actions/Coaching';

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  created: false,
};

const coachingCompetenceItemsReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_COACHING_COMPETENCE_ITEMS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case types.FETCH_COACHING_COMPETENCE_ITEMS_SUCCESS:
      let data = [];
      if (
        typeof action.data === 'object' &&
        Array.isArray(action.data) === false
      ) {
        data = Object.values(action.data);
      } else {
        data = action.data;
      }
      return {
        ...state,
        fetching: false,
        data: data,
      };
    case types.FETCH_COACHING_COMPETENCE_ITEMS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default coachingCompetenceItemsReducers;
