import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import TasksHome from '@maple-tech/tasks-web-app/HomePage';
import Tickets from '@maple-tech/tasks-web-app/Tickets';

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

export const TASKS_BASE_ROUTE = {
  index: '/tasks',
  tickets: '/tasks/tickets',
};

const ROUTES = {
  index: 'tasks',
  tickets: 'tickets',
};

const subRoutesList = [
  {
    menuItem: true,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: '',
    element: <TasksHome />,
    title: 'Tasks',
    index: true,
  },
  {
    menuItem: true,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: 'tickets',
    title: 'My Tickets',
    element: <Tickets />,
  },
];

const TasksRoutes = {
  menuItem: true,
  requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
  path: ROUTES.index,
  section: true,
  title: 'Tasks',
  icon: <AssignmentTurnedInOutlinedIcon />,
  element: (
    <Page title="Tasks" contentClassName="!p-0">
      <Outlet />
    </Page>
  ),
  children: subRoutesList,
};

export default TasksRoutes;
