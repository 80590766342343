import { call, put, all, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';
import { RetailAPI } from 'Core/API';

export function* fetchFloorManagementSaga({ params, storeId }) {
  try {
    const data = yield call(() =>
      RetailAPI.get(
        `/store/${storeId}/floor-management?${new URLSearchParams(params)}`,
      ),
    );
    yield put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT_FAILED });
  }
}

export function* createFloorManagementSaga({ values, storeId }) {
  try {
    yield call(() =>
      RetailAPI.post(`/store/${storeId}/floor-management`, values),
    );
    yield all([
      put({ type: Types.POST_STORE_FLOOR_MANAGEMENT_SUCCESS }),
      put({
        type: Types.FETCH_STORE_FLOOR_MANAGEMENT,
        storeId,
        params: { currentDate: values.date },
      }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_FLOOR_MANAGEMENT_FAILED });
  }
}

export function* deleteFloorManagementSaga({ id, storeId }) {
  try {
    yield call(() =>
      RetailAPI.delete(`/store/${storeId}/floor-management/${id}`),
    );
    yield all([
      put({ type: Types.DELETE_STORE_FLOOR_MANAGEMENT_SUCCESS }),
      call(() => fetchFloorManagementSaga({ storeId, params: {} })),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_FLOOR_MANAGEMENT_FAILED });
  }
}

export function* updateFloorManagementSaga({ values, id, storeId }) {
  try {
    yield call(() =>
      RetailAPI.put(`/store/${storeId}/floor-management/${id}`, values),
    );
    yield all([
      put({ type: Types.PUT_STORE_FLOOR_MANAGEMENT_SUCCESS }),
      put({ type: Types.FETCH_STORE_FLOOR_MANAGEMENT }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_FLOOR_MANAGEMENT_FAILED });
  }
}
