const Types = {
  FETCH_VISITS: 'FETCH_VISITS',
  FETCH_VISITS_SUCCESS: 'FETCH_VISITS_SUCCESS',
  FETCH_VISITS_FAILED: 'FETCH_VISITS_FAILED',

  POST_VISITS: 'POST_VISITS',
  POST_VISITS_SUCCESS: 'POST_VISITS_SUCCESS',
  POST_VISITS_FAILED: 'POST_VISITS_FAILED',

  PUT_VISITS: 'PUT_VISITS',
  PUT_VISITS_SUCCESS: 'PUT_VISIT_SUCCESS',
  PUT_VISITS_FAILED: 'PUT_VISIT_FAILED',

  STAT_VISITS: 'STAT_VISITS',
  STAT_VISITS_SUCCESS: 'STAT_VISITS_SUCCESS',
  STAT_VISITS_FAILED: 'STAT_VISITS_FAILED',

  //Triggers

  BEGIN_VISITS: 'BEGIN_VISITS',
  BEGIN_VISITS_SUCCESS: 'BEGIN_VISITS_SUCCESS',
  BEGIN_VISITS_FAILED: 'BEGIN_VISITS_FAILED',

  STOP_VISITS: 'STOP_VISITS',
  STOP_VISITS_SUCCESS: 'STOP_VISITS_SUCCESS',
  STOP_VISITS_FAILED: 'STOP_VISITS_FAILED',

  COMPLETE_VISITS: 'COMPLETE_VISITS',
  COMPLETE_VISITS_SUCCESS: 'COMPLETE_VISITS_SUCCESS',
  COMPLETE_VISITS_FAILED: 'COMPLETE_VISITS_FAILED',

  RATE_VISITS: 'RATE_VISITS',
  RATE_VISITS_SUCCESS: 'RATE_VISITS_SUCCESS',
  RATE_VISITS_FAILED: 'RATE_VISITS_FAILED',

  OVERDUE_VISITS: 'OVERDUE_VISITS',
  OVERDUE_VISITS_SUCCESS: 'OVERDUE_VISITS_SUCCESS',
  OVERDUE_VISITS_FAILED: 'OVERDUE_VISITS_FAILED',

  // Notes

  CREATE_NOTE_VISITS: 'CREATE_NOTE_VISITS',
  CREATE_NOTE_VISITS_SUCCESS: 'CREATE_NOTE_VISITS_SUCCESS',
  CREATE_NOTE_VISITS_FAILED: 'CREATE_NOTE_VISITS_FAILED',

  FETCH_NOTE_VISITS: 'FETCH_NOTE_VISITS',
  FETCH_NOTE_VISITS_SUCCESS: 'FETCH_NOTE_VISITS_SUCCESS',
  FETCH_NOTE_VISITS_FAILED: 'FETCH_NOTE_VISITS_FAILED',

  DELETE_NOTE_VISITS: 'DELETE_NOTE_VISITS',
  DELETE_NOTE_VISITS_SUCCESS: 'DELETE_NOTE_VISITS_SUCCESS',
  DELETE_NOTE_VISITS_FAILED: 'DELETE_NOTE_VISITS_FAILED',
};

export const fetchVisits = (params) => ({
  type: Types.FETCH_VISITS,
  params,
});

export const createVisits = (params) => ({
  type: Types.POST_VISITS,
  params,
});

export const updateVisits = (params) => ({
  type: Types.PUT_VISITS,
  params,
});

export const fetchStateVisits = (params) => ({
  type: Types.STAT_VISITS,
  params,
});

// Triggers (BEGIN|STOP|COMPLETE|RATE|OVERDUE)
export const beginVisits = (params) => ({
  type: Types.BEGIN_VISITS,
  params,
});
export const completeVisits = (params) => ({
  type: Types.COMPLETE_VISITS,
  params,
});
export const stopVisits = (params) => ({
  type: Types.STOP_VISITS,
  params,
});
export const overdueVisits = (params) => ({
  type: Types.OVERDUE_VISITS,
  params,
});
export const rateVisits = (params) => ({
  type: Types.RATE_VISITS,
  params,
});

// Notes

export const createVisitNote = (params) => ({
  type: Types.CREATE_NOTE_VISITS,
  params,
});

export const fetchVisitNote = (params) => ({
  type: Types.FETCH_NOTE_VISITS,
  params,
});

export const deleteVisitNote = (params) => ({
  type: Types.DELETE_NOTE_VISITS,
  params,
});

export default Types;
