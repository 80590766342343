import { combineReducers } from 'redux';
import buildings, {
  INITIAL_STATE as fetchBuildingsInitialState,
} from 'Retail/state/reducers/retail/fetchBuildings';
import departments, {
  INITIAL_STATE as fetchDepartmentsInitialState,
} from 'Retail/state/reducers/retail/fetchDepartments';
import districts, {
  INITIAL_STATE as fetchDistrictsInitialState,
} from 'Retail/state/reducers/retail/fetchDistricts';
import employees, {
  INITIAL_STATE as fetchEmployeesInitialState,
} from 'Retail/state/reducers/retail/fetchEmployees';
import locations, {
  INITIAL_STATE as fetchLocationsInitialState,
} from 'Retail/state/reducers/retail/fetchLocations';
import permissions, {
  INITIAL_STATE as fetchPermissionsInitialState,
} from 'Retail/state/reducers/retail/fetchPermissions';
import fetchUserData, {
  INITIAL_STATE as fetchUserDataInitialState,
} from 'Retail/state/reducers/retail/fetchUserData';

const INITIALSTATE = {
  user: fetchUserDataInitialState,
  departments: fetchDepartmentsInitialState,
  employees: fetchEmployeesInitialState,
  locations: fetchLocationsInitialState,
  permissions: fetchPermissionsInitialState,
  buildings: fetchBuildingsInitialState,
  districts: fetchDistrictsInitialState,
};
const retailGlobal = {
  employees,
  departments,
  locations,
  permissions,
  buildings,
  user: fetchUserData,
  districts,
};

export default combineReducers(retailGlobal);
