const Types = {
  FETCH_TRAININGS: 'FETCH_TRAININGS',
  FETCH_TRAININGS_SUCCESS: 'FETCH_TRAININGS_SUCCESS',
  FETCH_TRAININGS_FAILED: 'FETCH_TRAININGS_FAILED',

  POST_TRAININGS: 'POST_TRAININGS',
  POST_TRAININGS_SUCCESS: 'POST_TRAININGS_SUCCESS',
  POST_TRAININGS_FAILED: 'POST_TRAININGS_FAILED',

  PUT_TRAININGS: 'PUT_TRAININGS',
  PUT_TRAININGS_SUCCESS: 'PUT_TRAININGS_SUCCESS',
  PUT_TRAININGS_FAILED: 'PUT_TRAININGS_FAILED',

  PERFORMERS_TRAININGS: 'PERFORMERS_TRAININGS',
  PERFORMERS_TRAININGS_SUCCESS: 'PERFORMERS_TRAININGS_SUCCESS',
  PERFORMERS_TRAININGS_FAILED: 'PERFORMERS_TRAININGS_FAILED',
};

export const fetchTrainings = (params) => ({
  type: Types.FETCH_TRAININGS,
  params,
});

export const createTrainings = (params) => ({
  type: Types.POST_TRAININGS,
  params,
});

export const updateTrainings = (params) => ({
  type: Types.PUT_TRAININGS,
  params,
});

export const getTrainingsPerformer = (params) => ({
  type: Types.PUT_TRAININGS,
  params,
});

export default Types;
