import { call, put, all, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';
import { RetailAPI } from 'Core/API';

export function* fetchTargetScheduleSaga({ params, storeId }) {
  try {
    const data = yield call(() =>
      RetailAPI.get(
        `/store/${storeId}/target-schedule?${new URLSearchParams(params)}`,
      ),
    );
    yield put({
      type: Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE_FAILED });
  }
}

export function* createTargetScheduleSaga({ values, storeId }) {
  try {
    yield call(() =>
      RetailAPI.post(`/store/${storeId}/target-schedule`, values),
    );
    yield all([
      put({ type: Types.POST_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS }),
      put({
        type: Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE,
        storeId,
        params: { currentDate: values.date },
      }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_TRACKING_TARGET_SCHEDULE_FAILED });
  }
}

export function* deleteTargetScheduleSaga({ id, storeId }) {
  try {
    yield call(() =>
      RetailAPI.delete(`/store/${storeId}/target-schedule/${id}`),
    );
    yield all([
      put({ type: Types.DELETE_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS }),
      call(() => fetchTargetScheduleSaga({ storeId, params: {} })),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_TRACKING_TARGET_SCHEDULE_FAILED });
  }
}

export function* updateTargetScheduleSaga({ values, id, storeId }) {
  try {
    yield call(() =>
      RetailAPI.put(`/store/${storeId}/target-schedule/${id}`, values),
    );
    yield all([
      put({ type: Types.PUT_STORE_TRACKING_TARGET_SCHEDULE_SUCCESS }),
      put({ type: Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_TRACKING_TARGET_SCHEDULE_FAILED });
  }
}
