import { nextState } from 'Core/state/reducers/utils';
import Types from '../actions/roles';

export const INITIAL_STATE = {
  data: [],

  loading: false,
  creating: false,
  created: false,
  error: null,
};

function Roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FETCH_ROLES:
      return nextState(state, { loading: true });
    case Types.FETCH_ROLES_SUCCESS:
      console.log('action.payload', action.payload);
      return nextState(state, {
        data: action.payload,
        error: null,
        loading: false,
      });
    case Types.FETCH_ROLES_FAILED:
      return nextState(state, {
        data: [],
        error: action.error,
        loading: false,
      });

    case Types.ADD_ROLES:
      return nextState(state, { loading: true });
    case Types.FETCH_ADD_SUCCESS:
      return nextState(state, {
        data: action.payload,
        error: null,
        loading: false,
      });
    case Types.ADD_ROLES_FAILED:
      return nextState(state, {
        data: [],
        error: action.error,
        loading: false,
      });

    case Types.DELETE_ROLES:
      return nextState(state, { loading: true });
    case Types.DELETE_ROLES_SUCCESS:
      return nextState(state, {
        data: action.payload,
        error: null,
        loading: false,
      });
    case Types.DELETE_ROLES_FAILED:
      return nextState(state, {
        data: [],
        error: action.error,
        loading: false,
      });

    default:
      return state;
  }
}

export default Roles;
