import Types from 'Retail/state/actions/visits';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  created: false,
};

const notes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_NOTE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.FETCH_NOTE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case Types.FETCH_NOTE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.CREATE_NOTE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.CREATE_NOTE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.CREATE_NOTE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.DELETE_NOTE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.DELETE_NOTE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.DELETE_NOTE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default notes;
