//Setup the i18next library
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  notification as AntDesignNotification,
  Button,
  ConfigProvider,
} from 'antd';
// import "antd/dist/antd.less";
import i18next from 'i18next';
import { useLayoutEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '../style/overrides.less';
//Setup the API library
import { CoreAPI } from './API';
import './FCM';
import './i18n';
import './index.css';
import './index.less';
//Grab the styles entry so webpack picks it up
//Get the router
import Routes from './routes';
//Setup the initial store
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import { getDeviceToken, onMessageListener } from './FCM';
import { themeGenerator } from './Themes/Default';
import ConfigureStore from './state/store';
import { SnackbarProvider } from 'notistack';
import { SettingsProvider } from '@maple-tech/web-components/settings';

import ThemeProvider from '@maple-tech/web-components/theme';

import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@mui/material';
import {MapleAIAsistantFab} from './components/MapleAI';

dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(localeData);

dayjs.updateLocale('en', {
  weekStart: 1,
});
// france paris
dayjs.tz.setDefault('Europe/Paris');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchInterval: 1000 * 60 * 15, // 15 minute
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // retryDelay: 1000 * 60 * 5, // 1 minute
      retryCount: 0,
      cacheTime: 0,
    },
  },
});

//This just adds a convienient console.debug() function in development env
if (import.meta.env.DEV) {
} else {
  console.debug = function () {};
}

//I know a lot of devs say that window globals are bad, but considering
//this application should only ever be on the maple domain, I'm not too
//worried about it
if (typeof window.Maple === 'undefined' || window.Maple === null) {
  window.Maple = {};
}

const { store, persistor } = ConfigureStore();
window.Maple.store = store;

window.Maple.util = {
  ...window.Maple.util,
  loginLocalPort: (port = 3000) => {
    const targetUrl = `http://localhost:${port}`;
    const authData = {
      'x-company': localStorage.getItem('x-company'),
      Authorization: localStorage.getItem('Authorization'),
      '@maple/userData': localStorage.getItem('@maple/userData'),
    };
    const encodedData = encodeURIComponent(JSON.stringify(authData));
    const url = `${targetUrl}#auth=${encodedData}`;
    const newTab = window.open(url, '_blank');
  },
};

//Add any "ready" hooks.
const visibilityControl = () => {
  //Just for Demir
  {
    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (
      typeof document.addEventListener === 'undefined' ||
      hidden === undefined
    ) {
      console.warn('Page visibility API not available');
    } else {
      document.addEventListener(
        visibilityChange,
        (e) => {
          if (document[hidden]) {
            document.title = "Don't forget about Maple";
            window.tabIsFocused = false;
          } else {
            document.title = 'Maple';
            window.tabIsFocused = true;
          }
        },
        false,
      );
    }
  }
};

const setStartUpTheme = () => {
  let theme = localStorage.getItem('theme');
  if (theme === 'dark') document.body.classList.add('dark');
};

const setUserLanguage = () => {
  const supportedLanguages = ['en', 'de', 'nl', 'es', 'fr', 'it', 'tr'];
  const lang = localStorage.getItem('i18nextLng');
  const findLang = supportedLanguages.find((lang) =>
    window.navigator.language.includes(lang),
  );
  if (lang) {
    dayjs.locale(lang);
    i18next.changeLanguage(lang);
  } else if (findLang) {
    localStorage.setItem('i18nextLng', findLang);
    dayjs.locale(findLang);
    i18next.changeLanguage(findLang);
  } else {
    localStorage.setItem('i18nextLng', 'en');
    dayjs.locale('en');
    i18next.changeLanguage('en');
  }
};

const App = () => {
  useLayoutEffect(() => {
    setUserLanguage();
    window.tabIsFocused = true;
    visibilityControl();
    setStartUpTheme();
    listenNotification();
    sendNewToken();
  }, []);
  const muiTheme = useMemo(() => {
    return themeGenerator('light');
  }, []);
  return (
    <ConfigProvider
      direction="ltr"
      theme={{
        token: {
          colorPrimary: '#6d0b19',
          colorSecondary: '#18544b',
        },
        components: {},
      }}
    >
      <Provider store={store}>
        <HelmetProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
              themeCorner: 'rounded', // 'rounded' | 'squared'
              themePaperVariant: 'outlined',
              themeSpacing: 'compact',
            }}
          >
            <ThemeProvider>
              <CssBaseline />
              <SnackbarProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                      <>
                        <Routes />
                      </>
                    </QueryClientProvider>
                  </PersistGate>
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </SettingsProvider>
        </HelmetProvider>
      </Provider>
    </ConfigProvider>
  );
};
const sendNewToken = async () => {
  const token = await getDeviceToken();
  if (
    localStorage.getItem('fcmToken') !== token &&
    localStorage.getItem('Authorization')?.length > 0
  ) {
    localStorage.setItem('fcmToken', token);
    return CoreAPI.post('/user/device-token', {
      application: 'retail',
      type: 'web',
      endPoint: 'https://alpha.workmaple.com',
      token: token,
      fingerPrint: localStorage.getItem('Authorization'),
      deviceName: navigator.userAgentData.platform + '-' + navigator.vendor,
    });
  }
};
const listenNotification = async () => {
  try {
    console.log('Notification.permission', Notification.permission);
    if (
      Notification.permission !== 'granted' ||
      Notification.permission === 'denied'
    ) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await sendNewToken();
      } else if (permission === 'denied') {
        // AntDesignNotification.warning({
        //   message: "Uyarı",
        //   description:
        //     "Lütfen ilerlemelerden haberdar olmak için bildirimlere izin verin.",
        //   btn: <Button onClick={listenNotification}>İzin Ver</Button>,
        // });
        return;
      }
    } else {
      await sendNewToken();
    }
    onMessageListener(async (payload) => {
      console.log('onMessage payload', payload);

      const antdOptions = {
        message: payload.notification.title,
        description: (
          <>
            <p>{payload.notification.body}</p>
            <br />
            {payload.notification.link && (
              <Button
                icon={<ArrowRightOutlined />}
                type="primary"
                href={payload.notification.link}
              >
                Detaylar
              </Button>
            )}
          </>
        ),
        duration: 10000,
      };
      AntDesignNotification.info(antdOptions);

      // ! Send local ios device notification
      // await fetch("http://localhost:7878", {
      //   method: "POST",
      //   body: JSON.stringify(payload),
      // });
    });
  } catch (error) {
    throw new Error(error);
  }
};

window.docsPathPrefix = (id) => `https://stg-documents-api.workmaple.com/${id}`;

export default App;
