import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import SagaMiddleware from "redux-saga";
import RetailReducers from "Retail/state/reducers";
import EmptyState from "./empty-state";
import LoggerMiddleware from "./logger-middleware";
import MonitorEnhancer from "./monitor-enhancer";
import Reducers, { Filters } from "./reducers";
import AuthSagas from "./sagas/authentication";
import BootstrapSagas from "./sagas/bootstrap";
import { composeWithDevTools } from "redux-devtools-extension";
import RetailSagas from "../../retail/state/sagas";

//import TaskSagas from '../../retail/state/sagas/task';

const PersistanceConfig = {
  key: "@maple/redux",
  keyPrefix: "",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: Filters,
};

function createReducer(asyncReducers = {}) {
  // return combineReducers({
  //   ...Reducers,
  //   ...RetailReducers,
  //   ...asyncReducers,
  // });
  return persistReducer(
    PersistanceConfig,
    combineReducers({
      ...Reducers,
      ...RetailReducers,
      ...asyncReducers,
    })
  );
}

export default function ConfigureStore(state) {
  if (!state) state = { ...EmptyState };

  const saga = SagaMiddleware();
  const middleware = [LoggerMiddleware, saga];

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middleware)
  );
  const enhancers = [middlewareEnhancer, MonitorEnhancer];
  // const composed = composeWithDevTools(...enhancers);

  const store = createStore(createReducer(), state, middlewareEnhancer);
  const persistor = persistStore(store, {});

  saga.run(AuthSagas);
  saga.run(RetailSagas.retail);
  saga.run(BootstrapSagas);
  //saga.run( TaskSagas );

  //Injection method
  store.asyncReducers = {};
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));

    console.debug("Injected async reducer: ", key);
  };

  store.asyncSagas = new Map();
  store.isSagaInjected = (key) => store.asyncSagas.has(key);
  store.injectSaga = (key, asyncSaga) => {
    if (store.isSagaInjected(key)) return;

    const task = saga.run(asyncSaga);
    store.asyncSagas.set(key, task);
    console.debug("Injected async saga: ", key);

    return task;
  };

  return { store, persistor };
}
