import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
};

const rejectTask = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.REJECT_TASK:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case Types.REJECT_TASK_SUCCESS:
      return {
        ...state,
        //templates: action.data,
        data: {},
        updated: state.updated + 1,
        error: false,
        fetching: false,
      };

    case Types.REJECT_TASK_FAILED:
      return {
        ...state,
        fetching: false,
        updated: state.updated + 1,
        error: true,
      };

    default:
      return state;
  }
};
rejectTask.Types = Types;

export default rejectTask;
