import { message } from 'antd';
import { RetailAPI } from 'Core/API';
import { isObject } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import Types from 'Retail/state/actions/mapleNotes';

export function* fetchMapleNotes({ params }) {
  if (typeof params === 'string')
    try {
      const data = yield call(() => RetailAPI.get('/maple-notes' + params));
      if (isObject(data)) {
        yield put({ type: Types.FETCH_MAPLE_NOTE_SUCCESS, data });
      } else yield put({ type: Types.FETCH_MAPLE_NOTE_FAILED });
    } catch (error) {
      yield put({ type: Types.FETCH_MAPLE_NOTE_FAILED });
    }
}

export function* createMapleNote({ params }) {
  try {
    const data = yield call(() => RetailAPI.post('/maple-note', params));
    const beforeState = yield select((state) => state.mapleNotes);
    yield all([
      put({ type: Types.CREATE_MAPLE_NOTE_SUCCESS }),
      call(() => fetchMapleNotes({ params: beforeState.params })),
    ]);
    message.success('Note Başarılı Bir Şekilde Oluşturuldu');
  } catch (error) {
    yield put({ type: Types.CREATE_MAPLE_NOTE_FAILED });
  }
}

export function* deleteMapleNote({ params }) {
  try {
    const data = yield call(() => RetailAPI.post('/maple-note/' + params.id));
    yield all([
      put({ type: Types.DELETE_MAPLE_NOTE_SUCCESS }),
      call(() => fetchMapleNotes({ params: '?moduleId=' + params.moduleId })),
    ]);
    message.success('Note Başarılı Bir Şekilde Silindi');
  } catch (error) {
    yield put({ type: Types.DELETE_MAPLE_NOTE_FAILED });
  }
}

export default function* mapleNoteSaga() {
  yield takeLatest(Types.FETCH_MAPLE_NOTE, fetchMapleNotes);
  yield takeLatest(Types.CREATE_MAPLE_NOTE, createMapleNote);
  yield takeLatest(Types.DELETE_MAPLE_NOTE, deleteMapleNote);
}
