import { takeEvery, all, fork } from 'redux-saga/effects';

import retailSaga from './retail';
import taskSaga from './task';
import trainingsSaga from './trainings';
import checklistSaga from './checklist';
import visitsSaga from './visits';
import storeSaga from './store';
import coachingSaga from './coaching';
import rolesSaga from './roles';
import permissionSaga from './permissions';
import mapleNoteSaga from './mapleNotes';

function* testRetailSaga() {
  console.debug('TEST RETAIL SAGA WORKS!');
}

function* rootSaga() {
  yield all([
    fork(checklistSaga),
    fork(retailSaga),
    fork(rolesSaga),
    fork(permissionSaga),
    fork(testRetailSaga),
    fork(taskSaga),
    fork(trainingsSaga),
    fork(visitsSaga),
    fork(storeSaga),
    fork(coachingSaga),
    fork(mapleNoteSaga),
  ]);
  // yield takeEvery('RETAIL_TEST', testRetailSaga);
}

/**
 * Key based sagas. Each one will be injected into the state and ran individually.
 * The keys help prevent duplicate sagas from running.
 */
const RetailSagas = {
  retail: rootSaga,
};

export default RetailSagas;
