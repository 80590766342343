import { useNavigate, useLocation } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

export default function SupportButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = () => {
    navigate('/support');
  };

  return (
    <IconButton
      color={pathname === '/support' ? 'primary' : 'default'}
      variant="circular"
      onClick={onClick}
    >
      <ContactSupportTwoToneIcon />
    </IconButton>
  );
}
