import Types from 'Retail/state/actions/checklist';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
};

const fetchCategories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.GET_CHECKLIST_CATEGORY:
      return { ...state, fetching: true };
    case Types.GET_CHECKLIST_CATEGORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        data: Array.isArray(action.data)
          ? action.data
          : action.data !== null || typeof action.data === 'object'
            ? Object.values(action.data).filter(
                (i) =>
                  i &&
                  !Array.isArray(i) &&
                  i.name &&
                  typeof i === 'object' &&
                  i !== null,
              )
            : [],
        updated: state.updated + 1,
      };
    case Types.GET_CHECKLIST_CATEGORY_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };

    default:
      return state;
  }
};
export default fetchCategories;
