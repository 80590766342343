import { createAuthService, getAuthKey, getXCompanyID } from '@maple-tech/auth';

//This comes from Webpack as a DefinePlugin object.
//It is defined here to cut down on duplicates in the bundle.
const KnownHosts = import.meta.env;

// axios.interceptors.request.use(registerRequestInterceptors);

let apiList = new Map([
  ['ModulesAPI', KnownHosts.VITE_Modules],
  ['CoreAPI', KnownHosts.VITE_Core],
  ["CoreAPIV2", KnownHosts.VITE_Core_V2],
  ['RetailAPI', KnownHosts.VITE_Retail],
  ['RetailAPIV2', KnownHosts.VITE_Retail_V2],
  // !Alpha tam olarak axios'a uygun değil, bu yüzden response'u dönüştürmek için bir interceptor kullanıyoruz
  ['RetailAPIForAlpha', KnownHosts.VITE_Retail],
  // ["AuthAPI", VITE_AUTH],
  ['EducationAPI', KnownHosts.VITE_Education],
  ['DocumentsAPI', KnownHosts.VITE_Documents],
  ['DocumentsAPIV2', KnownHosts.VITE_Documents_V2],
  ['AutomatorsAPI', KnownHosts.VITE_Automator],
  ['AuthAPI', KnownHosts.VITE_AUTH],
  ['TaskAPI', KnownHosts.VITE_Task],
]);
// Create Service
export const authService = createAuthService({
  storage: {
    get: (key) => {
      // console.log("get Key", key);
      return localStorage.getItem(key);
    },
    getAll: () => {
      return Object.entries(localStorage);
    },
    del: (key) => {
      let deletedValue = localStorage.getItem(key);
      localStorage.removeItem(key);
      return deletedValue || '';
    },
    set: (key, value) => {
      // console.log("set Key", key, value);
      localStorage.setItem(key, value);
    },
    clearAll: () => {
      localStorage.clear();
    },
  },
  onError: (error, ...args) => {
    console.log('onError', error, args);
  },
  onLoginSuccess: () => {
    console.log('onLoginSuccess');
    if (window.location.pathname === '/login') {
      console.log('onLoginSuccess', window.location.pathname);
    }
  },
  onAuthLogout: () => {
    console.log('onAuthLogout');
    if (window.location.pathname != '/login') {
      window.location.href = '/login';
    }
  },
  onSessionExpired: () => {
    console.log('onSessionExpired');
    if (window.location.pathname != '/login') {
      window.location.href = '/login';
    }
  },
  userStorageKey: '@maple/userData',
  apisRegistry: apiList,
});
