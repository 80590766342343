import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import FloorManagementHome from '@maple-tech/floor-management-web-app/HomePage';

import ApartmentIcon from '@mui/icons-material/Apartment';

export const FloorManagement_BASE_ROUTE = {
  index: '/floor-management',
};

const ROUTES = {
  index: 'floor-management',
};

const subRoutesList = [
  {
    menuItem: false,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: '',
    element: (
      <Page title={'Floor Management'} contentStyle={{ padding: 0 }}>
        <FloorManagementHome />
      </Page>
    ),
    index: true,
  },
];

const FloorManagementPagesRoutes = {
  menuItem: true,
  requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
  path: ROUTES.index,
  section: true,
  title: 'Floor Management',
  icon: <ApartmentIcon />,
  element: <Outlet />,
  children: subRoutesList,
};

export default FloorManagementPagesRoutes;
