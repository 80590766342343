import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { CoreAPI } from "../../API";
import ActionKeys from "../actions/authentication";
import { userLoggedIn, userCheckLogin } from "@maple-tech/auth";

export function* fetchCurrent() {
  const token = yield select((state) => state.authentication.token);
  console.log("token", token);
  if (!token) {
    yield put({ type: ActionKeys.CHECK_FAILED, error: null });
    yield put({ type: ActionKeys.CHECK_COMPLETE });
  } else {
    console.debug("Token received from state", token);
    yield put({ type: ActionKeys.CHECK_LOADING });

    try {
      const { data, status } = yield call(() =>
        CoreAPI.get("/session", {
          authorization: token,
        })
      );
      if (status != 200) {
        yield put({ type: ActionKeys.CHECK_FAILED, error: data });
        return;
      }
      yield put({ type: ActionKeys.CHECK_SUCCESS, data });
    } catch (error) {
      yield put({ type: ActionKeys.CHECK_FAILED, error });
    } finally {
      yield put({ type: ActionKeys.CHECK_COMPLETE });
    }
  }
}

export function* fetchLogin(action) {
  yield put({ type: ActionKeys.LOGIN_LOADING });
  try {
    if (action?.samlLogin) {
      console.debug("Token received from state", action.token);
      yield put({ type: ActionKeys.CHECK_LOADING });

      try {
        const { data, status } = yield call(() =>
          CoreAPI.get("/session", {
            authorization: action.token,
          })
        );
        if (status != 200) {
          yield put({ type: ActionKeys.LOGIN_FAILED, error: data });
          return;
        }
        const customSamlData = {
          user: data,
          authentication: action.token,
        };
        yield put({ type: ActionKeys.CHECK_COMPLETE });
        yield put({ type: ActionKeys.LOGIN_SUCCESS, data: customSamlData });
      } catch (error) {
        console.error(error);
        yield put({ type: ActionKeys.CHECK_FAILED, error });
      }
    } else {
      const response = yield call(
        () => userLoggedIn(action.contact, action.password)
        // CoreAPI.post(
        //   "/login",
        //   {
        //     contact: action.contact,
        //     password: action.password,
        //     platform: "WEB",
        //   },
        //   {
        //     useAuthorization: false,
        //   }
        // )
      );
      if (typeof response !== "object") {
        yield put({ type: ActionKeys.LOGIN_FAILED, error: response });
        return;
      }
      yield put({ type: ActionKeys.LOGIN_SUCCESS, data: response });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: ActionKeys.LOGIN_FAILED, error });
  } finally {
    yield put({ type: ActionKeys.LOGIN_COMPLETE });
  }
}

export function* fetchForgot(action) {
  yield put({ type: ActionKeys.FORGOT_LOADING });

  try {
    //The data is garbage in this one
    yield call(() =>
      CoreAPI.post(
        "/forgot-password",
        {
          contact: action.contact,
        },
        {
          useAuthorization: false,
        }
      )
    );
    yield put({ type: ActionKeys.FORGOT_SUCCESS });
  } catch (error) {
    yield put({ type: ActionKeys.FORGOT_FAILED, error });
  } finally {
    yield put({ type: ActionKeys.FORGOT_COMPLETE });
  }
}

export function* fetchLogout() {
  yield put({ type: ActionKeys.LOGOUT_LOADING });

  try {
    const data = yield call(() =>
      CoreAPI.put("/logout", {
        // invalidateAll: false, //Note: This will log EVERY device out if true
      })
    );
    yield put({ type: ActionKeys.LOGOUT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ActionKeys.LOGOUT_FAILED, error });
  } finally {
    yield put({ type: ActionKeys.LOGOUT_COMPLETE });
  }
}

export default function* authorizationSaga() {
  yield all([
    takeLatest(ActionKeys.CHECK, fetchCurrent),
    takeLatest(ActionKeys.LOGIN, fetchLogin),
    takeLatest(ActionKeys.FORGOT, fetchForgot),
    takeLatest(ActionKeys.LOGOUT, fetchLogout),
  ]);
}
