import Types from 'Retail/state/actions/retail';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
  params: new URLSearchParams(),
};

const fetchBuildings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_BUILDINGS:
      return {
        ...state,
        fetching: true,
        params: action.params || new URLSearchParams(),
      };
    case Types.FETCH_BUILDINGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.data,
        updated: state.updated + 1,
      };
    case Types.FETCH_BUILDINGS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
fetchBuildings.Types = Types;

export default fetchBuildings;
