import { RetailAPI } from 'Core/API';
import { call, put, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/checklist';

export function* fetchChecklist({ params }) {
  try {
    const oldParams = yield select((state) => state.checklist.lists.params);
    const {
      Data: data,
      Size: size,
      Page: page,
      TotalCount: totalCount,
    } = yield call(() =>
      RetailAPI.get(
        `checklist-completed-v2${
          '?' + (params ? params.toString() : oldParams.toString())
        }`,
      ),
    );
    console.log({ size, page, totalCount, data });
    yield put({
      type: Types.FETCH_CHECKLIST_SUCCESS,
      data,
      size,
      page,
      totalCount,
      params,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_CHECKLIST_FAILED });
  }
}

export function* putChecklist({ params }) {
  try {
    yield put({ type: Types.FETCH_CHECKLIST });
  } catch (error) {
    yield put({ type: Types.PUT_CHECKLIST_FAILED });
  }
}
