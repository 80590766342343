import React from 'react';

import { useUserController } from '@maple-tech/auth';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';

export default ({ expand = false }) => {
  const userState = useUserController((f) => f.user);
  const company = userState?.companies?.[0];

  if (!Boolean(company.picture))
    return (
      <Box sx={{ backgroundColor: '#374a67' }}>
        <CardHeader
          avatar={
            <Avatar
              src={company?.picture}
              alt={company?.name}
              sx={{ backgroundColor: 'primary.dark' }}
              variant="circular"
            >
              {company?.name.charAt(0)}
            </Avatar>
          }
          titleTypographyProps={{ variant: 'body1', color: 'white' }}
          title={company?.name}
        />
      </Box>
    );
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{ backgroundColor: '#374a67', padding: 1 }}
    >
      <Avatar
        src={company?.picture}
        alt={company?.name}
        sx={{ backgroundColor: 'primary.dark', height: 72, width: 72 }}
        variant="circular"
      >
        {company?.name.charAt(0)}
      </Avatar>
      <Typography variant="body1" color="white">
        {company?.name}
      </Typography>
    </Stack>
  );
};
