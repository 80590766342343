import "./serviceWorkerSetup"
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
// analytics
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_apiKey,
  authDomain: import.meta.env.VITE_authDomain,
  databaseURL: import.meta.env.VITE_databaseURL,
  projectId: import.meta.env.VITE_projectId,
  storageBucket: import.meta.env.VITE_storageBucket,
  messagingSenderId: import.meta.env.VITE_messagingSenderId,
  appId: import.meta.env.VITE_appId,
  measurementId: import.meta.env.VITE_measurementId,
};
const isSuportedFCM = await isSupported();
console.log("FCM is supported", isSuportedFCM);
const firebaseApp = await initializeApp(firebaseConfig);
firebaseApp.automaticDataCollectionEnabled = true;

const isDev = import.meta.env.DEV;

export const firebaseAnalytics = getAnalytics(firebaseApp);

export const firebaseMessaging = /* !isDev &&  */ isSuportedFCM
  ? getMessaging(firebaseApp)
  : null;

// // Initialize Firebase
// export const firebaseAnalytics = getAnalytics(firebaseApp);
export const getDeviceToken = () => {
  if (!isSuportedFCM || firebaseMessaging === null) return null;
  return getToken(firebaseMessaging, {
    vapidKey: import.meta.env.VITE_VAPID_KEY,
  })
    .then((currentToken) => {
      console.log("currentToken", currentToken);
      if (currentToken) {
        console.log("currentToken", currentToken);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
await getDeviceToken();
export const onMessageListener = (onPayload) =>
  onMessage(firebaseMessaging, onPayload);
