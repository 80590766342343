import { takeLatest } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';
import {
  createFloorManagementSaga,
  deleteFloorManagementSaga,
  fetchFloorManagementSaga,
  updateFloorManagementSaga,
} from './floorManagement';
import {
  createFloorManagementTypesSaga,
  deleteFloorManagementTypesSaga,
  fetchFloorManagementTypesSaga,
  updateFloorManagementTypesSaga,
} from './floorManagementTypes';
import {
  createStorePosts,
  deleteStorePosts,
  fetchStorePosts,
  updateStorePosts,
} from './posts';
import {
  createShiftSaga,
  deleteShiftSaga,
  fetchBuildingShiftSaga,
  fetchShiftSaga,
  updateShiftSaga,
} from './shift';
import {
  createShiftTypesSaga,
  deleteShiftTypesSaga,
  fetchShiftTypesSaga,
  updateShiftTypesSaga,
} from './shiftType';
import {
  createTargetScheduleSaga,
  deleteTargetScheduleSaga,
  fetchTargetScheduleSaga,
  updateTargetScheduleSaga,
} from './targetSchedule';

export default function* storeSaga() {
  // ! shifts
  yield takeLatest(Types.FETCH_SINGLE_STORE_SHIFTS, fetchBuildingShiftSaga);
  yield takeLatest(Types.FETCH_STORE_SHIFTS, fetchShiftSaga);
  yield takeLatest(Types.POST_STORE_SHIFT, createShiftSaga);
  yield takeLatest(Types.DELETE_STORE_SHIFT, deleteShiftSaga);
  yield takeLatest(Types.UPDATE_STORE_SHIFT, updateShiftSaga);
  // ! shift type
  yield takeLatest(Types.FETCH_STORE_SHIFT_TYPES, fetchShiftTypesSaga);
  yield takeLatest(Types.POST_STORE_SHIFT_TYPE, createShiftTypesSaga);
  yield takeLatest(Types.DELETE_STORE_SHIFT_TYPE, deleteShiftTypesSaga);
  yield takeLatest(Types.UPDATE_STORE_SHIFT_TYPE, updateShiftTypesSaga);
  // ! store posts
  yield takeLatest(Types.FETCH_STORE_POSTS, fetchStorePosts);
  yield takeLatest(Types.POST_STORE_POSTS, createStorePosts);
  yield takeLatest(Types.PUT_STORE_POSTS, updateStorePosts);
  yield takeLatest(Types.DELETE_STORE_POSTS, deleteStorePosts);

  // ! Tracking Target Schedule
  yield takeLatest(
    Types.FETCH_STORE_TRACKING_TARGET_SCHEDULE,
    fetchTargetScheduleSaga,
  );
  yield takeLatest(
    Types.POST_STORE_TRACKING_TARGET_SCHEDULE,
    createTargetScheduleSaga,
  );
  yield takeLatest(
    Types.PUT_STORE_TRACKING_TARGET_SCHEDULE,
    updateTargetScheduleSaga,
  );
  yield takeLatest(
    Types.DELETE_STORE_TRACKING_TARGET_SCHEDULE,
    deleteTargetScheduleSaga,
  );

  // ! store floor management
  yield takeLatest(
    Types.FETCH_STORE_FLOOR_MANAGEMENT,
    fetchFloorManagementSaga,
  );
  yield takeLatest(
    Types.POST_STORE_FLOOR_MANAGEMENT,
    createFloorManagementSaga,
  );
  yield takeLatest(Types.PUT_STORE_FLOOR_MANAGEMENT, updateFloorManagementSaga);
  yield takeLatest(
    Types.DELETE_STORE_FLOOR_MANAGEMENT,
    deleteFloorManagementSaga,
  );

  // ! store floor management
  yield takeLatest(
    Types.FETCH_STORE_FLOOR_MANAGEMENT_TYPES,
    fetchFloorManagementTypesSaga,
  );
  yield takeLatest(
    Types.POST_STORE_FLOOR_MANAGEMENT_TYPES,
    createFloorManagementTypesSaga,
  );
  yield takeLatest(
    Types.PUT_STORE_FLOOR_MANAGEMENT_TYPES,
    updateFloorManagementTypesSaga,
  );
  yield takeLatest(
    Types.DELETE_STORE_FLOOR_MANAGEMENT_TYPES,
    deleteFloorManagementTypesSaga,
  );
}
