import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: {},
  error: false,
  updated: 0,
  success: false,
  requesting: false,
};

const prepareNewTemplate = (state = INITIAL_STATE, action) => {
  let savingData = localStorage.getItem('newTemplate');
  if (savingData)
    action.data = state.data = Object.assign(
      {},
      JSON.parse(savingData) || state.data,
      action.data,
    );

  action.data = action.data || state.data;
  switch (action.type) {
    // Task Templates
    case Types.PREPARE_NEW_TEMPLATE:
      return {
        ...state,
        data: action.data,
        success: false,
        error: false,
        requesting: true,
      };

    case Types.PREPARE_NEW_TEMPLATE_SUCCESS:
      localStorage.setItem(
        'newTemplate',
        JSON.stringify(Object.assign({}, state.data, action.data)),
      );
      return {
        ...state,
        data: action.data,
        updated: state.updated + 1,
        requesting: false,
        success: true,
      };
    case Types.PREPARE_NEW_TEMPLATE_FAILED:
      return {
        ...state,
        error: false,
        updated: state.updated + 1,
        requesting: false,
        success: false,
      };
    default:
      return state;
  }
};
prepareNewTemplate.Types = Types;

export default prepareNewTemplate;
