import { RetailAPI } from 'Core/API';
import { all, call, put } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';

export function* fetchShiftSaga({ params }) {
  try {
    const data = yield call(() => RetailAPI.post(`/shift/search`, params));
    yield put({ type: Types.FETCH_STORE_SHIFTS_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_SHIFTS_FAILED });
  }
}
export function* fetchBuildingShiftSaga({ params, storeId }) {
  try {
    const data = yield call(() =>
      RetailAPI.get(`/store/${storeId}/shift?${new URLSearchParams(params)}`),
    );
    yield put({ type: Types.FETCH_SINGLE_STORE_SHIFTS_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: Types.FETCH_SINGLE_STORE_SHIFTS_FAILED });
  }
}

export function* createShiftSaga({ values, storeId }) {
  try {
    yield call(() => RetailAPI.post(`/shift`, values));
    yield all([
      put({ type: Types.POST_STORE_SHIFT_SUCCESS }),
      put({
        type: Types.FETCH_STORE_SHIFT,
        storeId,
        params: { currentDate: values.date },
      }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_SHIFT_FAILED });
  }
}

export function* deleteShiftSaga({ id, storeId }) {
  try {
    yield call(() => RetailAPI.delete(`/shift/${id}`));
    yield all([
      put({ type: Types.DELETE_STORE_SHIFT_SUCCESS }),
      call(() => fetchShiftSaga({ storeId, params: {} })),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_SHIFT_FAILED });
  }
}

export function* updateShiftSaga({ values, id, storeId }) {
  try {
    yield call(() => RetailAPI.put(`/shift/${id}`, values));
    yield all([
      put({ type: Types.PUT_STORE_SHIFT_SUCCESS }),
      put({ type: Types.FETCH_STORE_SHIFT }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_SHIFT_FAILED });
  }
}
