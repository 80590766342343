const Types = {
  FETCH_TEMPLATES: 'FETCH_TEMPLATES',
  FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAILED: 'FETCH_TEMPLATES_FAILED',

  PREPARE_NEW_TEMPLATE: 'PREPARE_NEW_TEMPLATE',
  PREPARE_NEW_TEMPLATE_SUCCESS: 'PREPARE_NEW_TEMPLATE_SUCCESS',
  PREPARE_NEW_TEMPLATE_FAILED: 'PREPARE_NEW_TEMPLATE_FAILED',
  PREPARE_NEW_TEMPLATE_SAVE: 'PREPARE_NEW_TEMPLATE_FAILED',

  ADD_NEW_TEMPLATE: 'ADD_NEW_TEMPLATE',
  ADD_NEW_TEMPLATE_SUCCESS: 'ADD_NEW_TEMPLATE_SUCCESS',
  ADD_NEW_TEMPLATE_FAILED: 'ADD_NEW_TEMPLATE_FAILED',

  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILED: 'FETCH_CATEGORIES_FAILED',

  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAILED: 'FETCH_TAGS_FAILED',

  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILED: 'CREATE_CATEGORY_FAILED',

  PREPARE_NEW_ISSUE_TASK: 'PREPARE_NEW_ISSUE_TASK',
  PREPARE_NEW_ISSUE_TASK_SUCCESS: 'PREPARE_NEW_ISSUE_TASK_SUCCESS',

  PREPARE_NEW_CUSTOM_TASK: 'PREPARE_NEW_CUSTOM_TASK',
  PREPARE_NEW_CUSTOM_TASK_SUCCESS: 'PREPARE_NEW_CUSTOM_TASK_SUCCESS',
  PREPARE_NEW_CUSTOM_TASK_FAILED: 'PREPARE_NEW_CUSTOM_TASK_FAILED',

  ADD_NEW_TASK: 'ADD_NEW_TASK',
  ADD_NEW_TASK_SUCCESS: 'ADD_NEW_TASK_SUCCESS',
  ADD_NEW_TASK_TASK: 'ADD_NEW_TASK_TASK',

  FETCH_TASK: 'FETCH_TASK',
  FETCH_TASK_SUCCESS: 'FETCH_TASK_SUCCESS',
  FETCH_TASK_FAILED: 'FETCH_TASK_FAILED',

  PUT_TASK_DOCUMENTS: 'PUT_TASK_DOCUMENTS',
  PUT_TASK_DOCUMENTS_SUCCESS: 'PUT_TASK_DOCUMENTS_SUCCESS',
  PUT_TASK_DOCUMENTS_FAILED: 'PUT_TASK_DOCUMENTS_FAILED',

  APPROVE_TASK: 'APPROVE_TASK',
  APPROVE_TASK_SUCCESS: 'APPROVE_TASK_SUCCESS',
  APPROVE_TASK_FAILED: 'APPROVE_TASK_FAILED',

  CLAIM_TASK: 'CLAIM_TASK',
  CLAIM_TASK_SUCCESS: 'CLAIM_TASK_SUCCESS',
  CLAIM_TASK_FAILED: 'CLAIM_TASK_FAILED',

  BEGIN_TASK: 'BEGIN_TASK',
  BEGIN_TASK_SUCCESS: 'BEGIN_TASK_SUCCESS',
  BEGIN_TASK_FAILED: 'BEGIN_TASK_FAILED',

  REJECT_TASK: 'REJECT_TASK',
  REJECT_TASK_SUCCESS: 'REJECT_TASK_SUCCESS',
  REJECT_TASK_FAILED: 'REJECT_TASK_FAILED',

  DENY_TASK: 'DENY_TASK',
  DENY_TASK_SUCCESS: 'DENY_TASK_SUCCESS',
  DENY_TASK_FAILED: 'DENY_TASK_FAILED',

  COMPLETE_TASK: 'COMPLETE_TASK',
  COMPLETE_TASK_SUCCESS: 'COMPLETE_TASK_SUCCESS',
  COMPLETE_TASK_FAILED: 'COMPLETE_TASK_FAILED',

  DELETE_TASK: 'DELETE_TASK',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILED: 'DELETE_TASK_FAILED',
};

export const fetchTemplates = (params) => ({
  type: Types.FETCH_TEMPLATES,
  params,
});

export const fetchCategories = () => ({
  type: Types.FETCH_CATEGORIES,
});

export const fetchTags = () => ({
  type: Types.FETCH_TAGS,
});

export const fetchTask = ({ params = {}, callback = console.log }) => ({
  type: Types.FETCH_TASK,
  params: params,
  callback,
});

export const prepareNewTemplate = (data) => ({
  type: Types.PREPARE_NEW_TEMPLATE,
  data,
});

export const addNewTemplate = () => ({
  type: Types.ADD_NEW_TEMPLATE,
});

export const createCategory = (object) => ({
  type: Types.CREATE_CATEGORY,
  object,
});

export const prepareNewIssueTask = (object) => ({
  type: Types.PREPARE_NEW_ISSUE_TASK,
  object,
});

export const prepareNewCustomTask = (object) => ({
  type: Types.PREPARE_NEW_CUSTOM_TASK,
  object,
});

export const addNewTask = (object) => ({
  type: Types.ADD_NEW_TASK,
  object,
});

export const putTaskDocuments = (params, callback = console.log) => ({
  type: Types.PUT_TASK_DOCUMENTS,
  params,
  callback,
});
export const deleteTask = (id, callback = console.log) => ({
  type: Types.DELETE_TASK,
  id,
  callback,
});

export const beginTask = (params) => ({
  type: Types.BEGIN_TASK,
  params,
});
export const completeTask = (params) => ({
  type: Types.COMPLETE_TASK,
  params,
});
export const approveTask = (params) => ({
  type: Types.APPROVE_TASK,
  params,
});
export const rejectTask = (params) => ({
  type: Types.REJECT_TASK,
  params,
});
export const denyTask = (params) => ({
  type: Types.DENY_TASK,
  params,
});
export const claimTask = (params) => ({
  type: Types.CLAIM_TASK,
  params,
});

export default Types;
