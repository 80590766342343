import { combineReducers } from 'redux';
import category from './categories';
import lists from './list';
import fetchCehcklistTemplate from './template';

const checklistCore = {
  lists,
  categories: category,
  templates: fetchCehcklistTemplate,
};

export default combineReducers(checklistCore);
