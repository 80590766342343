import Types from 'Retail/state/actions/task';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  loaded: false,
  updated: 0,
  page: 1,
  size: 0,
  totalCount: 0,
};

const fetchTask = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_TASK:
      return { ...state, fetching: true, loaded: false };
    case Types.FETCH_TASK_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        loaded: true,
        data: action.data.Data,
        size: action.data.Size,
        page: action.data.Page,
        totalCount: action.data.TotalCount,
        updated: state.updated + 1,
      };
    case Types.FETCH_TASK_FAILED:
      return {
        ...state,
        fetching: false,
        loaded: false,
        error: true,
      };

    default:
      return state;
  }
};
fetchTask.Types = Types;

export default fetchTask;
