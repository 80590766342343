const Types = {
  INIT_DEFAULT_PERMISSIONS: 'INIT_DEFAULT_PERMISSIONS',

  FETCH_DEFAULT_PERMISSIONS: 'FETCH_DEFAULT_PERMISSIONS',
  FETCH_DEFAULT_PERMISSIONS_SUCCESS: 'FETCH_DEFAULT_PERMISSIONS_SUCCESS',
  FETCH_DEFAULT_PERMISSIONS_FAILED: 'FETCH_DEFAULT_PERMISSIONS_FAILED',
};

/**
 * @param {schoolId} query
 */
export const fetchPermissions = (action) => ({
  type: Types.FETCH_DEFAULT_PERMISSIONS,
  action,
});

export default Types;
