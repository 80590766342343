import Types from 'Retail/state/actions/retail';

export const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false,
  updated: 0,
};

const fetchPermissions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Task Templates
    case Types.FETCH_PERMISSIONS:
      return { ...state, fetching: true };
    case Types.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.data,
        updated: state.updated + 1,
      };
    case Types.FETCH_PERMISSIONS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
fetchPermissions.Types = Types;

export default fetchPermissions;
