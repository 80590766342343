import { nextState, optDate } from "./utils";
import createFilter from "redux-persist-transform-filter";

import AuthActions from "../actions/authentication";

export const DefaultState = {
  memberships: [],
  currentID: null,
  current: null,
};

export const Filter = createFilter("company", ["memberships", "currentID"]);

export default function Company(state = DefaultState, action) {
  switch (action.type) {
    case "persist/REHYDRATE":
      if (action.payload && action.payload.company) {
        const cmp = action.payload.company;
        return nextState(state, {
          ...cmp,
          current: cmp.currentID
            ? cmp.memberships.find((c) => c.id === cmp.currentID)
            : null,
        });
      }
      return state;

    case AuthActions.CHECK_SUCCESS:
    case AuthActions.LOGIN_SUCCESS:
      if (action.data) {
        const comps =
          action?.companies ||
          action.data.companies ||
          action.data.user.companies;
        const tmpState = {
          memberships: comps.map((cmp) => ({
            id: cmp.id,
            name: cmp.name,
            picture: cmp.picture,
            info: {
              employees: cmp.employees,
              industries: cmp.industries,
              contact: cmp.contactInfo,
              timeUpdated: optDate(cmp.timeUpdated),
            },
            modules: cmp.modules,
            timeJoined: optDate(cmp.timeJoined),
            timeUpdated: optDate(cmp.timeMembershipUpdated),
            role: cmp.role,
            departments: cmp.departments || [],
            positions: cmp.positions,
            isExternal: !!cmp.isExternal,
            externalCompany: cmp.externalCompany,
          })),
        };

        if (tmpState.memberships.length > 0) {
          tmpState.current = tmpState.memberships[0];
          tmpState.currentID = tmpState.current.id;
        }

        return nextState(state, tmpState);
      }
      return state;

    case AuthActions.LOGOUT_COMPLETE:
      return nextState(state, DefaultState);
    default:
      return state;
  }
}
