import { isObject } from 'lodash';
import Types from 'Retail/state/actions/visits';

export const INITIAL_STATE = {
  data: [],
  stats: {
    all: 0,
    inProgress: 0,
    completed: 0,
    overdue: 0,
  },
  page: 1,
  size: 5,
  fetching: false,
  error: false,
  created: false,
};

const visits = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.FETCH_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: isObject(action.data) ? Object.values(action.data) : action.data,
        page: action?.page || 1,
        size: action?.size || 5,
        stats: action.stat || state.stats,
      };
    case Types.FETCH_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.POST_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.POST_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.POST_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.PUT_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.PUT_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.PUT_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.STAT_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.STAT_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
        // data: { ...state.data, performers: action.data },
      };
    case Types.STAT_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default visits;
