import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import ChangeLanguage from './change-language';
import { Box, Divider } from '@mui/material';
import {
  getAuthKey,
  getInstance,
  getXCompanyID,
  useUserController,
} from '@maple-tech/auth';
export default ({ expand }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  let timeout = null;

  const handleClick = (event) => {
    if (timeout) clearTimeout(timeout);
    if (anchorEl === null) setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    timeout = setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };
  return (
    <>
      <UserCard
        mini={expand}
        handleClick={handleClick}
        handleClose={handleClose}
      />
      <UserMenu
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
      />
    </>
  );
};

export const UserMenu = ({ anchorEl, handleClick, handleClose }) => {
  const open = Boolean(anchorEl);
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      MenuListProps={{
        onMouseEnter: handleClick,
        onMouseLeave: handleClose,
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ zIndex: 600 }}
    >
      <MenuItem>
        <ChangeLanguage />
      </MenuItem>
      {/* <UserMenuItem
        label="navigation.user-settings"
        to="/settings"
        icon={<SettingsIcon />}
      /> 
      <UserMenuItem
      -- Select Theme --
      />
      */}
      <UserMenuItem
        label="navigation.support"
        to="/support"
        icon={<ContactSupportIcon />}
      />
      <UserMenuItem
        label="navigation.logout"
        to="/logout"
        icon={<LogoutIcon />}
      />
    </Menu>
  );
};

const documentPath = (documentId ) => {
try{
   // ! source objesi farklı tiplerde dönebiliyor. MM hesapta string dönerken, Workmaple hesapta obje dönüyor.
   if(typeof documentId !== "string" || documentId === null || documentId.length < 4) return null;
  if(documentId.search("http") === 0) return documentId;
  // data.source : {id:string} | string
  const params = `forceReadBytes=true&Authorization=${getAuthKey()}&X-Company=${getXCompanyID()}`;
  return `${getInstance('DocumentsAPIV2')?.defaults.baseURL}/documents/${documentId}?${params}`;
}catch(e){
  console.error(e);
  return null;
}
};

const UserCard = ({ mini, handleClick, handleClose }) => {
  const userState = useUserController((f) => f.user);
  const avatarSrc = documentPath(userState?.picture);
  const name = userState?.name;
  const role = userState?.companies?.[0]?.role?.name;
  if (mini) {
    return (
      <>
        <IconButton
          sx={{ zIndex: 700 }}
          onMouseEnter={handleClick}
          onMouseLeave={handleClose}
        >
          <SettingsIcon fontSize="small" />
        </IconButton>
        <Divider />
      </>
    );
  }
  return (
    <Box
      sx={{ zIndex: 700, cursor: 'pointer' }}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <CardHeader
        sx={{ pointerEvents: 'none' }}
        avatar={<Avatar src={avatarSrc} alt={name} />}
        title={name}
        subheader={role}
        action={
          <IconButton sx={{ pointerEvents: 'none' }}>
            <SettingsIcon fontSize="small" />
          </IconButton>
        }
      />
      <Divider />
    </Box>
  );
};

const UserMenuItem = ({ label, to, icon }) => {
  const { t } = useTranslation('core');
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(to);
  }, []);
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={t(label)} />
    </MenuItem>
  );
};
