import { nextState } from './utils';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import Actions from '../actions/application';
import AuthActions from '../actions/authentication'
import BootstrapActions from '../actions/bootstrap';

export const DefaultState = {
    rootPath: '/',
    navigationMenu: [],
    bootstrapStatus: 'INIT',
    bootstrapLoginStatus: 'INIT',
};

//These keys are NOT saved to storage
export const Filter = createBlacklistFilter('application', [
    'navigationMenu',
    'bootstrapStatus',
    'bootstrapLoginStatus',
]);

export default function Application(state = DefaultState, action) {
    switch(action.type) {
      case "PURGE":
        return DefaultState;
        case AuthActions.LOGOUT_COMPLETE:
            return nextState(state, DefaultState);

        case BootstrapActions.BOOTSTRAP_START:
            return nextState(state, { bootstrapStatus: 'LOADING' });
        case BootstrapActions.BOOTSTRAP_COMPLETE:
            return nextState(state, { bootstrapStatus: 'COMPLETE' });

        case BootstrapActions.BOOTSTRAP_LOGIN_START:
            return nextState(state, { bootstrapLoginStatus: 'LOADING' });
        case BootstrapActions.BOOTSTRAP_LOGIN_COMPLETE:
            return nextState(state, { bootstrapLoginStatus: 'COMPLETE' });

        case Actions.ADD_NAVIGATION:
            return nextState(state, {
                navigationMenu: [
                    ...(state.navigationMenu || []),
                    ...action.config,
                ],
                rootPath: action.rootPath || state.rootPath,
            });

        case Actions.SET_BADGE:
            const nextNav = state.navigationMenu.map(nav => {
                if(nav.routes) {
                    return {
                        ...nav,
                        routes: nav.routes.map(sub => {
                            if(sub.path == action.path) {
                                return {
                                    ...sub,
                                    badge: action.count,
                                };
                            }
                            return sub;
                        }),
                    };
                }

                if(nav.path == action.path) {
                    return {
                        ...nav,
                        badge: action.count,
                    };
                }
                return nav;
            });
            
            return nextState(state, { navigationMenu: nextNav });
            
        default:
            return state;
    }
}