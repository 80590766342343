import Types from 'Retail/state/actions/visits';

export const INITIAL_STATE = {
  fetching: false,
  error: false,
  created: false,
};

const visitTriggers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.BEGIN_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.BEGIN_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case Types.BEGIN_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.COMPLETE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.COMPLETE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.COMPLETE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.STOP_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.STOP_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
      };
    case Types.STOP_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.RATE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.RATE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
        // data: { ...state.data, performers: action.data },
      };
    case Types.RATE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case Types.OVERDUE_VISITS:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.OVERDUE_VISITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        created: true,
        // data: { ...state.data, performers: action.data },
      };
    case Types.OVERDUE_VISITS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default visitTriggers;
