import { getAuthService } from '@maple-tech/auth';
const KnownHosts = import.meta.env;

const authService = getAuthService();
/**
 * Instantiated singleton of a APIFetch object, preset to the core-api
 * host.
 */
export const CoreAPI = authService.getInstance('CoreAPI');

/**
 * Instantiated singleton of a APIFetch object, preset to the core-api
 * host.
 */
export const DocumentsAPI = authService.getInstance('DocumentsAPIV2');
/**
 * Instantiated singleton of a APIFetch object, preset to the modules-api
 */
export const ModulesAPI = authService.getInstance('ModulesAPI');
/**
 * Instantiated singleton of a APIFetch object, preset to the retail-api
 * host.
 */
// RetailAPIForAlpha yakın bir zamanda kaldırılarak RetailAPI'ya dönüştürülecek
export const RetailAPI = authService.getInstance('RetailAPIForAlpha');
/**
 * Instantiated singleton of a APIFetch object, preset to the education-api
 * host.
 */

export const TaskAPI = authService.getInstance('TaskAPI');

export const EducationAPI = authService.getInstance('EducationAPI');

/**
 * Instantiated singleton of a APIFetch object, preset to the education-api
 * host.
 */
export const AutomatorAPI = authService.getInstance('AutomatorsAPI');

/* The line `export const AuthAPI = authService.getInstance("AuthAPI");` is creating an instance of the
APIFetch object for the "AuthAPI" endpoint using the `authService.getInstance()` method. This
instance is then exported as `AuthAPI` for use in other parts of the code. */
export const AuthAPI = authService.getInstance('AuthAPI');

// redefine paramsSerializer to use encodeURIComponent
[DocumentsAPI, RetailAPI].forEach(
  (api) =>
    (api.defaults.paramsSerializer = (params) => {
      let result = '';
      for (const [key, value] of Object.entries(params)) {
        result += `${key}=${encodeURIComponent(value)}&`;
      }
      return result.slice(0, result.length - 1);
    }),
);
// Use interceptors to return just the response.data object
[DocumentsAPI, RetailAPI].forEach((api) => {
  api.interceptors.response.use(
    (response) => {
      if (response.headers['content-type'] === 'application/octet-stream') {
        return new Blob([response.data]);
      }
      // Sadece response.data'yı döndürün
      return response.data;
    },
    (error) => {
      // Hata durumunda hatayı ileterek devam edin
      return Promise.reject(error);
    },
  );
});

export const COMPANIES = {
  SEPHORA: 'AAAAAAAAAAQAAIAAAAAAAAAA74',
  SEPHORA_FRANCE: '44D432CBXII65N5WAAGTVZWXVA',
  MEDIAMARKT: 'HCQPFWHIAII6ZMNLAAGTVZWXVA',
  WORKMAPLE: 'AAAAAAAAABAAAQAAAAAAAAAA74',
  LOREM: 'GKS52OWDGAI63OHXAAGTVZWXVA',
  DEMO: 'R5I54VECC4I6ZA4JAAGTVZWXVA',
  VINEYARD_VALLEY: 'OQWJ7XQKKBH3JNXQG66DWP2HAA',
  CENTRAL_RETAIL: 'N3HNZ2WVDFFAFNG3DZHUZ4QWDU',
};
export const COUNTRIES = {
  MM_HOLLAND: '38',
  MM_BELGIUM: '3a',
  MM_TURKIYE: '10',
  SEPHORA: {
    TURKIYE: '1',
    ITALY: '4',
  },
};

export const getAuthorizationHeaders = () => ({
  Authorization: authService.getAuthKey(),
  'X-Company': authService.getXCompanyID(),
});

//Inject into the global namespace
if (!window.Maple) window.Maple = {};
window.Maple.API = {
  Core: CoreAPI,
  Documents: DocumentsAPI,
  Task: TaskAPI,
  Retail: RetailAPI,
  Modules: ModulesAPI,
  Education: EducationAPI,
  Automator: AutomatorAPI,
  Auth: AuthAPI,
  MODE: KnownHosts.VITE_MODE,
};
