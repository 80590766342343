export const types = {
  FETCH_COACHING_COMPETENCE_ITEMS: 'FETCH_COACHING_COMPETENCE_ITEMS',
  FETCH_COACHING_COMPETENCE_ITEMS_SUCCESS:
    'FETCH_COACHING_COMPETENCE_ITEMS_SUCCESS',
  FETCH_COACHING_COMPETENCE_ITEMS_FAILED:
    'FETCH_COACHING_COMPETENCE_ITEMS_FAILED',
  FETCH_COACHING: 'FETCH_COACHING',
  FETCH_COACHING_SUCCESS: 'FETCH_COACHING_SUCCESS',
  FETCH_COACHING_FAILED: 'FETCH_COACHING_FAILED',
};

export const fetchCoaching = (params = new URLSearchParams()) => ({
  type: types.FETCH_COACHING,
  params,
});

export const fetchCoachingCompetenceItems = (
  params = new URLSearchParams(),
) => ({
  type: types.FETCH_COACHING_COMPETENCE_ITEMS,
  params,
});
