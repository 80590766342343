/**
 * Declares the reducers and default state that this product will use.
 * Will be asynchronously injected into the redux state.
 */
import { combineReducers } from 'redux';
import checklist from './checklist';
import coaching from './Coaching';
import coachingCompetenceItemsReducers from './CoachingCompetenceItems';
import mapleNotes from './mapleNotes';
import permissions from './permissions';
import retail from './retail';
import roles from './roles';
import store from './store';
import task from './task';
import trainings from './trainings';
import visits from './Visit';

export default {
  retail,
  roles,
  permissions,
  task,
  trainings,
  checklist,
  visits,
  store,
  coaching,
  coachingCompetenceList: coachingCompetenceItemsReducers,
  mapleNotes,
};
