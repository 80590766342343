import { call, put, takeLatest, all } from 'redux-saga/effects';
import Types from 'Retail/state/actions/visits';
import { RetailAPI } from 'Core/API';
import { message } from 'antd';

/*
		r.HandleFunc("/visit-notes", c.GET).Methods("GET")
		r.HandleFunc("/visit-note", c.POST).Methods("POST")
		r.HandleFunc("/visit-note/{id}", c.GETBYID).Methods("GET")
		r.HandleFunc("/visit-note/{id}", c.PUT).Methods("PUT")
		r.HandleFunc("/visit-note/{id}", c.DELETE).Methods("DELETE")
*/

export function* fetchNotes({ params }) {
  console.log('params', params);
  if (typeof params === 'string')
    try {
      const data = yield call(() => RetailAPI.get('/visit-notes' + params));
      if (Array.isArray(data)) {
        yield put({ type: Types.FETCH_NOTE_VISITS_SUCCESS, data });
      } else message.error('Notlar çekilirken bir hata oluştu');
    } catch (error) {
      yield put({ type: Types.FETCH_NOTE_VISITS_FAILED });
    }
}

export function* createNote({ params }) {
  try {
    const data = yield call(() => RetailAPI.post('/visit-note', params));
    yield all([
      put({ type: Types.CREATE_NOTE_VISITS_SUCCESS }),
      call(() => fetchNotes({ params: '?visitId=' + params.visitId })),
    ]);
    message.success('Note Başarılı Bir Şekilde Oluşturuldu');
  } catch (error) {
    yield put({ type: Types.COMPLETE_VISITS_FAILED });
  }
}

export function* deleteNote({ params }) {
  try {
    const data = yield call(() => RetailAPI.post('/visit-note/' + params.id));
    yield all([
      put({ type: Types.DELETE_NOTE_VISITS_SUCCESS }),
      call(() => fetchNotes({ params: '?visitId=' + params.visitId })),
    ]);
    message.success('Note Başarılı Bir Şekilde Silindi');
  } catch (error) {
    yield put({ type: Types.DELETE_NOTE_VISITS_FAILED });
  }
}
