import { nextState, optDate } from "./utils";

import AuthActions from "../actions/authentication";

export const DefaultState = {
  valid: false,
  id: null,
  name: null,
  picture: null,
  active: false,
  timeCreated: null,
  timeUpdated: null,
  settings: {
    language: "en",
    twoFactorMode: 0,
    timeUpdated: null,
  },
};

export default function User(state = DefaultState, action) {
  console.log("Action User:", action);
  switch (action.type) {
    case "persist/REHYDRATE":
      console.log("action.payload", action.payload);
      if (action.payload && action.payload.user) {
        const usr = action.payload.user;
        return nextState(state, {
          ...usr,
          valid: !!(usr && typeof usr.id === "string" && usr.id.length > 0),
        });
      }
      return state;

    case AuthActions.CHECK_SUCCESS:
    case AuthActions.LOGIN_SUCCESS:
      if (action.data) {
        const usr = action.data.user || action.data;
        return nextState(state, {
          valid: !!(usr && typeof usr.id === "string" && usr.id.length > 0),
          id: usr.id,
          name: usr.name,
          picture: usr.picture,
          active: usr.active || true, //Note: assuming true if bad payload
          timeCreated: new Date(usr.timeCreated),
          timeUpdated: optDate(usr.timeUpdated),
          settings: {
            language: usr.settings?.language || "tr-TR",
            twoFactorMode: usr.settings?.twoFactorMode || 0,
            timeUpdated: optDate(usr.settings?.timeUpdated),
          },
        });
      }
      return state;

    case AuthActions.LOGOUT_COMPLETE:
      return nextState(state, DefaultState);
    default:
      return state;
  }
}
