//Setup the i18next library
// import "antd/dist/antd.less";
// alert("index.jsx");
import './API/register_maple_configs';
import './FCM';
import dayjs from 'dayjs';

import { createRoot } from 'react-dom/client';
import '../style/overrides.less';
import App from './App';
import './i18n';
import './index.css';
import './index.less';
// Extend default dayjs with plugins

if (!window.dayjs) {
  window.dayjs = dayjs;
}

if (!global || !global.dayjs) {
  global.dayjs = dayjs;
  globalThis.dayjs = dayjs;
}

if (import.meta.env.DEV === false) {
  console.clear();
  const nop = () => {};
  console.log = nop;
  console.debug = nop;
  console.info = nop;
  console.count = nop;
  console.countReset = nop;
  console.time = nop;
  console.timeEnd = nop;
  console.table = nop;
  console.group = nop;
  console.groupEnd = nop;
  console.groupCollapsed = nop;
}
const root = createRoot(document.getElementById('root'))
root.render(<App />);
