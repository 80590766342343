import { call, put, takeLatest, all } from 'redux-saga/effects';
import Types from 'Retail/state/actions/visits';
import { RetailAPI } from 'Core/API';
import {
  beginVisits,
  stopVisits,
  rateVisits,
  overdueVisits,
  completeVisits,
} from './Triggers';
import { fetchNotes, createNote, deleteNote } from './Notes';

export function* fetchVisits({ params }) {
  try {
    let { data, stats, page, size } = yield call(() =>
      RetailAPI.get('/visits' + (params ? `?${params}` : '')),
    );
    yield put({
      type: Types.FETCH_VISITS_SUCCESS,
      data,
      stat: stats,
      page,
      size,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_VISITS_FAILED });
  }
}

export function* createVisits({ params }) {
  try {
    yield call(() => RetailAPI.post('/visit', params));
    yield all([
      put({ type: Types.POST_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_VISITS_FAILED });
  }
}

export function* putVisits({ params }) {
  try {
    const data = yield call(() => RetailAPI.put('/visit/' + params.id, params));
    yield all([
      put({ type: Types.PUT_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_VISITS_FAILED });
  }
}

export function* fetchStats({ params }) {
  try {
    const data = yield call(() => RetailAPI.get('/visits/stat'));
    yield put({
      type: Types.STAT_VISITS_SUCCESS,
      data,
    });
  } catch (error) {
    console.log('error', error);
    yield put({ type: Types.STAT_VISITS_FAILED });
  }
}
export default function* visitsSaga() {
  yield takeLatest(Types.FETCH_VISITS, fetchVisits);
  yield takeLatest(Types.POST_VISITS, createVisits);
  yield takeLatest(Types.PUT_VISITS, putVisits);
  yield takeLatest(Types.STAT_VISITS, fetchStats);
  yield takeLatest(Types.BEGIN_VISITS, beginVisits);
  yield takeLatest(Types.STOP_VISITS, stopVisits);
  yield takeLatest(Types.RATE_VISITS, rateVisits);
  yield takeLatest(Types.OVERDUE_VISITS, overdueVisits);
  yield takeLatest(Types.COMPLETE_VISITS, completeVisits);
  yield takeLatest(Types.FETCH_NOTE_VISITS, fetchNotes);
  yield takeLatest(Types.CREATE_NOTE_VISITS, createNote);
  yield takeLatest(Types.DELETE_NOTE_VISITS, deleteNote);
}
