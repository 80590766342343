import { RetailAPI } from 'Core/API';
import { all, call, put } from 'redux-saga/effects';
import Types from 'Retail/state/actions/store';

export function* fetchShiftTypesSaga({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.get(`/shift-type/search?${new URLSearchParams(params)}`),
    );
    yield put({
      type: Types.FETCH_STORE_SHIFT_TYPES_SUCCESS,
      data: Array.from(data),
    });
  } catch (error) {
    yield put({ type: Types.FETCH_STORE_SHIFT_FAILED });
  }
}

export function* createShiftTypesSaga({ values }) {
  try {
    yield call(() => RetailAPI.post(`/shift-type`, values));
    yield all([
      put({ type: Types.POST_STORE_SHIFT_TYPES_SUCCESS }),
      put({ type: Types.FETCH_STORE_SHIFT_TYPES }),
    ]);
  } catch (error) {
    yield put({ type: Types.POST_STORE_SHIFT_TYPES_FAILED });
  }
}

export function* deleteShiftTypesSaga({ id }) {
  try {
    yield call(() => RetailAPI.delete(`/shift-type/${id}`));
    yield all([
      put({ type: Types.DELETE_STORE_SHIFT_TYPES_SUCCESS }),
      call(() => fetchShiftTypesSaga()),
    ]);
  } catch (error) {
    yield put({ type: Types.DELETE_STORE_SHIFT_TYPES_FAILED });
  }
}

export function* updateShiftTypesSaga({ values, id }) {
  try {
    yield call(() => RetailAPI.put(`/shift-type/${id}`, values));
    yield all([
      put({ type: Types.PUT_STORE_SHIFT_TYPES_SUCCESS }),
      put({ type: Types.FETCH_STORE_SHIFT_TYPES }),
    ]);
  } catch (error) {
    yield put({ type: Types.PUT_STORE_SHIFT_TYPES_FAILED });
  }
}
