import Types from 'Retail/state/actions/retail';

export const INITIAL_STATE = {
  fetching: false,
  error: false,
  active: true,
  buildingId: '',
  buildings: [],
  companyId: '',
  contactInfo: { email: 'dddd@someplace.com', phone: '434' },
  departments: null,
  districts: null,
  employeeId: '',
  id: '',
  isManager: true,
  locations: [],
  name: 'John Doe',
  picture: null,
  positions: [],
  timeCreated: '2019-08-30T15:53:14Z',
  timeDeleted: null,
  timeUpdated: '2019-08-30T16:41:51Z',
  userId: '',
};
function fetchUserData(state = INITIAL_STATE, action) {
  console.log(state, action);
  switch (action.type) {
    case Types.FETCH_USER:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case Types.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...action.data,
        fetching: false,
        error: false,
      };
    case Types.FETCH_USER_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
}
fetchUserData.Types = Types;

export default fetchUserData;
