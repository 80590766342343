import createFilter from "redux-persist-transform-filter";
import Actions from "../actions/authentication";
import BootstrapActions from "../actions/bootstrap";
import { nextState } from "./utils";

export const DefaultState = {
  valid: false,
  token: null,

  checkStatus: "INIT",
  checkError: null,

  loginStatus: "INIT",
  loginError: null,

  logoutStatus: "INIT",
  logoutError: null,

  forgotStatus: "INIT",
  forgotError: null,
};

//Only allow the following properties to get stored (cached) to the user's system
export const Filter = createFilter("authentication", ["token"]);

export default function Authentication(state = DefaultState, action) {
  switch (action.type) {
    case "persist/REHYDRATE":
      console.log("Rehydrating authentication", action);
      if (action.payload && action.payload.authentication) {
        const auth = action.payload.authentication;
        return nextState(state, {
          ...auth,
          valid: !!(auth.token?.length > 0),
        });
      }
      return state;

    //Session check fetching
    case Actions.CHECK_LOADING:
      return nextState(state, { checkStatus: "LOADING" });
    case Actions.CHECK_FAILED:
      return nextState(state, {
        valid: false,
        token: null,
        checkStatus: "FAILED",
        checkError: action.error,
      });
    case Actions.CHECK_SUCCESS:
      return nextState(state, {
        checkStatus: "SUCCESS",
        checkError: null,
      });

    //Login fetching
    case Actions.LOGIN_LOADING:
      return nextState(state, { loginStatus: "LOADING" });
    case Actions.LOGIN_FAILED:
      return nextState(state, {
        valid: false,
        token: null,
        loginStatus: "FAILED",
        loginError: action.error,
      });
    case Actions.LOGIN_SUCCESS:
      console.log(action);
      return nextState(state, {
        valid: false, //Wait for bootloader to confirm
        token: action.data.authentication,
        loginStatus: "SUCCESS",
        loginError: null,
      });

    //Forgot fetching
    case Actions.FORGOT_LOADING:
      return nextState(state, { forgotStatus: "LOADING" });
    case Actions.FORGOT_FAILED:
      return nextState(state, {
        forgotStatus: "FAILED",
        forgotError: action.error,
      });
    case Actions.FORGOT_SUCCESS:
      return nextState(state, { forgotStatus: "SUCCESS", forgotError: null });

    //Logout fetching
    case Actions.LOGOUT_LOADING:
      return nextState(state, { logoutStatus: "LOADING" });
    case Actions.LOGOUT_FAILED:
      return nextState(state, {
        valid: false,
        token: null,
        logoutStatus: "FAILED",
        logoutError: action.error,
      });
    case Actions.LOGOUT_SUCCESS:
      return nextState(state, {
        valid: false,
        token: null,
        logoutStatus: "SUCCESS",
        logoutError: null,
      });

    //Bootstrapping
    case BootstrapActions.BOOTSTRAP_COMPLETE:
    case BootstrapActions.BOOTSTRAP_LOGIN_COMPLETE:
      return nextState(state, {
        valid:
          state.token &&
          typeof state.token === "string" &&
          state.token.length > 0,
      });

    default:
      return state;
  }
}
