import { call, put, takeLatest } from 'redux-saga/effects';
import Types from 'Retail/state/actions/trainings';
import { RetailAPI } from 'Core/API';

export function* fetchTrainings({ params }) {
  try {
    let data = yield call(() => RetailAPI.get('/training'));
    console.log('data', data);
    yield put({ type: Types.FETCH_TRAININGS_SUCCESS, data });
  } catch (error) {
    yield put({ type: Types.FETCH_TRAININGS_FAILED });
  }
}
export function* createTrainings({ params }) {
  try {
    const data = yield call(() => RetailAPI.post('/training', params));
    yield put({ type: Types.FETCH_TRAININGS });
  } catch (error) {
    yield put({ type: Types.FETCH_TRAININGS_FAILED });
  }
}
export function* putTrainings({ params }) {
  // const formData = new FormData();
  // formData.append("id", params.id);
  // formData.append("performer", params.performer);
  try {
    const data = yield call(() => RetailAPI.put('/training/' + params.id));
    yield put({ type: Types.FETCH_TRAININGS });
  } catch (error) {
    yield put({ type: Types.PUT_TRAININGS_FAILED });
  }
}
export function* getPerformers({ params }) {
  // const formData = new FormData();
  // formData.append("id", params.id);
  // formData.append("performer", params.performer);
  try {
    const data = yield call(() =>
      RetailAPI.get('/training/' + params.id + '/performers'),
    );
    yield put({
      type: Types.PERFORMERS_TRAININGS_SUCCESS,
      data,
      id: params.id,
    });
  } catch (error) {
    yield put({ type: Types.PERFORMERS_TRAININGS_FAILED });
  }
}
export default function* trainingsSaga() {
  yield takeLatest(Types.FETCH_TRAININGS, fetchTrainings);
  yield takeLatest(Types.POST_TRAININGS, createTrainings);
  yield takeLatest(Types.PUT_TRAININGS, putTrainings);
  yield takeLatest(Types.PERFORMERS_TRAININGS, getPerformers);
}
