import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { COMPANIES } from 'Core/API';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
// Orders
const OrderOrdersPage = lazy(() => import('./orders'));
const OrderOrderDetailsPage = lazy(() => import('./orders/OrderDetails'));
const OrderProductsPage = lazy(() => import('./products'));
const OrderProductDetailsPage = lazy(() => import('./products/ProductDetails'));
const OrderModifyProductPage = lazy(() => import('./products/Modify'));
const OrderCategoriesPage = lazy(() => import('./categories'));
const OrderCategoryDetailsPage = lazy(
  () => import('./categories/CategoryDetails'),
);
const OrderModifyCategoryPage = lazy(() => import('./categories/Modify'));

const ROUTES = {
  root: 'order',
  overview: 'overview',

  orderDetail: ':id',

  products: 'products',
  productDetail: 'product/:id',
  newProduct: 'product/new',
  editProduct: 'product/:id/edit',

  categories: 'categories',
  categoryDetail: 'category/:id',
  newCategory: 'category/new',
  editCategory: 'category/:id/edit',
};

const ORDER_REQUIRED_ROLES = {
  root: ['admin', COMPANIES.WORKMAPLE],
  overview: ['admin', COMPANIES.WORKMAPLE],

  orderDetail: ['admin', COMPANIES.WORKMAPLE],

  products: ['admin', COMPANIES.WORKMAPLE],
  productDetail: ['admin', COMPANIES.WORKMAPLE],
  newProduct: ['admin', COMPANIES.WORKMAPLE],
  editProduct: ['admin', COMPANIES.WORKMAPLE],

  categories: ['admin', COMPANIES.WORKMAPLE],
  categoryDetail: ['admin', COMPANIES.WORKMAPLE],
  newCategory: ['admin', COMPANIES.WORKMAPLE],
  editCategory: ['admin', COMPANIES.WORKMAPLE],
};

const OrderRoutes = {
  menuItem: true,
  requiredRoles: ORDER_REQUIRED_ROLES.root,
  path: ROUTES.root,
  rootPath: '/' + ROUTES.root,
  icon: <AssignmentTurnedInOutlinedIcon />,
  title: 'retail:navigation.orders.section-title',
  element: <Outlet />,
  section: true,
  children: [
    {
      menuItem: true,
      requiredRoles: ORDER_REQUIRED_ROLES.overview,
      path: ROUTES.overview,
      title: 'retail:navigation.orders.overview',
      element: <OrderOrdersPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.orderDetail,
      path: ROUTES.orderDetail,
      title: null,
      element: <OrderOrderDetailsPage />,
    },
    {
      menuItem: true,
      requiredRoles: ORDER_REQUIRED_ROLES.products,
      path: ROUTES.products,
      title: 'retail:navigation.orders.products',
      element: <OrderProductsPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.productDetail,
      path: ROUTES.productDetail,
      title: 'retail:navigation.orders.product-detail',
      element: <OrderProductDetailsPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.newProduct,
      path: ROUTES.newProduct,
      title: null,
      element: <OrderModifyProductPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.editProduct,
      path: ROUTES.editProduct,
      title: null,
      element: <OrderModifyProductPage />,
    },
    {
      menuItem: true,
      requiredRoles: ORDER_REQUIRED_ROLES.categories,
      path: ROUTES.categories,
      title: 'retail:navigation.orders.categories',
      element: <OrderCategoriesPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.categoryDetail,
      path: ROUTES.categoryDetail,
      title: null,
      element: <OrderCategoryDetailsPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.newCategory,
      path: ROUTES.newCategory,
      title: null,
      element: <OrderModifyCategoryPage />,
    },
    {
      menuItem: false,
      requiredRoles: ORDER_REQUIRED_ROLES.editCategory,
      path: ROUTES.editCategory,
      title: null,
      element: <OrderModifyCategoryPage />,
    },
  ],
};

export default OrderRoutes;

// full paths
export const ORDER_ROUTES = {
  root: '/order',
  overview: `/order/${ROUTES.overview}`,

  orderDetail: `/order/${ROUTES.orderDetail}`,

  products: `/order/${ROUTES.products}`,
  productDetail: `/order/${ROUTES.productDetail}`,
  newProduct: `/order/${ROUTES.newProduct}`,
  editProduct: `/order/${ROUTES.editProduct}`,

  categories: `/order/${ROUTES.categories}`,
  categoryDetail: `/order/${ROUTES.categoryDetail}`,
  newCategory: `/order/${ROUTES.newCategory}`,
  editCategory: `/order/${ROUTES.editCategory}`,
};
