import { call, put, all, select } from 'redux-saga/effects';
import Types from 'Retail/state/actions/checklist';
import { RetailAPI } from 'Core/API';

export function* fetchChecklistCategories({ params }) {
  try {
    const data = yield call(() => RetailAPI.get('checklist-category'));
    yield put({ type: Types.GET_CHECKLIST_CATEGORY_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: Types.GET_CHECKLIST_CATEGORY_FAILED });
  }
}
export function* createChecklistCategories({ values }) {
  // const state = yield select();
  // values.id = state.checklist.categories.data.length + 1;
  try {
    yield call(() => RetailAPI.post('checklist-category', values));
    yield all([
      put({ type: Types.CREATE_CHECKLIST_CATEGORY_SUCCESS }),
      put({ type: Types.GET_CHECKLIST_CATEGORY }),
    ]);
  } catch (error) {
    yield put({ type: Types.CREATE_CHECKLIST_CATEGORY_FAILED });
  }
}
