import React, { useEffect } from 'react';

import Logo from '../logo';
import Company from './company';
import User from './user';
import Navigation from './navigation';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

import './page-menu.less';
import { Layout } from 'antd';

export default ({ currentPath }) => {
  const [collapsed, setCollapse] = React.useState(false);
  const [isPressed] = useKeyboardJs('alt + space');

  useEffect(() => {
    if (isPressed) {
      setCollapse((c) => !c);
    }
  }, [isPressed]);
  return (
    <Layout.Sider
      width={250}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(collapse) => setCollapse(collapse)}
    >
      <div id="page-menu-logo">
        <Logo full={!collapsed} />
      </div>

      <Company expand={collapsed} />
      <div id="page-menu-wrapper">
        <User expand={collapsed} />
        <Navigation currentPath={currentPath} collapsed={collapsed} />
        {/* <span id="page-menu-version">{`Version ${VERSION}`}</span> */}
      </div>
    </Layout.Sider>
  );
};
