const keys = {
  CHECK: "authentication/CHECK",
  CHECK_LOADING: "authentication/CHECK/LOADING",
  CHECK_SUCCESS: "authentication/CHECK/SUCCESS",
  CHECK_FAILED: "authentication/CHECK/FAILED",
  CHECK_COMPLETE: "authentication/CHECK/COMPLETE",

  LOGIN: "authentication/LOGIN",
  LOGIN_LOADING: "authentication/LOGIN/LOADING",
  LOGIN_SUCCESS: "authentication/LOGIN/SUCCESS",
  LOGIN_FAILED: "authentication/LOGIN/FAILED",
  LOGIN_COMPLETE: "authentication/LOGIN/COMPLETE",

  FORGOT: "authentication/FORGOT",
  FORGOT_LOADING: "authentication/FORGOT/LOADING",
  FORGOT_SUCCESS: "authentication/FORGOT/SUCCESS",
  FORGOT_FAILED: "authentication/FORGOT/FAILED",
  FORGOT_COMPLETE: "authentication/FORGOT/COMPLETE",

  REGISTER: "authentication/REGISTER",
  REGISTER_LOADING: "authentication/REGISTER/LOADING",
  REGISTER_SUCCESS: "authentication/REGISTER/SUCCESS",
  REGISTER_FAILED: "authentication/REGISTER/FAILED",
  REGISTER_COMPLETE: "authentication/REGISTER/COMPLETE",

  LOGOUT: "authentication/LOGOUT",
  LOGOUT_LOADING: "authentication/LOGOUT/LOADING",
  LOGOUT_SUCCESS: "authentication/LOGOUT/SUCCESS",
  LOGOUT_FAILED: "authentication/LOGOUT/FAILED",
  LOGOUT_COMPLETE: "authentication/LOGOUT/COMPLETE",
};

export const Check = () => ({ type: keys.CHECK });

export const Login = (username, password, samlLogin, token) => ({
  type: keys.LOGIN,
  contact: username,
  password: password,
  token,
  samlLogin,
});

export const Forgot = (username) => ({
  type: keys.FORGOT,
  contact: username,
});

export const Register = () => ({ type: keys.REGISTER });

export const Logout = () => ({ type: keys.LOGOUT });

export default keys;
