import { call, put, takeLatest, all } from 'redux-saga/effects';
import Types from 'Retail/state/actions/visits';
import { RetailAPI } from 'Core/API';

/*
		r.HandleFunc("/visit/rate/{id}", c.Rate).Methods("PUT")
		r.HandleFunc("/visit/begin/{id}", c.Begin).Methods("PUT")
		r.HandleFunc("/visit/complete/{id}", c.Complete).Methods("PUT")
		r.HandleFunc("/visit/stop/{id}", c.Stop).Methods("PUT")
		r.HandleFunc("/visit/overdue/{id}", c.Overdue).Methods("PUT")
*/

export function* beginVisits({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.put('/visit/begin/' + params.id, params),
    );
    yield all([
      put({ type: Types.BEGIN_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.BEGIN_VISITS_FAILED });
  }
}

export function* rateVisits({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.put('/visit/rate/' + params.id, params),
    );
    yield all([
      put({ type: Types.RATE_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.RATE_VISITS_FAILED });
  }
}

export function* completeVisits({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.put('/visit/complete/' + params.id, params),
    );
    yield all([
      put({ type: Types.COMPLETE_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.COMPLETE_VISITS_FAILED });
  }
}

export function* stopVisits({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.put('/visit/stop/' + params.id, params),
    );
    yield all([
      put({ type: Types.STOP_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.STOP_VISITS_FAILED });
  }
}

export function* overdueVisits({ params }) {
  try {
    const data = yield call(() =>
      RetailAPI.put('/visit/overdue/' + params.id, params),
    );
    yield all([
      put({ type: Types.OVERDUE_VISITS_SUCCESS }),
      put({ type: Types.FETCH_VISITS }),
    ]);
  } catch (error) {
    yield put({ type: Types.OVERDUE_VISITS_FAILED });
  }
}
