import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Logout as LogoutAction } from "../../state/actions/authentication";

import Logo from "../../components/logo";
import Spin from "../../components/spin";

import ConfigureStore from "../../state/store";

//I had a weird thing where the effect was doubling it's firing
var startedLogout = false;

import { CoreAPI } from "Core/API";
import { useNavigate } from "react-router-dom";
import "./logout.less";
import { userLoggedOut } from "@maple-tech/auth";
export default () => {
  const { t } = useTranslation(["core"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, token } = useSelector((state) => ({
    status: state.authentication.logoutStatus,
    token: state.authentication.token,
  }));
  const hasToken = !!(token && typeof token === "string" && token.length > 1);

  useEffect(() => {
    // await CoreAPI.put("/user/device-token", {
    //   application: "WEB",
    //   fingerPrint: localStorage.getItem("Authorization"),
    // });

    if (!(startedLogout || !hasToken)) {
      startedLogout = true;
      console.debug("Performing logout");
      // dispatch(LogoutAction());
      userLoggedOut();
    }

    setTimeout(() => {
      const { persistor } = ConfigureStore();
      persistor.purge();
      localStorage.removeItem("fcmToken");
      localStorage.removeItem("Authorization");
      localStorage.removeItem("x-company");
      localStorage.removeItem("@maple/userData");
      // getAuthService().clearAll();
      window.location.href = "/login";
      // navigate("/login");
    }, 1000);
  }, []);

  return (
    <div id="route-logout">
      <div id="route-logout-content">
        <main>
          <Logo full color="primary" />
          <h1>{t("core:logout.greeting")}</h1>
          <h2>{t("core:logout.sub-greeting")}</h2>

          <Spin size="large" active={status === "LOADING"} />
        </main>
      </div>
    </div>
  );
};
