/**
 * Bootstrapper
 *
 * Intends to dynamically load and inject products into the application.
 *
 */
import i18n from "./i18n";
import { AddNavigation as AddNavigationAction } from "./state/actions/application";

export async function LoadForUser(user, companyState) {
  console.group("[Bootstrap] LoadForUser");

  console.debug("Loading products for user", user, companyState);
  if (!user || !companyState) reject("Invalid state");
  if (companyState.memberships.length == 0) {
    console.debug("No company memberships found");
    return;
  }

  const modules = [];
  companyState.memberships.forEach((comp) => {
  //   if (Array.isArray(comp.info.industries) && comp.info.industries.length === 0) {
  //  comp.info.industries.push("RETAIL") }
    comp.info.industries.forEach((mod) => {
      if (modules.findIndex((m) => m === mod) === -1) modules.push(mod);
    });
  });

  console.debug("Loading modules: ", modules);
  for (const mod of modules) {
    try {
      let product;

      switch (mod) {
        // case "EDUCATION":
        //   product = await import("../education");
        //   break;
        case "RETAIL":
          product = await import("Retail/index");
        default:
          break;
      }

      if (product.default && typeof product.default === "function") {
        product.default.call(null, user, companyState);
      }
    } catch (err) {
      console.error("Error loading product", mod, err);
    }
  }

  console.groupEnd();
}

export function InjectNavigation(config = [], rootPath = "/") {
  if (!config || !Array.isArray(config))
    throw new TypeError(
      "InjectNavigation expects an array configuration object"
    );
  console.debug("[Bootstrap] Injecting Navigation", config);

  const state = Maple.store.getState();
  const dispatch = Maple.store.dispatch;
  if (!state || !dispatch)
    throw new Error("Global store/state mechanism is not setup");

  //Inject into the singleton
  //InjectRoutes(config);

  dispatch(AddNavigationAction(config, rootPath));
}

export async function InjectLocale(config = []) {
  if (!config || !Array.isArray(config))
    throw new TypeError("InjectLocales expects an array configuration object");
  console.debug("[Bootstrap] Injecting locale namespaces", config);

  try {
    await i18n.loadNamespaces(config);
    console.debug("[Boostrap] Namespaces injected");
  } catch (err) {
    console.error("[Bootstrap] Error loading i18n namespaces", err);
  }
}

export async function InjectState(reducers = {}, sagas = {}) {
  try {
    Object.keys(reducers).forEach((key) =>
      Maple.store.injectReducer(key, reducers[key])
    );
    Object.keys(sagas).forEach((key) =>
      Maple.store.injectSaga(key, sagas[key])
    );
  } catch (err) {
    console.error("[Bootstrap] Error injecting redux state mechanisms", err);
  }
}
