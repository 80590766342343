import { call, put, takeLatest } from 'redux-saga/effects';

import { CoreAPI } from 'Core/API';

import Types from '../actions/roles';

export function* fetchRoles({ action }) {
  try {
    const payload = yield call(() => CoreAPI.get('/company/permission'));
    yield put({ type: Types.FETCH_ROLES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.FETCH_ROLES_FAILED, error });
  }
}

export function* addRoles({ action }) {
  try {
    const payload = yield call(() =>
      CoreAPI.post('/company/permission', action),
    );
    yield put({ type: Types.ADD_ROLES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.ADD_ROLES_FAILED, error });
  }
}

export function* deletePermissions({ payload: id }) {
  console.log('id :', id);
  try {
    const payload = yield call(() =>
      CoreAPI.delete('/company/permission', [id]),
    );
    yield put({ type: Types.DELETE_ROLES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.DELETE_ROLES_FAILED, error });
  }
}

export default function* permissionsSaga() {
  yield takeLatest(Types.FETCH_ROLES, fetchRoles);
  yield takeLatest(Types.ADD_ROLES, addRoles);
  yield takeLatest(Types.DELETE_ROLES, deletePermissions);
}
