import { combineReducers } from 'redux';
import notes, { INITIAL_STATE as notesState } from './notes';
import posts, { INITIAL_STATE as visitCoreState } from './posts';
import triggers, { INITIAL_STATE as visitTriggerState } from './triggers';

const INITIALSTATE = {
  posts: visitCoreState,
  triggers: visitTriggerState,
  notes: notesState,
};
const visitCore = {
  posts,
  triggers,
  notes,
};

export default combineReducers(visitCore);
