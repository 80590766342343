import { combineReducers } from 'redux';
import addNewTask, {
  INITIAL_STATE as addNewTaskInitialState,
} from 'Retail/state/reducers/task/addNewTask';
import addNewTemplate, {
  INITIAL_STATE as addNewTemplateInitialState,
} from 'Retail/state/reducers/task/addNewTemplate';
import approveTask, {
  INITIAL_STATE as approveTaskInitialState,
} from 'Retail/state/reducers/task/approveTask';
import beginTask, {
  INITIAL_STATE as beginTaskInitialState,
} from 'Retail/state/reducers/task/beginTask';
import claimTask, {
  INITIAL_STATE as claimTaskInitialState,
} from 'Retail/state/reducers/task/claimTask';
import completeTask, {
  INITIAL_STATE as completeTaskInitialState,
} from 'Retail/state/reducers/task/completeTask';
import createCategory, {
  INITIAL_STATE as createCategoryInitialState,
} from 'Retail/state/reducers/task/createCategory';
import denyTask, {
  INITIAL_STATE as denyTaskInitialState,
} from 'Retail/state/reducers/task/denyTask';
import fetchCategories, {
  INITIAL_STATE as fetchCategoriesInitialState,
} from 'Retail/state/reducers/task/fetchCategories';
import fetchTags, {
  INITIAL_STATE as fetchTagsInitialState,
} from 'Retail/state/reducers/task/fetchTags';
import fetchTask, {
  INITIAL_STATE as fetchTaskInitialState,
} from 'Retail/state/reducers/task/fetchTask';
import fetchTemplates, {
  INITIAL_STATE as fetchTemplatesInitialState,
} from 'Retail/state/reducers/task/fetchTemplates';
import prepareNewCustomTask, {
  INITIAL_STATE as prepareNewCustomTaskInitialState,
} from 'Retail/state/reducers/task/prepareNewCustomTask';
import prepareNewIssueTask, {
  INITIAL_STATE as prepareNewIssueTaskInitialState,
} from 'Retail/state/reducers/task/prepareNewIssueTask';
import prepareNewTemplate, {
  INITIAL_STATE as prepareNewTemplateInitialState,
} from 'Retail/state/reducers/task/prepareNewTemplate';
import putTaskDocuments, {
  INITIAL_STATE as putTaskDocumentsInitialState,
} from 'Retail/state/reducers/task/putTaskDocuments';
import rejectTask, {
  INITIAL_STATE as rejectTaskInitialState,
} from 'Retail/state/reducers/task/rejectTask';

console.log('addNewTaskInitialState :', addNewTaskInitialState);
const INITIALSTATE = {
  addNewTask: addNewTaskInitialState,
  addNewTemplate: addNewTemplateInitialState,
  approveTask: approveTaskInitialState,
  beginTask: beginTaskInitialState,
  claimTask: claimTaskInitialState,
  completeTask: completeTaskInitialState,
  createCategory: createCategoryInitialState,
  denyTask: denyTaskInitialState,
  fetchCategories: fetchCategoriesInitialState,
  fetchTags: fetchTagsInitialState,
  putTaskDocuments: putTaskDocumentsInitialState,
  fetchTask: fetchTaskInitialState,
  fetchTemplates: fetchTemplatesInitialState,
  prepareNewCustomTask: prepareNewCustomTaskInitialState,
  prepareNewIssueTask: prepareNewIssueTaskInitialState,
  prepareNewTemplate: prepareNewTemplateInitialState,
  rejectTask: rejectTaskInitialState,
};
const taskCore = {
  addNewTask,
  addNewTemplate,
  approveTask,
  beginTask,
  claimTask,
  completeTask,
  createCategory,
  denyTask,
  fetchCategories,
  fetchTags,
  fetchTask,
  fetchTemplates,
  prepareNewCustomTask,
  prepareNewIssueTask,
  prepareNewTemplate,
  rejectTask,
  putTaskDocuments,
};

export default combineReducers(taskCore);
