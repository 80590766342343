import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// import Notifications from "../Notifications";
import NotificationsPager from '@maple-tech/company-settings-web-app/Notifications';

import SupportButton from 'Core/components/support-info-dialog/support-button';

import './page-header.less';
export default ({
  title = '',
  content = null,
  showBack = false,
  backTo = null,
}) => {
  const navigate = useNavigate();

  const handleBack = () =>
    backTo && typeof backTo === 'string' ? navigate(backTo) : navigate(-1);

  return (
    <header id="page-header">
      <div id="page-header-title">
        {showBack && (
          <Button
            shape="circle"
            type="text"
            icon={<LeftOutlined size={30} />}
            className="page-header-back"
            onClick={handleBack}
          />
        )}
        <h1>{title}</h1>
      </div>
      {renderHeaderContent(content)}
      <div id="header-portal" />
      <SupportButton />
      <NotificationsPager previewMode="popup" />
    </header>
  );
};

const renderHeaderContent = (content) => {
  if (!content) return null;

  return (
    <div id="page-header-content">
      {typeof content === 'function' ? content() : content}
    </div>
  );
};
