const keys = {
    BOOTSTRAP: 'bootstrap/INIT',
    BOOTSTRAP_START: 'bootstrap/START',
    BOOTSTRAP_COMPLETE: 'bootstrap/COMPLETE',

    BOOTSTRAP_LOGIN_START: 'bootstrap/LOGIN/START',
    BOOTSTRAP_LOGIN_COMPLETE: 'bootstrap/LOGIN/COMPLETE',
};

export const Bootstrap = () => ({ type: keys.BOOTSTRAP });

export default keys;